export const OidcRoles = {
    EDIT_PROJECTS_ROLE: "ROLE_DriveHub_Projects_Edit",
    USER_INFO_LSU_ROLE_PREFIX: "ROLE_DriveHub_UserInfo_Group_LSU_",
    UPDATE_PROJECT_LSU_ROLE: "ROLE_DriveHub_Projects_LSU_Update",
    READ_TRANSFER_PRICES_ROLE: "ROLE_DriveHub_TransferPrices_Read",
    READ_MULTIPLIER_ROLE: "ROLE_DriveHub_Multiplier_Read",
    READ_LIST_PRICES_ROLE: "ROLE_DriveHub_ListPrices_Read",
    READ_TRANSFERPRICES_DISCOUNT_ROLE: "ROLE_DriveHub_TransferPrices_Discount_Read",
    UPDATE_TRANSFERPRICES_DISCOUNT_ROLE: "ROLE_DriveHub_TransferPrices_Discount_Update",
    READ_COSTPRICES_ROLE: "ROLE_DriveHub_CostPrices_Read",
    READ_MFK_ROLE: "ROLE_DriveHub_Mfk_Read"
} as const
