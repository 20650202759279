import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../translations/TranslationKeys"
import { AddLineItemsArgs } from "../sales.api.types"
import SnackbarSlice from "../../snackbar/snackbar.slice"
import { SalesService, UpdateServerResponse } from "@encoway/sales-api-js-client"
import { StringLineItemProperties } from "../sales.constants"
import { AbbLineItem } from "../sales.types"
import { merge } from "lodash"
import { SalesQueryFnOptions } from "../sales.api.utils"

const CHUNK_SIZE = 5

export const addItemsQuery: SalesQueryFnOptions<UpdateServerResponse | undefined, AddLineItemsArgs>["query"] = async (
    args: AddLineItemsArgs,
    salesService: SalesService,
    api: BaseQueryApi
) => {
    const responses: UpdateServerResponse[] = []
    const errorProductNames: string[] = []
    for (let i = 0; i < args.articlesToInsert.length; i += CHUNK_SIZE) {
        const articlesToInsert = args.articlesToInsert.slice(i, i + CHUNK_SIZE)
        try {
            const actualArgs = { ...args, articlesToInsert: articlesToInsert.map(article => ({ articleId: article.articleId, quantity: article.quantity })) }
            const response = await salesService.custom.call("/lineItem/additems", actualArgs)
            responses.push(response)
        } catch (e) {
            articlesToInsert.forEach(article => errorProductNames.push(article.articleName ?? article.articleId))
        }
    }
    const success = errorProductNames.length === 0
    if (!success) {
        const productNames = errorProductNames.map(name => "'" + name + "'").join(", ")
        const message = L10n.format(TranslationKeys.pages.project.catalog.productSelection.multiple.addToCompositionErrorMessage, { productNames })
        api.dispatch(SnackbarSlice.actions.open({ message, severity: "error" }))
    }
    if (responses.length > 0) {
        const response: UpdateServerResponse = merge({}, ...responses)
        handleAddItemsResponse(response, args, api, success)
        return response
    }
}

function handleAddItemsResponse(response: UpdateServerResponse, args: AddLineItemsArgs, api: BaseQueryApi, success = true) {
    const translationKeys = getTranslationKeys(args)
    const addedItemsWithValidationProblems = getAddedItemsWithHighSeverityValidationProblems(response)
    if (addedItemsWithValidationProblems) {
        const errorMessage = L10n.format(translationKeys.error, { addedItemsWithValidationProblems })
        api.dispatch(SnackbarSlice.actions.open({ message: errorMessage, severity: "error" }))
    } else if (success) {
        api.dispatch(SnackbarSlice.actions.open({ message: L10n.format(translationKeys.success), severity: "success" }))
    }
}

function getTranslationKeys(args: AddLineItemsArgs) {
    const key = args.articlesToInsert.length === 1 ? "single" : "multiple"
    return {
        success: TranslationKeys.pages.project.catalog.productSelection[key].addToCompositionSuccessMessage,
        error: TranslationKeys.pages.project.catalog.productSelection[key].addToCompositionValidationProblemsMessage
    }
}

function getAddedItemsWithHighSeverityValidationProblems(updateServerResponse: UpdateServerResponse): string {
    return getAddedItems(updateServerResponse)
        .filter(hasHighSeverityValidationProblems)
        .map(item => "'" + item.properties[StringLineItemProperties.CONFIGURATION_NAME] + "'")
        .join(", ")
}

function hasHighSeverityValidationProblems(lineItem: AbbLineItem) {
    return lineItem.properties.validationProblems?.flat().some(validationProblem => validationProblem.severity === "HIGH") ?? false
}

function getAddedItems(updateServerResponse: UpdateServerResponse): AbbLineItem[] {
    return Object.values(updateServerResponse.addedChildLineItems).flat()
}
