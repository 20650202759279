import { useCallback } from "react"
import readError from "../features/error/utils/readError"
import ErrorSlice from "../features/error/error.slice"
import { useAppDispatch } from "../store/store"

const fullscreenChangeEventHandler = (event: Event) => {
    if (!document.fullscreenElement && event.target) {
        const fullscreenElement = event.target as HTMLElement
        const fluentContainer = document.getElementById("fluent-default-layer-host")
        fullscreenElement.removeEventListener("fullscreenchange", fullscreenChangeEventHandler)
        if (fluentContainer) {
            document.body.appendChild(fluentContainer)
        }
    }
}

export function useFullscreen(elementGetter: () => HTMLElement) {
    const dispatch = useAppDispatch()

    const activateFullscreen = useCallback(() => {
        const fullscreenElement = elementGetter()
        const fluentContainer = document.getElementById("fluent-default-layer-host")
        fullscreenElement
            .requestFullscreen()
            .then(() => fullscreenElement.addEventListener("fullscreenchange", fullscreenChangeEventHandler))
            .then(() => fluentContainer && fullscreenElement.appendChild(fluentContainer))
            .catch(e => readError(e).then(error => dispatch(ErrorSlice.actions.set(error))))
    }, [dispatch, elementGetter])

    return {
        activate: activateFullscreen
    }
}
