import { Checkbox, CheckboxProps } from "@encoway/cui-configurator-components"
import AbbCheckboxStyles from "./AbbCheckbox.styles"
import { merge } from "lodash"
import CuiUtils from "../../utils/CuiUtils"
import ViewPortProperties from "../../constants/ViewPortProperties"

export default function AbbCheckbox(props: Readonly<CheckboxProps>) {
    const showShortTextViewPortProperty = props.data.viewPortProperties?.[ViewPortProperties.SHOW_SHORTTEXT] as string | undefined
    const showShortText = showShortTextViewPortProperty && CuiUtils.readViewportPropertyValue(showShortTextViewPortProperty) === "1"
    return (
        <div>
            <Checkbox {...props} styles={merge({}, AbbCheckboxStyles, props.styles)} />
            {showShortText && props.data.shortText}
        </div>
    )
}
