import { useEffect, useState } from "react"
import { getProductSelection } from "../../../pages/projectPage/components/productSelection/ProductSelection.utils"
import { CatalogApi } from "../catalog.api"

export function useProductFilters(productGroupId: string, searchValue?: string) {
    const [characteristicFilters, setCharacteristicFilters] = useState<Record<string, string | string[] | undefined>>({})
    const productSelection = getProductSelection(productGroupId, characteristicFilters, !!searchValue, searchValue)
    const productsQuery = CatalogApi.useProductsQuery(productSelection)

    useEffect(() => {
        setCharacteristicFilters({})
    }, [productGroupId])

    const updateFilters = (characteristicId: string, value: string | string[] | undefined) => {
        setCharacteristicFilters(prevState => {
            const newState = { ...prevState }
            if (value === undefined) {
                delete newState[characteristicId]
            } else {
                newState[characteristicId] = value
            }
            return newState
        })
    }

    return {
        characteristicFilters,
        aggregatedCharacteristicValues: productsQuery.currentData?.aggregatedCharacteristicValues ?? {},
        onUpdateFilter: updateFilters,
        productGroupId
    }
}
