import theme from "../../App.theme"

const BreadcrumbsStyles = {
    link: {
        color: theme.palette.text.secondary,
        textDecoration: "none",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    typography: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}

export default BreadcrumbsStyles
