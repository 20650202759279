export const ProductQuantityCellStyles = {
    textField: {
        minWidth: "52px",
        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0
        },
        "input[type=number]": {
            MozAppearance: "textfield"
        },
        input: {
            textAlign: "center",
            boxSizing: "content-box"
        }
    },
    button: {
        borderRadius: "4px",
        boxSizing: "border-box",
        height: "40px"
    }
}
