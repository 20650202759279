import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Settings } from "../../settings"

interface TranslationsState {
    language: string
}

const initialState: TranslationsState = {
    language: Settings.locale
}

export const TranslationsSlice = createSlice({
    name: "translations",
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload
        }
    }
})
