import { NumberValue, Product } from "@encoway/c-services-js-client"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"

export function getProductData(product: Product) {
    const configurationReferenceModel = CatalogUtils.getCharacteristicValue<string>(product, Characteristics.ConfigurableProductReferenceModel.id)
    return {
        id: product.id,
        name: product.name,
        isConfigurable: product.configurable || !!configurationReferenceModel,
        imageSrc: CatalogUtils.getMediaUri(product, Characteristics.ProductImage.id),
        catalogLink: CatalogUtils.getCharacteristicValue<string>(product, Characteristics.CatalogLink.id),
        shortText: CatalogUtils.getCharacteristicValue<string>(product, Characteristics.ShortText.id),
        isLineupCompatible: !!CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.LineupCompatible.id)?.value
    }
}
