/* eslint-disable */
import {
    classNamesFunction,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IContextualMenuItem,
    IProcessedStyleSet,
    IStyleSet,
    mergeStyles,
    PrimaryButton,
    registerIcons,
    styled,
    Text,
    TextField
} from "@fluentui/react"
import { EditIcon } from "@fluentui/react-icons-mdl2"
import React, { FormEvent, useCallback, useState } from "react"

import { ParameterTO } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"
import { onValueChanged } from "@encoway/react-configurator"
import { validateNumericInput } from "../../validation/customValidation" /* CUSTOMIZING */
import { AdditionalValueProps, AdditionalValueStyles, IAdditionalValueStyles } from "@encoway/cui-configurator-components"
import { determineLayerClass } from "@encoway/cui-configurator-components/dist/esm/helperFunctions"

const ACCEPT = "Accept"
const CLEAR = "Clear"
const EDIT = "Edit"

registerIcons(
    {
        icons: {
            [EDIT]: <EditIcon />
        }
    },
    { disableWarnings: true }
)

const defaultDialogMinWidth = "30rem"

interface UseAdditionalValueProps {
    isMultiValue?: boolean
}

interface UseAdditionalValueResult {
    isOpen: boolean
    applyValue: (data: ParameterTO, value: string, onValueChanged?: onValueChanged) => string | null
    dismiss: () => void
    additionalValueMenuItem: IContextualMenuItem
}

/**
 * Use this hook to get the necessary dialog props and menu item.
 *
 * The menu item is used to open the dialog.
 */
function useAdditionalValue({ isMultiValue = false }: UseAdditionalValueProps): UseAdditionalValueResult {
    const [isOpen, setIsOpen] = useState(false)

    const openDialog = useCallback(() => {
        setIsOpen(true)
    }, [setIsOpen])

    const closeDialog = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    const applyValue = (data: ParameterTO, value: string, onValueChanged?: onValueChanged): string | null => {
        if (!onValueChanged) {
            console.error("onValueChanged is undefined, can't apply value")
            return null
        }

        if (!data.valueType) {
            onValueChanged(data, value)
            return null
        }

        if (data.valueType.toLowerCase() === "string") {
            onValueChanged(data, value)
        } else {
            const number = validateNumericInput(value, L10n.currentFullLocale())

            if (isNaN(number)) {
                return L10n.format("Configuration.AdditionalValue.Dialog.Validation.Number")
            }

            onValueChanged(data, value)
        }

        closeDialog()
        return null
    }

    const additionalValueMenuItem: IContextualMenuItem = {
        key: "additionalValue",
        text: L10n.format(`Configuration.AdditionalValue.${isMultiValue ? "MultiValue" : "SingleValue"}.Name`),
        iconProps: { iconName: EDIT },
        onClick: openDialog
    }

    return {
        isOpen,
        applyValue,
        dismiss: closeDialog,
        additionalValueMenuItem
    }
}

/**
 * Renders a Dialog that is used to add additional values or add an alternative value to the parameter.
 * Supporting both multi-value and single-value data.
 *
 * Links:
 * - [MS Fluent Dialog](https://developer.microsoft.com/de-DE/fluentui#/controls/web/dialog)
 *
 * @visibleName AdditionalValue
 */
function IAdditionalValue({ styles, theme, data, parameterMenuData, onValueChanged, modalProps, dialogMinWidth }: AdditionalValueProps) {
    const classNames: IProcessedStyleSet<IStyleSet<IAdditionalValueStyles>> = classNamesFunction<{}, IStyleSet<IAdditionalValueStyles>>()(styles, theme)

    const isMultiValue = data.multivalued

    const { isOpen, applyValue, dismiss, additionalValueMenuItem } = useAdditionalValue({
        isMultiValue
    })

    parameterMenuData.add(additionalValueMenuItem)

    const [value, setValue] = useState<string | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const nameTranslation = isMultiValue
        ? L10n.format("Configuration.AdditionalValue.MultiValue.Name")
        : L10n.format("Configuration.AdditionalValue.SingleValue.Name")

    const dialogContentProps = {
        type: DialogType.normal,
        title: nameTranslation
    }

    const onDismiss = useCallback(() => {
        dismiss()
        setErrorMessage(null)
        setValue(null)
    }, [dismiss, setValue, setErrorMessage])

    const onApply = useCallback(() => {
        if (value === null) {
            console.error("Can not apply value 'null'")
            return
        }
        const newErrorMessage = applyValue(data, value, onValueChanged)

        if (newErrorMessage) {
            setErrorMessage(newErrorMessage)
        } else {
            setErrorMessage(null)
            setValue(null)
        }
    }, [value, data, onValueChanged, setValue, setErrorMessage])

    const onKeyDown = useCallback(
        (event: KeyboardEvent | React.KeyboardEvent) => {
            if (event.key === "Enter" && value !== null) {
                onApply()
            }
        },
        [onApply, value]
    )

    return (
        <Dialog
            hidden={!isOpen}
            onDismiss={onDismiss}
            dialogContentProps={dialogContentProps}
            minWidth={`min(${dialogMinWidth || defaultDialogMinWidth}, 100%)`}
            modalProps={{
                className: mergeStyles(classNames.dialog, classNames.modal, determineLayerClass(modalProps?.layerProps, classNames.layer)),
                overlay: {
                    isDarkThemed: false,
                    className: classNames.overlay
                },
                ...modalProps
            }}
        >
            <Text className={classNames.text}>{L10n.format("Configuration.AdditionalValue.Dialog.Text")}</Text>
            <TextField
                value={value === null ? "" : value}
                onChange={(_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                    if (newValue) {
                        setValue(newValue)
                    } else {
                        setValue(null)
                    }
                }}
                onKeyDown={onKeyDown}
                placeholder={`${nameTranslation}...`}
                errorMessage={errorMessage === null ? "" : errorMessage}
                className={classNames.textField}
            />
            <DialogFooter className={classNames.dialogFooter}>
                <PrimaryButton
                    onClick={onApply}
                    text={L10n.format("Configuration.AdditionalValue.Dialog.Apply")}
                    iconProps={{ iconName: ACCEPT }}
                    disabled={value === null}
                    className={classNames.applyButton}
                />
                <DefaultButton
                    onClick={onDismiss}
                    text={L10n.format("Configuration.AdditionalValue.Dialog.Cancel")}
                    iconProps={{ iconName: CLEAR }}
                    className={classNames.dismissButton}
                />
            </DialogFooter>
        </Dialog>
    )
}

export const CuiAdditionalValueWithCustomValueFormatting = styled(IAdditionalValue, AdditionalValueStyles)
CuiAdditionalValueWithCustomValueFormatting.displayName = "AdditionalValue"
