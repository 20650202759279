import { getTheme } from "@fluentui/react"

const theme = getTheme()

const CuiStyleConstants = {
    CONTAINER_BORDER_RADIUS: 8,
    FIELD_BORDER_WIDTH: 2,
    FIELD_BORDER_RADIUS: 4,
    FIELD_BORDER_COLOR: theme.palette.neutralTertiary,
    COLUMN_GAP: 24
} as const

export default CuiStyleConstants
