const MainMenuStyles = {
    wrapper: {
        display: {
            xs: "none",
            sm: "flex"
        }
    },
    link: {
        textTransform: "none",
        my: 2,
        fontSize: 15,
        borderBottomWidth: 2,
        borderBottomColor: "transparent",
        borderRadius: 0,
        color: "text.secondary",
        "&[aria-current]": {
            color: "text.secondary"
        },
        "&.active": {
            color: "text.primary",
            fontWeight: "bold",
            "&:hover": {
                color: "text.secondary"
            }
        },
        "&:hover": {
            backgroundColor: "transparent",
            color: "text.primary"
        }
    }
}

export default MainMenuStyles
