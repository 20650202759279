import { DataGridProProps, GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Radio, Stack } from "@mui/material"
import { InputFieldProps } from "@encoway/cui-configurator-components"
import AbbDimensioningDcStyles from "./AbbDimensioningDc.styles"
import { AbbDimensioningDcsDataGrid } from "./dcs/AbbDimensioningDcsDataGrid"
import { AbbDimensioningDctDataGrid } from "./dct/AbbDimensioningDctDataGrid"
import { DcType, determineDcType } from "./AbbDimensioningDc.utils"
import { useAppSelector } from "../../../../../../../../store/store"
import { DcsDimensioningResultEntry } from "../../../../../../../../features/dimensioning/dimensioning.types"
import { ReactNode } from "react"

export interface AbbDimensioningDataGridProps extends Partial<DataGridProProps> {
    configurationId: string
    parameterId: string
    renderProductNameCell: (params: GridRenderCellParams<DcsDimensioningResultEntry>) => ReactNode
}

export default function AbbDimensioningDc(props: Readonly<InputFieldProps>) {
    const dcType: DcType = determineDcType(props.data.name)
    const selectedProductId = props.data.selectedValues?.at(0)?.value
    const readOnly = useAppSelector(state => state.sales.configurationReadOnly)

    const renderProductNameCell = (params: GridRenderCellParams<DcsDimensioningResultEntry>) => {
        return (
            <Stack direction="row" spacing={1} alignItems="center">
                <Radio checked={params.row.id === selectedProductId} disabled={readOnly} onChange={() => props.onValueChanged(props.data, params.row.id)} />
                <span>{params.value}</span>
            </Stack>
        )
    }

    const dataGridProps: AbbDimensioningDataGridProps = {
        configurationId: (props as any).configurationId,
        parameterId: props.data.id,
        renderProductNameCell: renderProductNameCell,
        disableRowSelectionOnClick: true,
        hideFooter: true,
        sx: AbbDimensioningDcStyles.dataGrid
    }

    return (
        <Stack spacing={1} sx={AbbDimensioningDcStyles.stack}>
            {dcType === DcType.DCS ? <AbbDimensioningDcsDataGrid {...dataGridProps} /> : <AbbDimensioningDctDataGrid {...dataGridProps} />}
        </Stack>
    )
}
