import { Box, Button, Stack } from "@mui/material"
import { Queue } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import TypeCodeCellStyles from "./TypeCodeCell.styles"
import useDialog from "../../../../../../../components/dialog/useDialog"
import Dialog from "../../../../../../../components/dialog/Dialog"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"

interface LineItemDataGridTypeCodeCellProps {
    value: string
}

export default function TypeCodeCell({ value }: LineItemDataGridTypeCodeCellProps) {
    const dialog = useDialog()

    const onClickCopyButton = () => {
        navigator.clipboard.writeText(value)
        dialog.close()
    }

    return (
        <>
            <Stack direction="row" onClick={dialog.open} sx={TypeCodeCellStyles.typecode.container}>
                <span style={TypeCodeCellStyles.typecode.text}>{value}</span>
                <Queue fontSize="small" />
            </Stack>
            <Dialog
                maxWidth="xl"
                title={L10n.format(TranslationKeys.pages.project.composition.copyTypeCode)}
                icon={<Queue fontSize="small" />}
                open={dialog.isOpen}
                onClose={dialog.close}
                withDividers
            >
                <Stack spacing={1}>
                    <span>{L10n.format(TranslationKeys.pages.project.composition.copyTypeCode)}</span>
                    <Stack direction="row" alignItems="end" spacing={1}>
                        <Box sx={TypeCodeCellStyles.typecode.box} dangerouslySetInnerHTML={{ __html: value.replaceAll("+", "<wbr>+") }} />
                        <Button variant="contained" color="secondary" size="large" onClick={onClickCopyButton} sx={TypeCodeCellStyles.typecode.button}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Queue />
                                <span>{L10n.format(TranslationKeys.pages.project.composition.copyTypeCode)}</span>
                            </Stack>
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    )
}
