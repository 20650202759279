import { Stack } from "@fluentui/react/lib/Stack"
import { Text } from "@fluentui/react/lib/Text"
import React from "react"
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling"
import { IDetailDropdownStyles } from "@encoway/cui-configurator-components/src/components/DetailDropdown/DetailDropdown.types"
import { IDetailDropdownOption } from "./AbbFilterDetailDropdown.types"

interface IShortTextProps {
    classNames: IProcessedStyleSet<IDetailDropdownStyles>
    dropdownOption: IDetailDropdownOption
    showShortText: boolean
}

const getShortTextClass = (dropdownOption: IDetailDropdownOption, classNames: IProcessedStyleSet<IDetailDropdownStyles>) => {
    let shortTextClass = classNames.shortTextContainer
    if (!dropdownOption.selected && !dropdownOption.selectable) {
        shortTextClass = mergeStyles(shortTextClass, classNames.shortTextContainerNotSelected)
    }

    return shortTextClass
}

export const DetailDropdownShortText = (props: IShortTextProps) => {
    const { dropdownOption, showShortText, classNames } = props

    const optionShortText = dropdownOption.shortText
    if (showShortText && optionShortText) {
        return (
            <Stack.Item className={getShortTextClass(dropdownOption, classNames)}>
                <Text>{optionShortText}</Text>
            </Stack.Item>
        )
    }
    return null
}
