import { createApi } from "@reduxjs/toolkit/query/react"
import { Environment, PublicEnvironment } from "./environment.types"
import { baseQuery } from "../../store/baseQuery"

export const EnvironmentApi = createApi({
    reducerPath: "environmentApi",
    baseQuery: baseQuery,
    endpoints: builder => ({
        environment: builder.query<Environment, void>({
            query: () => "/api/features/environment"
        }),
        publicEnvironment: builder.query<PublicEnvironment, void>({
            query: () => "/api/features/public/environment"
        })
    })
})
