import { useState } from "react"
import { Button, IconButton, Stack } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CalculateIcon from "@mui/icons-material/Calculate"
import ListIcon from "@mui/icons-material/List"
import PercentIcon from "@mui/icons-material/Percent"
import SellIcon from "@mui/icons-material/Sell"
import { L10n } from "@encoway/l10n"
import LineItemsDataGrid from "./lineItemsDataGrid/LineItemsDataGrid"
import useNavigate from "../../../../router/hooks/useNavigate"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import Dialog from "../../../../components/dialog/Dialog"
import useDialog from "../../../../components/dialog/useDialog"
import NewArticleDialog from "./customArticleDialog/NewArticleDialog"
import DocumentGenerationForm from "../../../../components/forms/documentGenerationForm/DocumentGenerationForm"
import theme from "../../../../App.theme"
import { ContentBlockIds, ProductGroupIds } from "../../../../features/catalog/catalog.constants"
import { CatalogApi } from "../../../../features/catalog/catalog.api"
import SalesApi from "../../../../features/sales/sales.api"
import usePrint from "../../../../features/sales/hooks/usePrint"
import { useDeleteLineItemsHandler } from "../../../../features/sales/hooks/useDeleteLineItemsHandler"
import { AbbLineItem } from "../../../../features/sales/sales.types"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { SalesSlice } from "../../../../features/sales/sales.slice"
import { ProjectCompositionView } from "./constants/ProjectCompositionView"
import { OidcUtils } from "../../../../features/oidc/oidc.utils"
import { useUserInformation } from "../../../../features/oidc/hooks/useUserInformation"
import { MessageBar, MessageBarType } from "@fluentui/react"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"
import { Currency } from "./constants/Currency"

export default function ProjectComposition() {
    const [lineItemToPrint, setLineItemToPrint] = useState<AbbLineItem>()
    const [selectedProjectCompositionView, setSelectedProjectCompositionView] = useState(ProjectCompositionView.PROJECT_VIEW)

    const salesDocumentReadOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const documentTypes = CatalogApi.useDocumentTypesQuery(ContentBlockIds.DocumentTypes).data ?? []
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!

    const [addFolder] = SalesApi.useAddFolderMutation()
    const [expandAllLineItems] = SalesApi.useExpandAllLineItemsMutation()
    const [collapseAllLineItems] = SalesApi.useCollapseAllLineItemsMutation()

    const deleteLineItemsHandler = useDeleteLineItemsHandler()
    const print = usePrint()
    const navigate = useNavigate()
    const dialog = useDialog()
    const dispatch = useAppDispatch()
    const userInformation = useUserInformation()

    const openConfiguration = (lineItem: AbbLineItem, readOnly?: boolean) => {
        dispatch(SalesSlice.actions.setConfigurationReadOnly(!!readOnly))
        navigate.toProjectConfiguration(salesDocument.salesDocumentId, lineItem.lineItemId)
    }

    const submitDocumentGeneration = (documentTemplate: string, documentFormat: string) => {
        setLineItemToPrint(undefined)
        print({ documentTemplate, documentFormat, lineItemId: lineItemToPrint!.lineItemId })
    }

    const addProduct = () => {
        if (salesDocument.properties.PRODUCT_CATEGORY) {
            const productGroupIds = [ProductGroupIds.Catalog, ProductGroupIds[salesDocument.properties.PRODUCT_CATEGORY as keyof typeof ProductGroupIds]]
            navigate.toProductGroup(salesDocument.salesDocumentId, productGroupIds)
        } else {
            navigate.toCatalog(salesDocument.salesDocumentId)
        }
    }

    return (
        <Stack spacing={3} width="100%">
            {(!salesDocument.properties[SalesDocumentProperties.TRANSFER_PRICE_CURRENCY] ||
                salesDocument.properties[SalesDocumentProperties.TRANSFER_PRICE_CURRENCY] === Currency.NO_CURRENCY) && (
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                    {L10n.format(TranslationKeys.project.warning.missingTransferPriceCurrency)}
                </MessageBar>
            )}
            <Stack direction="row" spacing={2} justifyContent="space-between" flexWrap="wrap" useFlexGap>
                <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
                    <Button variant="contained" endIcon={<AddIcon />} onClick={addProduct} disabled={salesDocumentReadOnly}>
                        {L10n.format(TranslationKeys.pages.project.composition.addLineItemButtonLabel)}
                    </Button>
                    <Button variant="contained" color="secondary" endIcon={<AddIcon />} onClick={() => addFolder()} disabled={salesDocumentReadOnly}>
                        {L10n.format(TranslationKeys.pages.project.composition.addFolderButtonLabel)}
                    </Button>
                    <Button variant="contained" color="secondary" endIcon={<AddIcon />} onClick={() => dialog.open()} disabled={salesDocumentReadOnly}>
                        {L10n.format(TranslationKeys.lineItem.newArticle.button.label)}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => collapseAllLineItems()}>
                        {L10n.format(TranslationKeys.pages.project.composition.collapseAllLineItemsButtonLabel)}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => expandAllLineItems()}>
                        {L10n.format(TranslationKeys.pages.project.composition.expandAllLineItemsButtonLabel)}
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <IconButton
                        color="secondary"
                        sx={{ backgroundColor: selectedProjectCompositionView === ProjectCompositionView.PROJECT_VIEW ? theme.palette.divider : undefined }}
                        onClick={() => setSelectedProjectCompositionView(ProjectCompositionView.PROJECT_VIEW)}
                    >
                        <ListIcon />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        sx={{ backgroundColor: selectedProjectCompositionView === ProjectCompositionView.CALCULATION_VIEW ? theme.palette.divider : undefined }}
                        onClick={() => setSelectedProjectCompositionView(ProjectCompositionView.CALCULATION_VIEW)}
                    >
                        <CalculateIcon />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        disabled={!OidcUtils.hasReadTransferPriceDiscountRole(userInformation)}
                        sx={{
                            backgroundColor:
                                selectedProjectCompositionView === ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW ? theme.palette.divider : undefined
                        }}
                        onClick={() => setSelectedProjectCompositionView(ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW)}
                    >
                        <PercentIcon />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        disabled={!OidcUtils.hasReadTransferPriceDiscountRole(userInformation)}
                        sx={{ backgroundColor: selectedProjectCompositionView === ProjectCompositionView.COST_VIEW ? theme.palette.divider : undefined }}
                        onClick={() => setSelectedProjectCompositionView(ProjectCompositionView.COST_VIEW)}
                    >
                        <SellIcon />
                    </IconButton>
                </Stack>
            </Stack>

            <LineItemsDataGrid
                onConfigure={openConfiguration}
                onDelete={lineItem => deleteLineItemsHandler.askToDeleteLineItems([lineItem.lineItemId])}
                onGenerateDocument={setLineItemToPrint}
                projectCompositionView={selectedProjectCompositionView}
            />

            <NewArticleDialog {...dialog} />

            <Dialog title={L10n.format(TranslationKeys.lineItem.actions.print)} onClose={() => setLineItemToPrint(undefined)} open={Boolean(lineItemToPrint)}>
                <DocumentGenerationForm documentTypes={documentTypes} onSubmit={submitDocumentGeneration} />
            </Dialog>

            <Dialog
                open={deleteLineItemsHandler.isAskingToDeleteLineItems}
                onClose={deleteLineItemsHandler.cancelDeletingLineItems}
                title={L10n.format(TranslationKeys.lineItem.deleteDialog.title)}
                message={L10n.format(TranslationKeys.lineItem.deleteDialog.message)}
                defaultDialogActions={{
                    onConfirm: deleteLineItemsHandler.deleteLineItems,
                    onCancel: deleteLineItemsHandler.cancelDeletingLineItems,
                    confirmButtonLabel: TranslationKeys.lineItem.deleteDialog.confirmButtonLabel,
                    cancelButtonLabel: TranslationKeys.lineItem.deleteDialog.cancelButtonLabel
                }}
            />
        </Stack>
    )
}
