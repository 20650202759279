import theme from "../../../../../../App.theme"

export const ProductQuickSelectionDataGridStyles = {
    "& .MuiDataGrid-row.Mui-selected": {
        backgroundColor: "transparent"
    },
    "& .quickSelectionBackground": {
        backgroundColor: theme.palette.background.default
    },
    ".MuiDataGrid-columnHeaderTitle": {
        whiteSpace: "normal",
        lineHeight: "normal",
        textTransform: "uppercase"
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "transparent"
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
        flexDirection: "row !important"
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within":
        {
            outline: "none"
        }
}
export const LoaderGridStyles = {
    minHeight: 150
}
