import { IRadioButtonStyles } from "@encoway/cui-configurator-components"
import CuiStyleConstants from "../../constants/CuiStyleConstants"
import { merge } from "lodash"

export const AbbRadioButtonStyles: IRadioButtonStyles = {
    choiceGroup: {
        ".ms-ChoiceField": {
            maxWidth: "100%"
        },
        ".ms-ChoiceField:not(:first-child)": {
            marginTop: 0
        }
    }
}

export const AbbHorizontalRadioButtonStyles = merge({}, AbbRadioButtonStyles, {
    choiceGroup: {
        ".ms-ChoiceField": {
            minWidth: 300,
            maxWidth: 300
        },
        ".ms-ChoiceFieldGroup-flexContainer": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            columnGap: CuiStyleConstants.COLUMN_GAP,
            rowGap: "8px"
        }
    }
})
