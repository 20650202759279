import { ILinkedTreeStyles } from "@encoway/cui-configurator-components"
import theme from "../../../../../../../../App.theme"
import CuiStyleConstants from "../../constants/CuiStyleConstants"

const AbbLinkedTreeStyles: ILinkedTreeStyles = {
    resizeBar: {
        display: "none"
    },
    root: {
        borderRadius: CuiStyleConstants.CONTAINER_BORDER_RADIUS,
        padding: 8,
        height: "100%",
        width: "100%",
        button: {
            backgroundColor: "transparent !important",
            color: theme.palette.text.primary
        },
        ".is-selected": {
            backgroundColor: theme.palette.background.default,
            borderRadius: 4
        },
        ".ms-Nav-groupContent": {
            marginBottom: 0
        }
    },
    resizeContainer: {
        width: "100%"
    }
}

export default AbbLinkedTreeStyles
