import { Stack } from "@fluentui/react/lib/Stack"
import { Image, ImageFit } from "@fluentui/react/lib/Image"
import placeholderImageSrc from "@encoway/cui-configurator-components/src/components/DetailDropdown/placeholder_icon.png"
import { mediaLink } from "@encoway/react-configurator"

interface ValueImageProps {
    image?: string
    size: string
    showImage: boolean
    imageResolution?: string
    className?: string
    mediaLink?: mediaLink
}

export const DetailDropdownValueImage = (props: ValueImageProps) => {
    const { image, showImage, imageResolution, className, size } = props
    if (showImage) {
        const imageSrc = image ? props.mediaLink?.(image, imageResolution || "tiny") : placeholderImageSrc
        return (
            <Stack.Item>
                <Image className={className} src={imageSrc} width={size} height={size} imageFit={ImageFit.centerContain} />
            </Stack.Item>
        )
    }
    return <Stack.Item />
}
