import theme from "../../../../../../App.theme"

const ProjectActionsStyles = {
    button: {
        color: theme.palette.text.primary,
        ":hover": {
            backgroundColor: theme.palette.background.default
        }
    },
    icon: {
        cursor: "context-menu"
    },
    menu: {
        "& li": {
            display: "flex",
            columnGap: 1
        }
    }
}

export default ProjectActionsStyles
