import { getTheme } from "@fluentui/react"
import { ISectionStyles } from "@encoway/cui-configurator-components/src/components/Section/Section.types"
import { merge } from "lodash"
import CuiStyleConstants from "../../constants/CuiStyleConstants"

const theme = getTheme()

export const AbbSectionStyles: Partial<ISectionStyles> = {
    root: {
        paddingBottom: 8,
        selectors: {
            ".ms-Label": {
                color: theme.palette.themePrimary
            }
        }
    }
}

export const AbbHorizontalSectionStyles = merge({}, AbbSectionStyles, {
    parameters: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        columnGap: CuiStyleConstants.COLUMN_GAP,
        selectors: {
            ">*": {
                minWidth: 300,
                maxWidth: 300
            }
        }
    }
})
