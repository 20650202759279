import { Stack, Typography, useTheme } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TimeAgo from "javascript-time-ago"
import { Settings } from "../../../../../../settings"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import { AbbSalesDocumentEntity } from "../../../../../../features/sales/sales.types"

export interface ProjectInformationProps {
    project: AbbSalesDocumentEntity
}

export default function ProjectInformation({ project }: ProjectInformationProps) {
    const theme = useTheme()
    const timeAgo = new TimeAgo(Settings.locale)
    return (
        <Stack>
            <Typography variant="h6" fontWeight="bold">
                {project.properties.project_name}
            </Typography>
            <Typography variant="caption" color={theme.palette.text.secondary}>
                {`${L10n.format(TranslationKeys.pages.dashboard.recentProjectsWidget.created)} ${timeAgo.format(
                    new Date(project.properties.quote_created_at!)
                )}`}
            </Typography>
            <Typography variant="caption">
                {`${L10n.format(TranslationKeys.pages.dashboard.recentProjectsWidget.modified)} ${timeAgo.format(
                    new Date(project.properties.quote_modified_at!)
                )}`}
            </Typography>
        </Stack>
    )
}
