import { Characteristic, NumberValue, Product } from "@encoway/c-services-js-client"
import { GridColDef, GridRowClassNameParams } from "@mui/x-data-grid-pro"
import CatalogUtils from "../../../../../../features/catalog/catalog.utils"
import { TextCell } from "./components/textCell/TextCell"

export function getRowId(product: Product) {
    return product.id
}

export function getRowClassName(params: GridRowClassNameParams<Product>) {
    return params.indexRelativeToCurrentPage % 2 === 0 ? "" : "quickSelectionBackground"
}

const determineMinWidth = (value?: string) => {
    return value ? 30 + value.length * 6 : undefined
}

export function getCharacteristicColumns(characteristics: Characteristic[]): GridColDef[] {
    return characteristics.map(characteristic => ({
        field: characteristic.id,
        headerName: characteristic.name,
        valueGetter: getValueGetter(characteristic),
        type: characteristic.valueType === "NUMBER" ? "number" : "string",
        sortComparator: getSortComparator(characteristic),
        renderCell: TextCell,
        minWidth: determineMinWidth(characteristic.name)
    }))
}

function getValueGetter(characteristic: Characteristic) {
    return (value: any, row: Product) =>
        characteristic.valueType === "NUMBER"
            ? CatalogUtils.getCharacteristicValue<NumberValue>(row, characteristic.id)?.value
            : CatalogUtils.getCharacteristicValue(row, characteristic.id)
}

function getSortComparator(characteristic: Characteristic) {
    return (v1: any, v2: any) => {
        const value1 = characteristic.possibleValues?.[v1]?.value ?? v1
        const value2 = characteristic.possibleValues?.[v2]?.value ?? v2
        if (value1 === value2) {
            return 0
        } else if (value1 > value2) {
            return 1
        } else {
            return -1
        }
    }
}
