import { Theme } from "@mui/material"

export const ErrorAlertStyles = {
    alert: {
        ".MuiAlert-icon": {
            alignItems: "center",
            display: "flex"
        },
        ".MuiAlert-message": {
            alignItems: "center",
            display: "flex",
            columnGap: 1
        }
    },
    button: {
        padding: 0,
        color: (theme: Theme) => theme.palette.common.white
    }
}
