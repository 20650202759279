import { ProjectConfiguration, ProjectConfigurationLineItem } from "../../../../features/sales/sales.api.types"
import { AbbLineItem } from "../../../../features/sales/sales.types"
import { ProjectConfigurationLineItemWithParent } from "./components/treeItem/TreeItem"
import SalesUtils from "../../../../features/sales/sales.utils"
import { isEmpty } from "lodash"
import TreeUtils from "../../../../utils/TreeUtils"
import { ViewIds } from "../../../../features/catalog/catalog.constants"

export function getLineItem(lineItemId: string, projectConfiguration: ProjectConfiguration): ProjectConfigurationLineItem | undefined {
    return TreeUtils.findRecursively(
        projectConfiguration.lineItems,
        item => item.children,
        item => item.lineItem.lineItemId === lineItemId
    )
}

export const getViewId = (searchParamViewId: string | null, lineItem?: AbbLineItem) => {
    if (searchParamViewId) {
        return searchParamViewId
    } else if (lineItem?.properties.ARTICLE_TYPE === "UNIT_OPTIONS") {
        return ViewIds.CONFIGURATION_INTERFACE
    } else {
        return undefined
    }
}

export function getAllConfigurableChildren(lineItemId: string, projectConfiguration: ProjectConfiguration): ProjectConfigurationLineItem[] {
    const allChildren = getAllChildren(lineItemId, projectConfiguration)
    return allChildren.filter(child => SalesUtils.lineItems.isArticle(child.lineItem) && SalesUtils.lineItems.isConfigurable(child.lineItem))
}

export function getAllChildren(lineItemId: string, projectConfiguration: ProjectConfiguration): ProjectConfigurationLineItem[] {
    const lineItem = getLineItem(lineItemId, projectConfiguration)
    return lineItem ? getChildrenRecursively(lineItem) : []
}

function getChildrenRecursively(lineItem: ProjectConfigurationLineItem): ProjectConfigurationLineItem[] {
    const children = lineItem.children
    return children.concat(children.flatMap(child => getChildrenRecursively(child)))
}

export function getParent(lineItemId: string, projectConfiguration: ProjectConfiguration): ProjectConfigurationLineItem | undefined {
    const lineItems = projectConfiguration.lineItems
    for (const lineItem of lineItems) {
        const parent = getParentRecursively(lineItemId, lineItem)
        if (parent) {
            return parent
        }
    }

    return undefined
}

function getParentRecursively(lineItemId: string, parent: ProjectConfigurationLineItem): ProjectConfigurationLineItem | undefined {
    const children = parent.children

    if (isEmpty(children)) {
        return undefined
    }

    for (const child of children) {
        if (child.lineItem.lineItemId === lineItemId) {
            return parent
        }

        const grandChild = getParentRecursively(lineItemId, child)
        if (grandChild) {
            return child
        }
    }
}

export function getProjectOptionsLineItem(projectConfiguration: ProjectConfiguration): AbbLineItem | undefined {
    return TreeUtils.findRecursively(
        projectConfiguration.lineItems,
        item => item.children,
        item => item.lineItem.properties.ARTICLE_TYPE === "PROJECT_OPTIONS"
    )?.lineItem
}

export function getLineupLineItem(item: ProjectConfigurationLineItemWithParent): AbbLineItem | undefined {
    if (!item.parent) {
        return undefined
    } else if (item.parent.lineItem.properties.FOLDER_TYPE === "LINEUP") {
        return item.parent.lineItem
    } else {
        return getLineupLineItem(item.parent)
    }
}

export function getPreviousItem(item: ProjectConfigurationLineItem, configuration: ProjectConfiguration, parent?: ProjectConfigurationLineItem) {
    if (parent) {
        const index = parent.children.findIndex(child => child.lineItem.lineItemId === item.lineItem.lineItemId)
        return index > 0 ? parent.children.at(index - 1) : undefined
    } else {
        const index = configuration.lineItems.findIndex(child => child.lineItem.lineItemId === item.lineItem.lineItemId)
        return index > 0 ? configuration.lineItems.at(index - 1) : undefined
    }
}

export function getAllLineItemIdsToExpand(lineItemId: string, items: ProjectConfigurationLineItem[], result = [] as string[]): string[] {
    for (const item of items) {
        if (item.lineItem.lineItemId === lineItemId) {
            return result.concat(lineItemId)
        } else {
            const lineItemIdsToExpand = getAllLineItemIdsToExpand(lineItemId, item.children, result.concat(item.lineItem.lineItemId))
            if (lineItemIdsToExpand.length - 1 > result.length) {
                return lineItemIdsToExpand
            }
        }
    }
    return []
}

export function getAllLineItemIdsToCollapse(item: ProjectConfigurationLineItem): string[] {
    if (item.children.length === 0) {
        return [item.lineItem.lineItemId]
    } else {
        const childLineItemIds = item.children.flatMap(child => getAllLineItemIdsToCollapse(child))
        return [item.lineItem.lineItemId].concat(childLineItemIds)
    }
}
