import { useMemo } from "react"
import { GenericInput } from "../../../components/forms/genericForm/GenericForm"
import SalesApi from "../sales.api"
import { SalesDocumentProperties } from "../sales.constants"
import TranslationKeys from "../../translations/TranslationKeys"

export function usePriceListInput(skip?: boolean): GenericInput {
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data
    const priceLists = SalesApi.usePriceListsQuery(undefined, { skip }).data
    return useMemo(
        () => ({
            id: SalesDocumentProperties.PRICE_LIST,
            label: TranslationKeys.project.properties.priceList,
            disabled: true,
            value: priceLists?.find(list => list.priceListId === salesDocument?.properties.quote_pricelist)?.properties["sys_pl_id"] ?? ""
        }),
        [priceLists, salesDocument?.properties.quote_pricelist]
    )
}
