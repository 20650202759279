import { useMemo } from "react"
import { projectInformationInputs } from "../constants/projectInformationInputs"
import { GenericInput } from "../../../../../components/forms/genericForm/GenericForm"
import { usePriceListInput } from "../../../../../features/sales/hooks/usePriceListInput"
import { useSalesDocumentPropertyInput } from "../../../../../features/sales/hooks/useSalesDocumentPropertyInput"
import { SalesDocumentProperties } from "../../../../../features/sales/sales.constants"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"

export function useProjectInformationFormInputs(): GenericInput[] {
    const localSalesUnitInput = useSalesDocumentPropertyInput(SalesDocumentProperties.LOCAL_SALES_UNIT, Characteristics.LocalSalesUnit.id, true, true)
    const priceListInput = usePriceListInput()
    const currencyInput = useSalesDocumentPropertyInput(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY, Characteristics.TransferPriceCurrency.id, true, true)
    const productCategoryInput = useSalesDocumentPropertyInput(SalesDocumentProperties.PRODUCT_CATEGORY, Characteristics.ProductCategory.id, true, true)
    return useMemo(
        () => projectInformationInputs.concat([localSalesUnitInput, priceListInput, currencyInput, productCategoryInput]),
        [localSalesUnitInput, priceListInput, currencyInput, productCategoryInput]
    )
}
