import { useCallback, useEffect, useRef } from "react"
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from "react-dnd"
import { Box } from "@mui/material"
import SalesApi from "../../../../../../features/sales/sales.api"
import { getDragAndDropHoverPosition } from "../../../../../../utils/getDragAndDropHoverPosition"
import SalesUtils from "../../../../../../features/sales/sales.utils"
import { getPreviousItem } from "../../ProjectConfiguration.utils"
import { TreeItemLabel } from "./TreeItem.Label"
import { TreeItemStyles } from "./TreeItem.styles"
import { ProjectConfigurationLineItem } from "../../../../../../features/sales/sales.api.types"
import { ProjectConfigurationLineItemWithParent } from "./TreeItem"

interface ProjectConfigurationDragAndDropTreeItemLabelProps {
    item: ProjectConfigurationLineItemWithParent
}

interface UnitMoveAction {
    hoverPosition: "top" | "bottom" | "center" | undefined
    draggedItem: ProjectConfigurationLineItem
}

const DRAG_AND_DROP_ITEM_TYPE = "lineItems"

export function TreeItemDragAndDropLabel({ item }: Readonly<ProjectConfigurationDragAndDropTreeItemLabelProps>) {
    const ref = useRef<HTMLDivElement>(null)
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const projectConfiguration = SalesApi.endpoints.projectConfiguration.useQueryState().data!
    const [moveLineItems] = SalesApi.useMoveLineItemsMutation()

    const canDrag = useCallback(
        (monitor: DragSourceMonitor) => {
            const rect = ref.current?.getElementsByClassName("DragAndDropButton")[0]?.getBoundingClientRect()
            const clientOffset = monitor.getClientOffset()
            return !!(rect && clientOffset && clientOffset.x >= rect.x && clientOffset.x <= rect.x + rect.width)
        },
        [ref]
    )

    const canDrop = useCallback(
        (draggedItem: ProjectConfigurationLineItem, monitor: DropTargetMonitor) => {
            const hoverPosition = getDragAndDropHoverPosition(monitor, ref)
            return draggedItem && SalesUtils.lineItems.canDropLineItem(draggedItem, item, salesDocument, hoverPosition)
        },
        [item, salesDocument]
    )

    const moveLineItemsToHoverPosition = useCallback(
        async (unitMoveAction: UnitMoveAction | undefined) => {
            if (unitMoveAction) {
                if (unitMoveAction.hoverPosition === "top") {
                    const previousBomNode = getPreviousItem(item, projectConfiguration, item.parent)
                    await moveLineItems({
                        selectedLineItemIds: [unitMoveAction.draggedItem.lineItem.lineItemId],
                        parentLineItemId: item.parent?.lineItem.lineItemId,
                        previousLineItem: previousBomNode?.lineItem.lineItemId
                    })
                } else if (unitMoveAction.hoverPosition === "bottom") {
                    await moveLineItems({
                        selectedLineItemIds: [unitMoveAction.draggedItem.lineItem.lineItemId],
                        parentLineItemId: item.parent?.lineItem.lineItemId,
                        previousLineItem: item.lineItem.lineItemId
                    })
                } else if (unitMoveAction.hoverPosition === "center") {
                    await moveLineItems({
                        selectedLineItemIds: [unitMoveAction.draggedItem.lineItem.lineItemId],
                        parentLineItemId: item.lineItem.lineItemId
                    })
                }
            }
        },
        [item, projectConfiguration, moveLineItems]
    )

    const drop = useCallback(
        (draggedItem: ProjectConfigurationLineItem, monitor: DropTargetMonitor) => {
            const hoverPosition = getDragAndDropHoverPosition(monitor, ref)
            let unitMoveAction = { hoverPosition: hoverPosition, draggedItem: draggedItem }
            moveLineItemsToHoverPosition(unitMoveAction)
        },
        [moveLineItemsToHoverPosition]
    )

    const dropCollect = useCallback(
        (monitor: DropTargetMonitor) => {
            return {
                hoverPosition: canDrop(monitor.getItem(), monitor) ? getDragAndDropHoverPosition(monitor, ref) : undefined,
                dragItem: monitor.getItem()
            }
        },
        [ref, canDrop]
    )

    const [dragProps, dragRef] = useDrag(
        () => ({
            type: DRAG_AND_DROP_ITEM_TYPE,
            item: item,
            collect: monitor => ({ difference: monitor.getDifferenceFromInitialOffset(), isDragging: monitor.isDragging() }),
            canDrag: canDrag
        }),
        [item, canDrag]
    )

    const [dropProps, dropRef] = useDrop(
        () => ({
            accept: DRAG_AND_DROP_ITEM_TYPE,
            collect: dropCollect,
            drop: drop,
            canDrop: canDrop
        }),
        [drop, dropCollect, canDrop]
    )

    useEffect(() => {
        dragRef(ref.current)
        dropRef(ref.current)
    }, [ref, dragRef, dropRef])

    return (
        <>
            {!dropProps.hoverPosition && dropProps.dragItem && <Box sx={TreeItemStyles.treeItemLabelDropNotAllowOverlay(ref.current)} />}
            <TreeItemLabel ref={ref} item={item} sx={TreeItemStyles.treeItemLabelDragAndDrop(dropProps.hoverPosition, dragProps.isDragging)} />
            {dragProps.isDragging && <TreeItemLabel item={item} sx={TreeItemStyles.treeItemLabelDragged(ref.current, dragProps.difference?.y)} />}
        </>
    )
}
