import theme from "../../../../App.theme"

const DocumentWidgetStyles = {
    title: {
        color: theme.palette.text.primary
    },
    inputLabel: {
        color: theme.palette.text.primary
    },
    textField: {
        width: "50%",
        backgroundColor: theme.palette.common.white
    },
    button: {
        backgroundColor: theme.palette.common.black,
        height: 50,
        padding: "0 25px"
    }
}

export default DocumentWidgetStyles
