import { useCallback, useMemo, useState } from "react"
import { AbbSalesDocumentEntity } from "../sales.types"
import SalesApi from "../sales.api"

export default function useDeleteSalesDocument() {
    const [salesDocumentToDelete, setSalesDocumentToDelete] = useState<AbbSalesDocumentEntity | undefined>()
    const [deleteSalesDocument] = SalesApi.useDeleteSalesDocumentMutation()

    const cancel = useCallback(() => setSalesDocumentToDelete(undefined), [])

    const confirm = useCallback(async () => {
        setSalesDocumentToDelete(undefined)
        await deleteSalesDocument(salesDocumentToDelete!.salesDocumentId)
    }, [deleteSalesDocument, salesDocumentToDelete])

    return useMemo(() => ({ salesDocumentToDelete, setSalesDocumentToDelete, confirm, cancel }), [salesDocumentToDelete, confirm, cancel])
}
