import { ITabsFooterStyles } from "@encoway/cui-configurator-components"

const AbbTabsFooterStyles: ITabsFooterStyles = {
    footerLeft: {
        display: "flex",
        flexDirection: "row-reverse",
        marginRight: 5
    },
    footerRight: {
        flexDirection: "row",
        marginLeft: 5
    }
}

export default AbbTabsFooterStyles
