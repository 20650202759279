import { useCallback, useMemo, useState } from "react"
import { AbbSalesDocumentEntity } from "../sales.types"
import useNavigate from "../../../router/hooks/useNavigate"
import { useUserInformation } from "../../oidc/hooks/useUserInformation"
import { useAppDispatch } from "../../../store/store"
import { SalesSlice } from "../sales.slice"

export default function useOpenSalesDocumentHandler() {
    const [lockedSalesDocument, setLockedSalesDocument] = useState<AbbSalesDocumentEntity | undefined>()
    const navigate = useNavigate()
    const userInformation = useUserInformation()
    const dispatch = useAppDispatch()

    const lockedSalesDocumentCanBeOpened = userInformation?.username === lockedSalesDocument?.properties.quote_locked_by

    const cancel = useCallback(() => setLockedSalesDocument(undefined), [])

    const openLockedSalesDocument = useCallback(
        (readOnly?: boolean) => {
            setLockedSalesDocument(undefined)
            dispatch(SalesSlice.actions.setSalesDocumentReadOnly(!!readOnly))
            navigate.toProjectDetails(lockedSalesDocument!.salesDocumentId)
        },
        [navigate, dispatch, lockedSalesDocument]
    )

    const open = useCallback(
        (salesDocument: AbbSalesDocumentEntity, readOnly: boolean) => {
            if (readOnly || !salesDocument.properties.quote_locked_by) {
                dispatch(SalesSlice.actions.setSalesDocumentReadOnly(readOnly))
                navigate.toProjectDetails(salesDocument.salesDocumentId)
            } else {
                setLockedSalesDocument(salesDocument)
            }
        },
        [navigate, dispatch]
    )

    return useMemo(
        () => ({
            open,
            cancel,
            lockedSalesDocument,
            lockedSalesDocumentCanBeOpened,
            openLockedSalesDocument
        }),
        [open, cancel, lockedSalesDocument, lockedSalesDocumentCanBeOpened, openLockedSalesDocument]
    )
}
