import { ICheckboxStyles } from "@encoway/cui-configurator-components"
import CuiStyleConstants from "../../constants/CuiStyleConstants"

const AbbCheckboxStyles: ICheckboxStyles = {
    checkbox: {
        ".ms-Checkbox-checkbox": {
            borderWidth: CuiStyleConstants.FIELD_BORDER_WIDTH,
            borderRadius: CuiStyleConstants.FIELD_BORDER_RADIUS
        }
    }
}

export default AbbCheckboxStyles
