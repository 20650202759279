import { getTheme } from "@fluentui/react"
import { CSSProperties } from "react"

const theme = getTheme()

const AbbTabsFooterPreviousButtonStyles: CSSProperties = {
    backgroundColor: theme.palette.themeLighter,
    color: theme.palette.themePrimary,
    borderWidth: 0,
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20
}

export default AbbTabsFooterPreviousButtonStyles
