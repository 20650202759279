import { Avatar, IconButton } from "@mui/material"
import { L10n } from "@encoway/l10n"
import UserStyles from "./User.styles"
import useDialog from "../../../../../components/dialog/useDialog"
import Dialog from "../../../../../components/dialog/Dialog"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { useUserInformation } from "../../../../../features/oidc/hooks/useUserInformation"
import { useLogout } from "../../../../../features/oidc/hooks/useLogout"

export default function User() {
    const dialog = useDialog()
    const logout = useLogout()
    const userInformation = useUserInformation()
    const name = userInformation ? userInformation.name : ""
    const initials = name
        ? name
              .split(" ")
              .map(n => n[0])
              .join("")
        : ""
    return (
        <>
            <IconButton size="small" component="label" onClick={dialog.open}>
                <Avatar alt={name} sx={UserStyles.avatar}>
                    {initials}
                </Avatar>
            </IconButton>

            <Dialog
                open={dialog.isOpen}
                onClose={dialog.close}
                title={L10n.format(TranslationKeys.components.header.logoutDialog.title)}
                message={L10n.format(TranslationKeys.components.header.logoutDialog.message)}
                defaultDialogActions={{
                    onConfirm: logout,
                    onCancel: dialog.close,
                    confirmButtonLabel: TranslationKeys.components.header.logoutDialog.confirmButtonLabel,
                    cancelButtonLabel: TranslationKeys.components.header.logoutDialog.cancelButtonLabel
                }}
            />
        </>
    )
}
