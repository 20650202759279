import { ConfigurationInterfaceDecisionsDifference } from "../../../sales.types"
import { Differences } from "./TooltipTitle.types"
import { Characteristic } from "@encoway/c-services-js-client"
import CatalogUtils from "../../../../catalog/catalog.utils"

export function mergeDifferences(
    projectOptionsDifferences: ConfigurationInterfaceDecisionsDifference[],
    lineupOptionsDifferences?: ConfigurationInterfaceDecisionsDifference[]
): Differences {
    const result: Differences = {}
    projectOptionsDifferences.forEach(difference => {
        result[difference.parameterName] = {
            configurationParameterValue: difference.targetParameterValue,
            projectOptionsParameterValue: difference.sourceParameterValue
        }
    })
    lineupOptionsDifferences?.forEach(difference => {
        result[difference.parameterName] = {
            ...result[difference.parameterName],
            configurationParameterValue: difference.targetParameterValue,
            lineupOptionsParameterValue: difference.sourceParameterValue
        }
    })
    return result
}

export function getPossibleValueTranslation(possibleValueId?: string, characteristic?: Characteristic) {
    return possibleValueId && characteristic ? CatalogUtils.getPossibleValueTranslation(possibleValueId, characteristic) : possibleValueId
}
