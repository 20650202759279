export const UnitOverviewTableStyles = {
    stack: {
        overflowX: "auto",
        overflowY: "hidden",
        marginTop: "2em",
        marginRight: "1.5em",
        paddingTop: "1em"
    },
    badge: {
        ".MuiBadge-badge": {
            transform: "none",
            position: "inherit",
            height: "25px",
            width: "25px",
            borderRadius: "12px",
            top: "-10px",
            left: "-15px"
        }
    }
}
