import { RadioButton, RadioButtonProps } from "@encoway/cui-configurator-components"
import { AbbHorizontalRadioButtonStyles, AbbRadioButtonStyles } from "./AbbRadioButton.styles"
import CuiUtils from "../../utils/CuiUtils"
import { Characteristics } from "../../../../../../../../features/catalog/catalog.constants"

export const AbbRadioButton = (props: RadioButtonProps) => {
    const orientationProperty = props.data.viewPortProperties?.[Characteristics.Orientation.id] as string
    const horizontal =
        !!orientationProperty && CuiUtils.readViewportPropertyValue(orientationProperty) === Characteristics.Orientation.possibleValues.HORIZONTAL
    return <RadioButton {...props} styles={horizontal ? AbbHorizontalRadioButtonStyles : AbbRadioButtonStyles} />
}
