const ProductInformationStyles = {
    stack: {
        maxWidth: 300,
        padding: 2
    },
    image: {
        width: "100%"
    },
    title: {
        fontSize: 20,
        fontWeight: "bold"
    }
}

export default ProductInformationStyles
