import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { Settings } from "../../settings"
import prepareHeaders from "../../store/prepareHeaders"

interface MatlabParams {
    configurationId: string
    parameterName: string
}

export const MatlabApi = createApi({
    reducerPath: "matlabApi",
    baseQuery: fetchBaseQuery({
        baseUrl: Settings.configuration.baseUrl,
        prepareHeaders: prepareHeaders
    }),
    endpoints: builder => ({
        matlab: builder.query<void, MatlabParams>({
            query: params => ({
                url: `/api/features/${params.configurationId}/calculate/${params.parameterName}`,
                method: "PUT",
                responseHandler: "text"
            })
        })
    })
})

export const { useLazyMatlabQuery } = MatlabApi
