import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import SalesUtils from "../../features/sales/sales.utils"
import theme from "../../App.theme"
import InfoIcon from "../../components/icons/infoIcon/InfoIcon"
import SalesApi from "../../features/sales/sales.api"

export function ProjectPageInfoIcon() {
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    return (
        <InfoIcon>
            <TableContainer>
                <Table>
                    <TableBody>
                        {SalesUtils.salesDocument.getMainProperties(salesDocument).map(property => (
                            <TableRow key={property.name} sx={{ td: { px: 1, py: 0 } }}>
                                <TableCell>
                                    <Typography fontWeight={theme.typography.fontWeightBold} whiteSpace="nowrap" textAlign="right">
                                        {property.name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{property.value as string}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </InfoIcon>
    )
}
