import { CSSProperties } from "react"
import theme from "../../App.theme"

export const ResizeBarStyles = (axis: "x" | "y"): CSSProperties => ({
    cursor: axis === "x" ? "ew-resize" : "ns-resize",
    boxSizing: "content-box",
    width: axis === "x" ? "3px" : undefined,
    height: axis === "y" ? "3px" : undefined,
    borderWidth: axis === "x" ? "0 3px" : "3px 0",
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    touchAction: "none",
    flexShrink: 0
})
