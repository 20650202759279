import DashboardWidget from "../dashboardWidget/DashboardWidget"
import { Stack } from "@mui/material"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import InputLabel from "@mui/material/InputLabel"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import DocumentWidgetStyles from "./DocumentsWidget.styles"

interface DocumentWidgetProps {
    hide?: boolean
}

export default function DocumentsWidget({ hide }: DocumentWidgetProps) {
    return hide ? null : (
        <DashboardWidget title={L10n.format(TranslationKeys.pages.dashboard.documentsWidget.title)} titleProps={{ sx: DocumentWidgetStyles.title }}>
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <InputLabel htmlFor="dashboard-documents-widget-text-field" sx={DocumentWidgetStyles.inputLabel}>
                        {L10n.format(TranslationKeys.pages.dashboard.documentsWidget.inputLabel)}
                    </InputLabel>
                    <TextField id="dashboard-documents-widget-text-field" sx={DocumentWidgetStyles.textField} />
                </Stack>
                <Stack direction="row">
                    <Button variant="contained" sx={DocumentWidgetStyles.button}>
                        {L10n.format(TranslationKeys.pages.dashboard.documentsWidget.buttonLabel)}
                    </Button>
                </Stack>
            </Stack>
        </DashboardWidget>
    )
}
