/* eslint-disable */
import { AcceptIcon, ClearIcon, EditSolid12Icon } from "@fluentui/react-icons-mdl2"
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react/lib/Button"
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog"
import { Stack } from "@fluentui/react/lib/Stack"
import { IProcessedStyleSet, mergeStyles, registerIcons } from "@fluentui/react/lib/Styling"
import { Text } from "@fluentui/react/lib/Text"
import { TextField } from "@fluentui/react/lib/TextField"
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities"
import React, { FormEvent, useCallback, useEffect, useState } from "react"

import { Quantity as QuantityTO } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"
import { IQuantityStyles, QuantityProps, QuantityStyles } from "@encoway/cui-configurator-components"
import { useValidation } from "../../validation/customValidation" /* CUSTOMIZING */
import { determineLayerClass } from "@encoway/cui-configurator-components/dist/esm/helperFunctions"
import toFormattedString from "../../../../../../../../utils/toFormattedString"

const ACCEPT = "Accept"
const CLEAR = "Clear"
const EDIT = "EditSolid12"

registerIcons(
    {
        icons: {
            [ACCEPT]: <AcceptIcon />,
            [CLEAR]: <ClearIcon />,
            [EDIT]: <EditSolid12Icon />
        }
    },
    { disableWarnings: true }
)

const numberFormatOptions = { maximumFractionDigits: 20 }

const translate = (key: string) => {
    return L10n.format(key, undefined)
}

const getQuantityData = (quantity: QuantityTO, readOnly: boolean, error?: string, ignoreEditable?: boolean) => {
    const editable = (quantity.editable || ignoreEditable) && quantity.minimumQuantity !== quantity.maximumQuantity

    const hidden = (!editable && quantity.currentQuantity === 1) || (editable && readOnly && quantity.currentQuantity === 1)

    const hasRestrictedQuantity = quantity.currentMaximumQuantity !== quantity.maximumQuantity

    const max =
        editable && quantity.maximumQuantity === undefined
            ? `${L10n.format("Configuration.Quantity.Positive.Infinity")}`
            : toFormattedString(quantity.maximumQuantity, numberFormatOptions) /* CUSTOMIZING */
    const min =
        editable && quantity.minimumQuantity === undefined
            ? `${L10n.format("Configuration.Quantity.Negative.Infinity")}`
            : toFormattedString(quantity.minimumQuantity, numberFormatOptions) /* CUSTOMIZING */

    const restriction = hasRestrictedQuantity
        ? {
              minimumQuantity: toFormattedString(quantity.currentQuantity, numberFormatOptions) /* CUSTOMIZING */,
              maximumQuantity: toFormattedString(quantity.currentMaximumQuantity, numberFormatOptions) /* CUSTOMIZING */
          }
        : undefined

    const restrictedLabel =
        !error &&
        restriction &&
        (restriction.minimumQuantity !== restriction.maximumQuantity
            ? `${L10n.format("Configuration.Quantity.Restricted.Label")} ${restriction.minimumQuantity} - ${restriction.maximumQuantity}`
            : `${L10n.format("Configuration.Quantity.Restricted.Label")} ${restriction.maximumQuantity}`)
    return [min, max, hidden, editable, restrictedLabel]
}

interface EditIconProps {
    buttonClass?: string
    visible?: boolean
    onClick: () => void
}
const EditIcon = (props: EditIconProps) => {
    return props.visible ? <IconButton className={props.buttonClass} iconProps={{ iconName: EDIT }} onClick={props.onClick} /> : null
}

/**
 * The Quantity component renders the quantity as a MS Fluent Text for selected values if both the quantity exists and its amount is greater than 1.
 * If the minimum and maximum values of the quantity are not equal, a MS Fluent IconButton is displayed next to the quantity itself, opening a MS Fluent Callout, which contains a MS Fluent TextField that is used to edit the quantity.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Quantity/Quantity.tsx)
 * - [QuantityStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Quantity/Quantity.styles.ts)
 * - [QuantityProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Quantity/Quantity.types.ts)
 * - [MS Fluent Stack](https://developer.microsoft.com/de-DE/fluentui#/controls/web/stack)
 * - [MS Fluent Text](https://developer.microsoft.com/de-DE/fluentui#/controls/web/text)
 * - [MS Fluent Button](https://developer.microsoft.com/de-DE/fluentui#/controls/web/button)
 * - [MS Fluent Callout](https://developer.microsoft.com/de-DE/fluentui#/controls/web/callout)
 * - [MS Fluent TextField](https://developer.microsoft.com/de-DE/fluentui#/controls/web/textfield)
 *
 * @visibleName Quantity
 */
function IQuantity(props: QuantityProps) {
    const { styles, theme } = props
    const classNames = classNamesFunction()(styles, theme) as IProcessedStyleSet<IQuantityStyles>
    const quantityID = props.quantityId || `quantity-${props.data.id}`
    const quantity = props.quantity || props.data.values?.find(v => v.selected)?.quantity
    const readOnly = props.options?.readOnly

    const [editing, setEditing] = useState(false)
    const [currentValue, setCurrentValue] = useState("")
    const [error, setError] = useState<string | undefined>()
    const min = quantity?.minimumQuantity ?? 0
    const max = quantity?.maximumQuantity && quantity.maximumQuantity < min ? min : quantity?.maximumQuantity
    const validate = useValidation(
        "Quantity",
        setError,
        toFormattedString(min, numberFormatOptions) /* CUSTOMIZING */,
        toFormattedString(max, numberFormatOptions) /* CUSTOMIZING */,
        min,
        max
    )

    function setInitialValue() {
        setCurrentValue(toFormattedString(quantity?.currentQuantity, numberFormatOptions) /* CUSTOMIZING */ || "")
        setError(undefined)
    }

    useEffect(() => {
        if (quantity) {
            setInitialValue()
        }
    }, [quantity])

    const onChange = useCallback(
        (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newQuantity = event.currentTarget.value

            setCurrentValue(newQuantity || "")

            validate(newQuantity, {
                updateError: true,
                removeError: true
            })
        },
        [setCurrentValue, validate]
    )

    if (!quantity || quantity.maximumQuantity <= 1) {
        return null
    }

    const submit = () => {
        const validatedNumber = validate(currentValue, {
            updateError: true,
            removeError: true
        })

        if (!isNaN(validatedNumber)) {
            if (quantity?.currentQuantity !== validatedNumber) {
                setInitialValue()
                props.onValueChanged(props.data, { quantity: validatedNumber }, undefined)
            }
            setEditing(false)
        }
    }

    const discard = () => {
        if (error || quantity.currentQuantity !== validate(currentValue)) {
            setInitialValue()
        }
        setEditing(false)
    }

    const onKeyPressed = (event: React.KeyboardEvent) => {
        if (event.charCode === 13) {
            submit()
        }
    }

    const [minQuantity, maxQuantity, hideQuantity, isQuantityEditable, restrictedLabel] = getQuantityData(
        quantity,
        readOnly === true,
        error,
        props.ignoreEditable
    )

    let className = classNames.inputField
    if (error) {
        className = mergeStyles(className, classNames.inputError)
    }

    const textClass = readOnly ? classNames.disabled : undefined

    const label: string | undefined = error ? undefined : `${L10n.format("Configuration.Quantity.Label")} ${minQuantity} - ${maxQuantity}`

    const modalProps = {
        className: mergeStyles(classNames.dialog, classNames.modal, determineLayerClass(props.modalProps?.layerProps, classNames.layer)),
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
        overlay: {
            isDarkThemed: false,
            className: classNames.overlay
        },
        ...props.modalProps
    }

    const dialogContentProps = {
        type: DialogType.normal,
        showCloseButton: true,
        title: translate("Configuration.Dialog.quantity")
    }
    return hideQuantity ? null : (
        <Stack horizontal className={classNames.root}>
            <Text nowrap id={quantityID} className={textClass}>
                {`${toFormattedString(quantity.currentQuantity, numberFormatOptions) /* CUSTOMIZING */} ${L10n.format("Configuration.Quantity.Label.Short")}`}
            </Text>
            <EditIcon buttonClass={classNames.iconButton} visible={isQuantityEditable === true && !readOnly} onClick={() => setEditing(true)} />
            {editing && (
                <Dialog
                    hidden={!editing}
                    onDismiss={() => {
                        discard()
                        setEditing(false)
                    }}
                    dialogContentProps={dialogContentProps}
                    minWidth={"25rem"}
                    modalProps={modalProps}
                >
                    <TextField
                        type="number"
                        className={className}
                        description={label}
                        value={currentValue}
                        autoFocus
                        onChange={onChange}
                        onKeyPress={onKeyPressed}
                    />
                    {restrictedLabel && <Text className={classNames.restrictedQuantity}>{restrictedLabel}</Text>}
                    {error && <Text className={classNames.errorMessage}>{error}</Text>}
                    <DialogFooter className={classNames.footer}>
                        <PrimaryButton
                            onClick={submit}
                            disabled={error !== undefined}
                            text={translate("Configuration.Dialog.accept")}
                            iconProps={{ iconName: ACCEPT }}
                            className={classNames.acceptButton}
                        />
                        <DefaultButton
                            onClick={discard}
                            text={translate("Configuration.Dialog.reject")}
                            iconProps={{ iconName: CLEAR }}
                            className={classNames.rejectButton}
                        />
                    </DialogFooter>
                </Dialog>
            )}
        </Stack>
    )
}

/**
 * The Quantity component renders the quantity as a MS Fluent Text for selected values if both the quantity exists and its amount is greater than 1.
 * If the minimum and maximum values of the quantity are not equal, a MS Fluent IconButton is displayed next to the quantity itself, opening a MS Fluent Callout, which contains a MS Fluent TextField that is used to edit the quantity.
 *
 * @see QuantityStyles
 * @see QuantityProps
 */
export const CuiQuantityWithCustomValueFormatting = styled(IQuantity, QuantityStyles)
CuiQuantityWithCustomValueFormatting.displayName = "Quantity"
