import { AbbLineItem } from "../../sales.types"
import { Badge } from "@mui/material"
import { FolderOutlined, Person, Settings, SpaceDashboardOutlined, Tune } from "@mui/icons-material"
import Icon, { IconProps } from "../../../../components/icons/Icon"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../translations/TranslationKeys"
import theme from "../../../../App.theme"
import SalesUtils from "../../sales.utils"
import { isFooter } from "../../../../pages/projectPage/components/projectComposition/lineItemsDataGrid/components/row/rowUtils"

interface LineItemTypeIconProps extends Omit<IconProps, "component"> {
    lineItem: AbbLineItem
}

export function LineItemTypeIcon({ lineItem, ...iconProps }: Readonly<LineItemTypeIconProps>) {
    if (lineItem.properties.ARTICLE_TYPE === "PROJECT_OPTIONS") {
        return <Icon component={Tune} tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.projectOptions) }} {...iconProps} />
    } else if (lineItem.properties.ARTICLE_TYPE === "UNIT_OPTIONS") {
        return <Icon component={Tune} tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.unitOptions) }} {...iconProps} />
    } else if (lineItem.properties.isCustomLineItem) {
        return (
            <Badge badgeContent={<Person />}>
                <Icon component={SpaceDashboardOutlined} tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.customArticle) }} {...iconProps} />
            </Badge>
        )
    } else if (lineItem.properties.configurable) {
        return (
            <Badge badgeContent={<Settings />}>
                <Icon
                    component={SpaceDashboardOutlined}
                    sx={{ cursor: "pointer", ":hover": { color: theme.palette.text.secondary } }}
                    tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.configurableArticle) }}
                    {...iconProps}
                />
            </Badge>
        )
    } else if (lineItem.properties.isArticle) {
        return <Icon component={SpaceDashboardOutlined} tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.usualArticle) }} {...iconProps} />
    } else if (SalesUtils.lineItems.isFolder(lineItem) && !isFooter(lineItem)) {
        if (lineItem.properties.FOLDER_TYPE === "LINEUP") {
            return (
                <Badge badgeContent={<Settings />}>
                    <Icon component={FolderOutlined} tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.lineup) }} {...iconProps} />
                </Badge>
            )
        } else {
            return <Icon component={FolderOutlined} tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.folder) }} {...iconProps} />
        }
    } else {
        return null
    }
}
