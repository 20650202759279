import {
    AbbLineItem,
    AbbLineItemProperties,
    AbbLineItemWithParent,
    AbbSalesDocumentEntity,
    ConfigurationInterfaceDecisions,
    ConfigurationInterfaceDecisionsDifference
} from "../sales.types"
import { DragAndDropHoverPosition } from "../../../utils/getDragAndDropHoverPosition"
import { isFooter } from "../../../pages/projectPage/components/projectComposition/lineItemsDataGrid/components/row/rowUtils"
import { isNil } from "lodash"

export default class LineItemsUtils {
    static getConfigurationInterfaceDecisionsDifferences(
        targetLineItem: AbbLineItem,
        sourceLineItem: AbbLineItem
    ): ConfigurationInterfaceDecisionsDifference[] {
        const targetDecisionsJson = targetLineItem.properties.CONFIGURATION_INTERFACE_DECISIONS
        const sourceDecisionsJson = sourceLineItem.properties.CONFIGURATION_INTERFACE_DECISIONS
        try {
            const targetDecisions = targetDecisionsJson ? JSON.parse(targetDecisionsJson) : undefined
            const sourceDecisions = sourceDecisionsJson ? JSON.parse(sourceDecisionsJson) : undefined
            return targetDecisions && sourceDecisions ? this.getDifferences(targetDecisions, sourceDecisions) : []
        } catch {
            return []
        }
    }

    private static getDifferences(
        targetDecisions: ConfigurationInterfaceDecisions,
        sourceDecisions: ConfigurationInterfaceDecisions
    ): ConfigurationInterfaceDecisionsDifference[] {
        return targetDecisions.decisions.reduce((result: ConfigurationInterfaceDecisionsDifference[], targetDecision) => {
            const sourceDecision = sourceDecisions.decisions.find(decision => decision.parameterName === targetDecision.parameterName)
            return sourceDecision && sourceDecision.parameterValue !== targetDecision.parameterValue
                ? result.concat({
                      parameterName: targetDecision.parameterName,
                      targetParameterValue: targetDecision.parameterValue,
                      sourceParameterValue: sourceDecision.parameterValue
                  })
                : result
        }, [])
    }

    static getConfigurationInterfaceTargetLineItemIds(lineItem: AbbLineItem) {
        const propertyValue = lineItem.properties.CONFIGURATION_INTERFACE_TARGET_LINE_ITEM_IDS
        return propertyValue ? propertyValue.split(",") : undefined
    }

    static isDraggableLineItem(lineItem: AbbLineItem) {
        return lineItem.permission.includes("UPDATE") && !lineItem.properties.isSubarticle && !this.isProjectOptions(lineItem)
    }

    static isEditableLineItemProperty(lineItem: AbbLineItem, property: keyof AbbLineItemProperties) {
        return lineItem.permission.includes("UPDATE") && !!lineItem.propertyPermissions[property]?.includes("UPDATE")
    }

    static isFolder(lineItem: AbbLineItem) {
        return !lineItem.properties.isArticle
    }

    static isLineupFolder(lineItem: AbbLineItem) {
        return lineItem.properties.FOLDER_TYPE === "LINEUP"
    }

    static isProjectOptions(lineItem: AbbLineItem) {
        return lineItem.properties.ARTICLE_TYPE === "PROJECT_OPTIONS"
    }

    static getSubName(lineItem: AbbLineItem) {
        if (this.isFolder(lineItem) && !isNil(lineItem.properties.label)) {
            return lineItem.properties.label
        } else if (!isNil(lineItem.properties.CONFIGURATION_NAME) && lineItem.properties.CONFIGURATION_NAME !== lineItem.properties.label) {
            return lineItem.properties.CONFIGURATION_NAME
        } else {
            return undefined
        }
    }

    static isArticle(lineItem: AbbLineItem) {
        return !!lineItem.properties.isArticle
    }

    static isConfigurable(lineItem: AbbLineItem) {
        return !!lineItem.properties.configurable
    }

    static canDropLineItem(
        dragElement: AbbLineItemWithParent,
        dropElement: AbbLineItemWithParent,
        salesDocument: AbbSalesDocumentEntity,
        hoverPosition: DragAndDropHoverPosition
    ) {
        if (!hoverPosition || isFooter(dropElement.lineItem)) {
            return false
        } else if (dragElement.lineItem.properties.FOLDER_TYPE === "LINEUP") {
            return this.canDropLineup(dropElement, salesDocument, hoverPosition)
        }

        const parent = hoverPosition === "center" ? dropElement : dropElement.parent
        const lineup = this.getLineup(dragElement)
        const isLineupCompatible = dragElement.lineItem.properties.LINEUP_COMPATIBLE || false
        if (parent) {
            return isLineupCompatible && parent.lineItem.permission.includes("UPDATE") && this.isFolder(parent.lineItem) && this.isArticle(dragElement.lineItem)
        } else {
            return salesDocument.permission.includes("UPDATE") && !lineup
        }
    }

    // lineups may not be moved into folders
    private static canDropLineup(dropElement: AbbLineItemWithParent, salesDocument: AbbSalesDocumentEntity, hoverPosition: DragAndDropHoverPosition) {
        return (hoverPosition === "top" || hoverPosition === "bottom") && !dropElement.parent && salesDocument.permission.includes("UPDATE")
    }

    private static getLineup(lineItem: AbbLineItemWithParent): AbbLineItemWithParent | undefined {
        if (!lineItem.parent) {
            return undefined
        } else if (lineItem.parent.lineItem.properties.FOLDER_TYPE === "LINEUP") {
            return lineItem.parent
        } else {
            return this.getLineup(lineItem.parent)
        }
    }
}
