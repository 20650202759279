import { GridColDef } from "@mui/x-data-grid-pro"
import TranslationKeys from "../../../../../../../../features/translations/TranslationKeys"
import { ActionsCell } from "./ActionsCell"
import { LineItemProperties } from "../../../../../../../../features/sales/sales.constants"
import { getLineItemCellValue } from "./UnitOverviewTable.utils"

export const UnitOverviewTableColumns: GridColDef[] = [
    {
        field: LineItemProperties.TYPE_CODE,
        headerName: TranslationKeys.lineItem.properties.typeCode,
        valueGetter: getLineItemCellValue,
        minWidth: 200
    },
    {
        field: LineItemProperties.CONFIGURATION_NAME,
        headerName: TranslationKeys.lineItem.properties.configurationName,
        valueGetter: getLineItemCellValue,
        minWidth: 200
    },
    {
        field: LineItemProperties.FUNCTION_DESIGNATIONS,
        headerName: TranslationKeys.lineItem.properties.functionDesignation,
        valueGetter: getLineItemCellValue,
        minWidth: 200
    },
    {
        field: LineItemProperties.LOCATION_DESIGNATIONS,
        headerName: TranslationKeys.lineItem.properties.locationDesignation,
        valueGetter: getLineItemCellValue,
        minWidth: 200
    },
    {
        field: "actions",
        headerName: "",
        resizable: false,
        renderCell: ActionsCell
    }
]
