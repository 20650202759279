export default class SalesConfigurationUtils {
    static readConfigurationResponse(response: string): Record<string, string> {
        return response
            .split("?")
            .at(1)!
            .split("&")
            .reduce((properties, property) => {
                const [key, value] = property.split("=")
                return { ...properties, [key]: value }
            }, {})
    }

    static readInvalidPresets(invalidPresets: string) {
        return invalidPresets.split(",").reduce((result: Record<string, string>, preset) => {
            const [key, value] = preset.split(":")
            result[key] = value
            return result
        }, {})
    }
}
