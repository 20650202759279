import Dialog, { DialogProps } from "../../../../components/dialog/Dialog"
import { TError } from "../../../../features/error/error.slice"
import TranslationKeys from "../../../../features/translations/TranslationKeys"

interface ErrorDialogProps extends Omit<DialogProps, "title" | "message" | "open"> {
    error: TError
}

const ErrorDialog = ({ error, ...dialogProps }: ErrorDialogProps) => {
    return (
        <Dialog
            open={true}
            title={error.name}
            message={error.message}
            interpretMessageAsDangerouslySetInnerHTML={"activateDangerouslySetInnerHTML" in error && error.activateDangerouslySetInnerHTML}
            customDialogActions={{
                actions: [
                    {
                        label: TranslationKeys.error.button,
                        onClick: dialogProps.onClose,
                        variant: "contained"
                    }
                ]
            }}
            {...dialogProps}
        />
    )
}

export default ErrorDialog
