import { Typography } from "@mui/material"
import { TreeItemIndentationStyles } from "./TreeItemIndentation.styles"

interface TreeItemIndentationProps {
    depth: number
}

export const TreeItemIndentation = ({ depth }: TreeItemIndentationProps) => {
    return <Typography component="span" sx={TreeItemIndentationStyles(depth)} />
}
