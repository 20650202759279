import { Stack } from "@mui/material"
import RecentProjectsWidget from "./components/recentProjectsWidget/RecentProjectsWidget"
import DocumentsWidget from "./components/documentsWidget/DocumentsWidget"
import HelpWidget from "./components/helpWidget/HelpWidget"
import NewsWidget from "./components/newsWidget/NewsWidget"
import { MainContainer } from "../../components/mainContainer/MainContainer"

export default function DashboardPage() {
    return (
        <MainContainer>
            <Stack spacing={10} marginTop={8}>
                <RecentProjectsWidget />
                <DocumentsWidget hide />
                <HelpWidget />
                <NewsWidget />
            </Stack>
        </MainContainer>
    )
}
