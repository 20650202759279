import { loadTheme } from "@fluentui/react"

const CuiTheme = loadTheme({
    palette: {
        themePrimary: "#212121", // hover in selectbox and active
        themeLighterAlt: "#f5f5f5", // selectbox hover
        themeLighter: "#f0f0f0", // tab ready
        themeLight: "#ff0000",
        themeTertiary: "#ff0000",
        themeSecondary: "#ff0000",
        themeDarkAlt: "#ff0000",
        themeDark: "#ff0000",
        themeDarker: "#ff0000",
        neutralLighterAlt: "#ffffff",
        neutralLighter: "#f0f0f0",
        neutralLight: "#ffffff",
        neutralQuaternaryAlt: "#212121", // nothing
        neutralQuaternary: "#212121", // nothing
        neutralTertiaryAlt: "#212121", // header line
        neutralTertiary: "#C8C8C8", // border and placeholder
        neutralSecondary: "#999999", // caption
        neutralPrimaryAlt: "#ffffff",
        neutralPrimary: "#212121", // parameter text
        neutralDark: "#212121", // nothing
        black: "#212121", // nothing
        white: "#ffffff" // active tab & background
    },
    semanticColors: {
        primaryButtonBackground: "#ff0000", // nothing
        accentButtonBackground: "#0000ff", // nothing
        warningBackground: "#ffffff", // tab not ready / missing decision
        menuBackground: "#ffffff"
    }
})

export default CuiTheme
