const SnackbarStyles = {
    snackbar: {
        position: "static",
        maxWidth: 320,
        hover: "disabled"
    },
    alert: {
        width: "100%",
        hover: "disabled",
        overflowWrap: "break-word"
    },
    progressBar: {
        transform: "scaleX(-1)"
    }
}

export default SnackbarStyles
