import theme from "../../../App.theme"

export const NewTabLinkStyles = (linkIsDefined: boolean, disabled: boolean) => ({
    alignItems: "center",
    columnGap: 0.5,
    color: linkIsDefined ? theme.palette.text.primary : theme.palette.text.disabled,
    textDecorationColor: linkIsDefined ? theme.palette.text.primary : theme.palette.text.disabled,
    fontSize: theme.typography.button.fontSize,
    opacity: disabled ? 0.5 : 1
})
