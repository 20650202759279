import TableUtils, { ITable } from "./TableUtils"

export default function checkForFaultyTableDefinition(table: ITable) {
    checkEmptyTable(table)
    checkSize(table)
    checkName(table)
}

function checkEmptyTable(table: ITable) {
    if (table.columns.length === 0 || table.rows.length === 0) {
        console.error("Received empty table!")
    }
}

function checkSize(table: ITable) {
    table.rows.forEach((row, index) => {
        if (row.children.length !== table.columns.length) {
            console.error(`Column header size and size of columns inside a row does not match for row: ${index}!`)
        }
    })
}

function checkName(table: ITable) {
    table.rows.forEach((row, rowIndex) => {
        row.children.forEach((rowContent, rowColumnIndex) => {
            if (!table.columns.find((column, index) => TableUtils.getTableKey(column, index) === TableUtils.getTableKey(rowContent, rowColumnIndex))) {
                console.error(
                    `Column header name and name of a column inside a row does not match for row: ${rowIndex} and column: ${rowColumnIndex}`,
                    "Column header name: " + TableUtils.getTableKey(table.columns[rowColumnIndex], rowColumnIndex),
                    "Name of the column inside a row: " + TableUtils.getTableKey(rowContent, rowColumnIndex)
                )
            }
        })
    })
}
