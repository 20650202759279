import { Button } from "@mui/material"
import { GridNoRowsOverlay, GridOverlay, NoRowsOverlayPropsOverrides } from "@mui/x-data-grid-pro"
import ErrorIcon from "@mui/icons-material/Error"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"

declare module "@mui/x-data-grid-pro" {
    interface NoRowsOverlayPropsOverrides {
        error?: string
        onRetry?: () => any
    }
}

export function NoRowsOverlay({ error, onRetry }: Readonly<NoRowsOverlayPropsOverrides>) {
    return !error ? (
        <GridNoRowsOverlay />
    ) : (
        <GridOverlay>
            <ErrorIcon color="error" sx={{ marginRight: 1 }} />
            {error}
            {onRetry && (
                <Button variant="text" onClick={onRetry} disableRipple>
                    {L10n.format(TranslationKeys.error.retry)}
                </Button>
            )}
        </GridOverlay>
    )
}
