import { CircularProgress, CircularProgressProps, Stack, StackProps, Typography } from "@mui/material"

interface AbbSpinnerProps extends CircularProgressProps {
    label?: string
    stackProps?: StackProps
}

const AbbSpinner = ({ label, stackProps, ...circularProgressProps }: AbbSpinnerProps) => {
    return (
        <Stack data-cy={`AbbSpinner${label ? ":" + label : ""}`} spacing="3" alignItems="center" padding={2} {...stackProps}>
            <CircularProgress data-cy={`CircularProgress`} thickness={2} {...circularProgressProps} />
            <Typography>{label}</Typography>
        </Stack>
    )
}

export default AbbSpinner
