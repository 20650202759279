import { ParameterTO } from "@encoway/c-services-js-client"
import { Alert } from "@mui/material"
import AbbCheckResultStyles from "./AbbCheckResult.styles"
import { AlertColor } from "@mui/material/Alert/Alert"

interface CheckResultProps {
    data: ParameterTO
}

const severityMapper: { [key: string]: AlertColor } = {
    passed: "success",
    failed: "error"
}

export default function AbbCheckResult(props: Readonly<CheckResultProps>) {
    const check = props.data.selectedValues?.at(0)?.value || "notPerformed"
    const translatedValue = props.data.selectedValues?.at(0)?.translatedValue || "not performed"
    return (
        <Alert severity={severityMapper[check] || "warning"} variant={"outlined"} sx={AbbCheckResultStyles}>
            {props.data.translatedName} {translatedValue}
        </Alert>
    )
}
