import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { useContext } from "react"
import { BomNode, LineItemDataGridContext } from "../../LineItemsDataGrid"
import { useAppSelector } from "../../../../../../../store/store"
import { LineItemTypeIcon } from "../../../../../../../features/sales/components/lineItemTypeIcon/LineItemTypeIcon"

export default function LineItemTypeCell(params: Readonly<GridRenderCellParams<BomNode>>) {
    const { onConfigure } = useContext(LineItemDataGridContext)!
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    return (
        <LineItemTypeIcon
            lineItem={params.row.lineItem}
            onClick={params.row.lineItem.properties.configurable ? () => onConfigure(params.row.lineItem, readOnly) : undefined}
        />
    )
}
