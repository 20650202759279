import { MessageBar, MessageBarType } from "@fluentui/react"
import { ParameterTO, Value } from "@encoway/c-services-js-client"
import MatlabWarningStyles from "./MatlabWarning.styles"

type MatlabWarningProps = {
    data: ParameterTO
}

const MatlabWarning = (props: MatlabWarningProps) => {
    // Value can be "1.0" or "1" - thus we use the plus operator
    // which returns the numeric representation 1 in both cases
    const isFlagged = +(props.data.selectedValues as Value[])[0]?.value === 1
    return isFlagged ? (
        <div style={{ margin: "5px 0" }}>
            <MessageBar messageBarType={MessageBarType.severeWarning} styles={MatlabWarningStyles}>
                {props.data.translatedName}
            </MessageBar>
        </div>
    ) : null
}

export default MatlabWarning
