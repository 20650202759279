import { Box, Card, Skeleton, Stack, Typography } from "@mui/material"
import { Product } from "@encoway/c-services-js-client"
import NewsCardStyles from "./NewsCard.styles"
import Logo from "../../../../../assets/logo.svg"
import Image from "../../../../../components/image/Image"
import { getNewsData } from "./NewsCard.utils"

interface NewsCardProps {
    product?: Product
}

export default function NewsCard({ product }: Readonly<NewsCardProps>) {
    const newsData = product ? getNewsData(product) : undefined
    return (
        <Card component={Stack} spacing={1} sx={NewsCardStyles.card}>
            {newsData ? (
                <Image
                    src={newsData.imageSrc}
                    style={{ width: "100%" }}
                    fallbackImageProps={{ src: Logo, alt: "image of " + newsData.name, sx: NewsCardStyles.image }}
                />
            ) : (
                <Skeleton variant="rounded" height={150} />
            )}
            <Stack>
                <Typography sx={NewsCardStyles.title}>{newsData ? newsData.name : <Skeleton />}</Typography>
                {newsData?.timeAgo ? <Typography sx={NewsCardStyles.timeAgo}>{newsData.timeAgo}</Typography> : null}
                {newsData ? null : (
                    <Typography sx={NewsCardStyles.timeAgo}>
                        <Skeleton />
                    </Typography>
                )}
            </Stack>
            {newsData?.longText ? <Box dangerouslySetInnerHTML={{ __html: newsData.longText }} /> : null}
            {newsData ? null : <Skeleton />}
        </Card>
    )
}
