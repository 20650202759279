import { IconButton } from "@mui/material"
import { TreeItemStyles } from "./TreeItem.styles"
import { VisibilityOutlined } from "@mui/icons-material"
import Icon from "../../../../../../components/icons/Icon"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import { useSaveConfiguration } from "../../hooks/useSaveConfiguration"
import { AbbLineItem } from "../../../../../../features/sales/sales.types"
import { useSearchParams } from "react-router-dom"
import { SalesSlice } from "../../../../../../features/sales/sales.slice"
import { ProjectConfigurationSearchParams } from "../../ProjectConfiguration.constants"

type FocusLineUpVisualizationButtonProps = {
    item: AbbLineItem
}

export const FocusLineUpVisualizationButton = ({ item }: FocusLineUpVisualizationButtonProps) => {
    const saveConfiguration = useSaveConfiguration()
    const configurationIsSaved = useAppSelector(state => state.sales.configurationIsSaved)
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useAppDispatch()

    const focusVisualization = async () => {
        if (item.lineItemId === searchParams.get(ProjectConfigurationSearchParams.LINE_ITEM_ID)) {
            dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideVisualization: false, hideConfiguration: true }))
        } else {
            if (!configurationIsSaved) {
                await saveConfiguration()
            }

            setSearchParams({
                [ProjectConfigurationSearchParams.LINE_ITEM_ID]: item.lineItemId,
                [ProjectConfigurationSearchParams.FOCUS_VISUALIZATION]: "true"
            })
        }
    }

    return (
        <IconButton sx={TreeItemStyles.iconButton} onClick={focusVisualization}>
            <Icon component={VisibilityOutlined} />
        </IconButton>
    )
}
