import { Visualization } from "@encoway/visual-editor"
import CatalogUtils from "../../../../../../../../features/catalog/catalog.utils"
import { NumberValue } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"
import { IVisualizationNodeExtended } from "../../../../../../../../features/visualization/visualization.types"

export function getCustomContextMenuEntries(node: IVisualizationNodeExtended, visualization: Visualization, dismissMenu: () => void) {
    try {
        const customContextMenuCharacteristic = JSON.parse(CatalogUtils.getCharacteristicValue(node.props.product.product, "contextAnchorItems")!)
        return Object.entries(customContextMenuCharacteristic).map(([key, value]: [string, any]) => {
            const toggleIsOn = node.state.state[key] === true
            const itemDefinition = toggleIsOn ? value.off : value.on
            return {
                key,
                onClick: () => {
                    const nodesToSetStateFor = determineNodesToChangeState(value.selector || {}, visualization)
                    nodesToSetStateFor?.forEach(selectedNode => selectedNode.state.setState(itemDefinition.state))
                    node.state.setState({ [key]: !toggleIsOn })
                    dismissMenu()
                },
                ...(itemDefinition.icon && { iconProps: { iconName: itemDefinition.icon } }),
                ...(itemDefinition.iconOnly && { iconOnly: itemDefinition.iconOnly }),
                ...(itemDefinition.name && { name: L10n.format(itemDefinition.name) })
            }
        })
    } catch (e) {
        return []
    }
}

function determineNodesToChangeState(selectionCharacteristics: object, visualization: Visualization): IVisualizationNodeExtended[] {
    return visualization.cloud
        .graph()
        .nodes()
        .slice()
        .filter((node: IVisualizationNodeExtended) =>
            Object.entries(selectionCharacteristics).some(([key, value]) => {
                const characteristicValue = node.props.product?.product
                    ? CatalogUtils.getCharacteristicValue<NumberValue>(node.props.product.product, key)
                    : undefined
                return characteristicValue && characteristicValue.value === value
            })
        )
}

export function getToolbar(visualizationContainer: HTMLDivElement) {
    const toolbar = Array.from(visualizationContainer.getElementsByTagName("div")).find(element => element.dataset.component === "toolbar")
    return toolbar?.firstElementChild?.firstElementChild as HTMLDivElement | undefined | null
}

export function updateDoors(visualization: Visualization, hideDoors: boolean) {
    determineNodesToChangeState({ openable: 1 }, visualization)?.forEach(node => node.state.setState({ "/Visualization/HideDoors": hideDoors ? 1 : 0 }))
}
