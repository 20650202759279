import { ReactElement, ReactNode } from "react"
import { GridActionsCellItemProps, GridColDef, GridRenderCellParams, GridRowParams, GridValidRowModel, GridValueGetter } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { getContainsGridFilterOperator, getDateGridFilterOperators } from "../../../../components/dataGrid/dataGridFilterOperators"
import { dateFormatter } from "../../../../components/dataGrid/dataGridValueFormatters"
import renderEmptyHeader from "../../../../components/dataGrid/./renderer/renderEmptyHeader"
import { AbbSalesDocumentEntity } from "../../../../features/sales/sales.types"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"

const valueGetter: GridValueGetter = (value, row: GridValidRowModel, column: GridColDef) => row.properties[column.field]

const ProjectsDataGridColumns = (
    renderLockCell: (params: GridRenderCellParams<AbbSalesDocumentEntity>) => ReactNode,
    renderPermissionCell: (params: GridRenderCellParams<AbbSalesDocumentEntity>) => ReactNode,
    renderStatusCell: (params: GridRenderCellParams<AbbSalesDocumentEntity>) => ReactNode,
    getActions: (params: GridRowParams) => ReactElement<GridActionsCellItemProps>[]
): GridColDef[] => {
    const containsGridFilterOperator = getContainsGridFilterOperator()
    const dateGridFilterOperators = getDateGridFilterOperators()
    return [
        {
            field: SalesDocumentProperties.QUOTE_LOCKED_BY,
            headerName: L10n.format(TranslationKeys.project.properties.lockedBy),
            width: 40,
            filterable: false,
            sortable: false,
            renderCell: renderLockCell,
            renderHeader: renderEmptyHeader
        },
        {
            field: "permission",
            headerName: L10n.format(TranslationKeys.project.properties.permission),
            width: 40,
            type: "boolean",
            renderCell: renderPermissionCell
        },
        {
            field: SalesDocumentProperties.QUOTE_ID,
            headerName: L10n.format(TranslationKeys.project.properties.id),
            hideable: false,
            width: 120,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.DRIVESMART_ID,
            headerName: L10n.format(TranslationKeys.project.properties.driveSmart6Id),
            width: 120,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.PROJECT_NAME,
            headerName: L10n.format(TranslationKeys.project.properties.projectName),
            flex: 1,
            minWidth: 200,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER,
            headerName: L10n.format(TranslationKeys.project.properties.salesforceOpportunityNumber),
            flex: 1,
            minWidth: 80,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.CUSTOMER,
            headerName: L10n.format(TranslationKeys.project.properties.customer),
            width: 200,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.CREATED_BY,
            headerName: L10n.format(TranslationKeys.project.properties.createdBy),
            minWidth: 200,
            flex: 1,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.CREATED_AT,
            headerName: L10n.format(TranslationKeys.project.properties.createdAt),
            type: "date",
            minWidth: 120,
            valueFormatter: dateFormatter,
            filterOperators: dateGridFilterOperators,
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.MODIFIED_AT,
            headerName: L10n.format(TranslationKeys.project.properties.modifiedAt),
            type: "date",
            width: 120,
            valueFormatter: dateFormatter,
            filterOperators: dateGridFilterOperators,
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.MODIFIED_BY,
            headerName: L10n.format(TranslationKeys.project.properties.modifiedBy),
            minWidth: 200,
            flex: 1,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.QUOTE_STATUS,
            headerName: L10n.format(TranslationKeys.project.properties.status),
            width: 120,
            filterOperators: [containsGridFilterOperator],
            renderCell: renderStatusCell
        },
        {
            field: "actions",
            type: "actions",
            headerName: L10n.format(TranslationKeys.components.dataGrid.columns.actions),
            hideable: false,
            width: 0,
            renderHeader: renderEmptyHeader,
            getActions: getActions
        }
    ]
}

export default ProjectsDataGridColumns
