import Button, { ButtonProps } from "@mui/material/Button"
import usePopover from "../../../hooks/usePopover"
import Menu from "@mui/material/Menu"
import MuiMenuItem from "@mui/material/MenuItem"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import MenuButtonStyles from "./MenuButton.styles"
import { DropdownMenuItem } from "../../dropdown/Dropdown"

export interface MenuButtonProps extends Omit<ButtonProps, "children" | "onClick"> {
    text: string
    items: (DropdownMenuItem & { cypressId: string })[]
    value?: any
    onSelectItem: (value: any) => void
}

export default function MenuButton({ text, items, value, onSelectItem, ...props }: MenuButtonProps) {
    const popover = usePopover()
    return (
        <>
            <Button {...props} sx={MenuButtonStyles.button} onClick={event => popover.open(event.currentTarget)}>
                {text}
                {popover.isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
            <Menu open={popover.isOpen} anchorEl={popover.anchorEl} onClose={popover.close}>
                {items.map(item => (
                    <MuiMenuItem
                        data-cy={`MenuButton.MuiMenuItem.${item.cypressId}`}
                        key={item.text}
                        sx={MenuButtonStyles.item(value, item)}
                        onClick={() => {
                            onSelectItem(item.value)
                            popover.close()
                        }}
                    >
                        {item.text}
                    </MuiMenuItem>
                ))}
            </Menu>
        </>
    )
}
