import { TabsFooter, TabsFooterProps } from "@encoway/cui-configurator-components"
import AbbTabsFooterStyles from "./AbbTabsFooter.styles"
import { DefaultButton, IRenderFunction } from "@fluentui/react"
import { ButtonProps } from "@encoway/cui-configurator-components/src/components/TabsFooter/TabsFooter.types"
import { L10n } from "@encoway/l10n"
import AbbTabsFooterPreviousButtonStyles from "./AbbTabsFooterPreviousButton.styles"
import AbbTabsFooterNextButtonStyles from "./AbbTabsFooterNextButton.styles"

export default function AbbTabsFooter(props: Readonly<TabsFooterProps>) {
    const renderPreviousButton: IRenderFunction<ButtonProps> = props => {
        return <DefaultButton onClick={props?.onClick} text={L10n.format("Configuration.TabsFooter.Previous")} style={AbbTabsFooterPreviousButtonStyles} />
    }

    const renderNextButton: IRenderFunction<ButtonProps> = props => {
        return <DefaultButton onClick={props?.onClick} text={L10n.format("Configuration.TabsFooter.Next")} style={AbbTabsFooterNextButtonStyles} />
    }

    return <TabsFooter {...props} onRenderPreviousButton={renderPreviousButton} onRenderNextButton={renderNextButton} styles={AbbTabsFooterStyles} />
}
