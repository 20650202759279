import { Container, ContainerProps } from "@mui/material"
import { mergeSx } from "../../utils/mergeSx"

export const MainContainer = (props: ContainerProps) => {
    const sx = mergeSx({ padding: 2 }, props.sx)
    return (
        <Container component="main" maxWidth={false} {...props} sx={sx}>
            {props.children}
        </Container>
    )
}
