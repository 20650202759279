import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import BusySlice from "../features/busy/busy.slice"
import readError from "../features/error/utils/readError"
import ErrorSlice from "../features/error/error.slice"
import SnackbarSlice from "../features/snackbar/snackbar.slice"

export interface QueryFnOptions<Response, Arg, Service> {
    /**
     * Selects service from redux store.
     * @param state root state of redux store
     */
    serviceSelector: (state: any) => Service

    /**
     * Query function.
     * @param arg query args
     * @param service returned by serviceSelector
     * @param api {@link BaseQueryApi}
     */
    query: (arg: Arg, service: Service, api: BaseQueryApi) => Promise<Response>

    /**
     * If is false, spinner is disabled.
     * If is function, return value is used as spinner text.
     * @default true
     */
    spinner?: boolean | ((arg: Arg) => string)

    /**
     * Defines how to handle query error.
     * @default "dialog"
     */
    errorHandling?: "dialog" | "snackbar" | "none"
}

export function queryFn<Response, Arg, Service>({ serviceSelector, query, spinner = true, errorHandling = "dialog" }: QueryFnOptions<Response, Arg, Service>) {
    return async (arg: Arg, api: BaseQueryApi) => {
        const showSpinner = spinner !== false
        const spinnerText = typeof spinner === "function" ? spinner(arg) : undefined
        if (showSpinner) {
            api.dispatch(BusySlice.actions.setBusy(spinnerText))
        }
        try {
            const service = serviceSelector(api.getState() as any)
            const result = await query(arg, service, api)
            return { data: result }
        } catch (e) {
            const error = await readError(e)
            if (errorHandling === "dialog") {
                api.dispatch(ErrorSlice.actions.set(error))
            } else if (errorHandling === "snackbar") {
                api.dispatch(SnackbarSlice.actions.open({ message: error.message, severity: "error" }))
            }
            return { error }
        } finally {
            if (showSpinner) {
                api.dispatch(BusySlice.actions.setIdle(spinnerText))
            }
        }
    }
}
