import { useCallback } from "react"
import { ConfigurationService } from "@encoway/c-services-js-client"
import DownloadFileService from "../../../../features/download/DownloadFileService"

export const useDownload = (configurationService: ConfigurationService) => {
    const download = useCallback(async () => {
        const savedConfiguration = await configurationService.save()
        DownloadFileService.downloadFile(savedConfiguration, "configuration.config")
    }, [configurationService])
    return { download: download }
}
