import theme from "../../../../App.theme"

const HeaderStyles = {
    AppBar: {
        boxShadow: "none",
        borderWidth: "0px 0 1px 0",
        borderStyle: "solid",
        borderColor: theme.palette.divider
    }
}

export default HeaderStyles
