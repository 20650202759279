export const ProjectConfigurationContainerIds = {
    PROJECT_CONFIGURATION_CONTAINER: "project_configuration_container",
    CONFIGURATION_TREE: "configuration_tree_container",
    VISUALIZATION: "visualization_container",
    CONFIGURATION: "configuration_container"
} as const

export const ProjectConfigurationSearchParams = {
    LINE_ITEM_ID: "lineItemId",
    VIEW_ID: "viewId",
    FOCUS_VISUALIZATION: "focusVisualization"
} as const
