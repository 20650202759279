import { Settings } from "../../settings"
import toFormattedString from "../../utils/toFormattedString"
import { GridValidRowModel, GridValueFormatter } from "@mui/x-data-grid-pro"
import { BomNode } from "../../pages/projectPage/components/projectComposition/lineItemsDataGrid/LineItemsDataGrid"

export const dateFormatter: GridValueFormatter<GridValidRowModel, any, any, string> = value => {
    return value ? new Date(value).toLocaleDateString(Settings.locale) : ""
}

export const numberFormatter: GridValueFormatter<GridValidRowModel, any, any, number> = value => toFormattedString(value)

export const priceFormatter: GridValueFormatter<BomNode, any, any, number> = (value, row) => {
    const properties = row.lineItem.properties
    if (value) {
        return toFormattedPriceString(value)
    } else if (properties.hasPrice) {
        return toFormattedPriceString(0)
    } else {
        return ""
    }
}

export const multiplierFormatter: GridValueFormatter<GridValidRowModel, any, any, number> = value => {
    return value ? toFormattedString(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""
}

export const percentageFormatter: GridValueFormatter<GridValidRowModel, any, any, number | string> = value => {
    if (typeof value === "number") {
        const valueToFormat = value ?? 0
        return `${toFormattedString(valueToFormat)} %`
    } else {
        return value
    }
}

const toFormattedPriceString = (value?: number) => {
    return toFormattedString(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}
