import React, { useEffect } from "react"
import { RootProps } from "@encoway/cui-configurator-components"
import { ComponentFactory } from "@encoway/react-configurator"
import { ComponentName } from "@encoway/cui-configurator-components/src/constants"
import AbbRootStyles from "./AbbRoot.styles"
import { Portal } from "@mui/material"
import { ProjectConfigurationContainerIds } from "../../../../ProjectConfiguration.constants"
import { getFilteredData } from "./AbbRoot.utils"

export default function AbbRoot(props: Readonly<RootProps>) {
    const configurationContainer = document.getElementById(ProjectConfigurationContainerIds.CONFIGURATION)

    useEffect(() => {
        const mutationObserver = new MutationObserver(() => configurationContainer?.scrollTo({ top: 0, left: 0 }))
        mutationObserver.observe(configurationContainer!, { childList: true, subtree: false, attributes: false })
        return () => mutationObserver.disconnect()
    }, [configurationContainer])
    const filteredData = getFilteredData(props.data)
    const propsWithFilteredData = { ...props, data: filteredData }
    return (
        <Portal container={() => configurationContainer}>
            {props.data?.parameters?.length > 0 ? (
                <div style={AbbRootStyles}>{ComponentFactory.instanceOf(ComponentName.Section, propsWithFilteredData)}</div>
            ) : (
                ComponentFactory.instanceOf(ComponentName.Tabs, propsWithFilteredData)
            )}
        </Portal>
    )
}
