import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps as MuiBreadcrumbsProps, Link, Tooltip, Typography } from "@mui/material"
import BreadcrumbsStyles from "./Breadcrumbs.styles"
import { NavLink } from "react-router-dom"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

export interface Breadcrumb {
    label: string
    route?: string
}

interface BreadcrumbsProps extends Omit<MuiBreadcrumbsProps, "children"> {
    breadcrumbs: Breadcrumb[]
}

export default function Breadcrumbs({ breadcrumbs, ...props }: Readonly<BreadcrumbsProps>) {
    return (
        <MuiBreadcrumbs separator={<ChevronRightIcon fontSize="small" />} {...props}>
            {breadcrumbs.map((breadcrumb, index) => (
                <Tooltip key={breadcrumb.label} title={breadcrumb.label}>
                    {index < breadcrumbs.length - 1 ? (
                        <Link data-cy="Breadcrumb.Link" component={NavLink} to={breadcrumb.route ?? ""} sx={BreadcrumbsStyles.link}>
                            {breadcrumb.label}
                        </Link>
                    ) : (
                        <Typography data-cy="Breadcrumb.Typography" sx={BreadcrumbsStyles.typography}>
                            {breadcrumb.label}
                        </Typography>
                    )}
                </Tooltip>
            ))}
        </MuiBreadcrumbs>
    )
}
