import { Card, Stack } from "@mui/material"
import { L10n } from "@encoway/l10n"
import { ProductGroup } from "@encoway/c-services-js-client"
import { ProductGroupCardStyles } from "./ProductGroupCard.styles"
import useTextBox from "../../../../../components/textBox/useTextBox"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { NewTabLink } from "../../../../../components/links/newTabLink/NewTabLink"
import { getProductGroupData } from "./ProductGroupCard.utils"
import { ProductGroupCardTextBox } from "./ProductGroupCard.TextBox"
import { ProductGroupCardImage } from "./ProductGroupCard.Image"
import { ProductGroupCardButton } from "./ProductGroupCard.Button"
import { ProductGroupCardTitle } from "./ProductGroupCard.Title"

export enum ProductGroupCardSize {
    Default,
    Big
}

export interface ProductGroupCardProps {
    productGroup?: ProductGroup
    onSelect?: (productGroup: ProductGroup) => void
    size?: ProductGroupCardSize
    disabled?: boolean
}

export default function ProductGroupCard({ productGroup, onSelect, size = ProductGroupCardSize.Default, disabled = false }: ProductGroupCardProps) {
    const textBox = useTextBox()
    const data = productGroup ? getProductGroupData(productGroup) : undefined
    return size !== ProductGroupCardSize.Big ? (
        <Card
            component={Stack}
            direction="row"
            spacing={2}
            sx={ProductGroupCardStyles.card(disabled, size, textBox.isExpanded)}
            data-cy={`ProductGroupCard.${data?.id}`}
        >
            <Stack spacing={1} alignItems="center">
                <ProductGroupCardImage data={data} disabled={disabled} size={size} />
                <NewTabLink href={data?.catalogLink} text={L10n.format(TranslationKeys.pages.project.catalog.link)} disabled={!data?.catalogLink || disabled} />
            </Stack>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <ProductGroupCardTitle data={data} disabled={disabled} />
                <ProductGroupCardTextBox data={data} textBox={textBox} disabled={disabled} />
                <ProductGroupCardButton data={data} productGroup={productGroup} size={size} onSelect={onSelect} disabled={disabled} />
            </Stack>
        </Card>
    ) : (
        <Card component={Stack} spacing={1} sx={ProductGroupCardStyles.card(disabled, size, textBox.isExpanded)} data-cy={`ProductGroupCard.${data?.id}`}>
            <ProductGroupCardImage data={data} disabled={disabled} size={size} />
            <ProductGroupCardTitle data={data} disabled={disabled} />
            <ProductGroupCardTextBox data={data} textBox={textBox} disabled={disabled} />
            <NewTabLink href={data?.catalogLink} text={L10n.format(TranslationKeys.pages.project.catalog.link)} disabled={!data?.catalogLink || disabled} />
            <ProductGroupCardButton data={data} productGroup={productGroup} size={size} onSelect={onSelect} disabled={disabled} />
        </Card>
    )
}
