import { Stack } from "@mui/material"
import { LayoutIconEntryStyles } from "./LayoutIconEntry.styles"

type LayoutIconProps = {
    icon: any
    label: string
}

export const LayoutIconEntry = ({ icon, label }: LayoutIconProps) => {
    return (
        <Stack direction="row">
            <img src={icon} alt={label} style={LayoutIconEntryStyles.image} />
            {label}
        </Stack>
    )
}
