import theme from "../../../../App.theme"
import { TabsStyles } from "../../../../components/tabs/Tabs"

const ProductSelectionStyles = {
    warning: {
        backgroundColor: "transparent",
        color: theme.palette.text.secondary,
        padding: 0,
        maxWidth: 400
    },
    tabStyles: {
        tab: (tab: number, selectedTab: number) => ({
            minWidth: 0,
            px: 0,
            marginRight: 2,
            color: tab === selectedTab ? theme.palette.text.primary + " !important" : undefined
        }),
        tabs: {
            flexShrink: 0
        },
        tabLabel: (tab: number, selectedTab: number) => ({
            fontSize: 16,
            fontWeight: tab === selectedTab ? theme.typography.fontWeightBold?.toString() || "inherit" : "inherit",
            textTransform: "none",
            "& .MuiSvgIcon-root": {
                fontSize: 18
            }
        })
    } as TabsStyles
}

export default ProductSelectionStyles
