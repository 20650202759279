import theme from "../../App.theme"

const CheckBoxGroupStyles = {
    formControlLabel: {
        alignItems: "start",
        margin: 0,
        ".MuiCheckbox-root": {
            marginRight: 1,
            padding: 0
        }
    },
    optionCaption: {
        color: theme.palette.text.secondary
    }
}

export default CheckBoxGroupStyles
