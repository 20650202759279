import { Box, Stack, Typography } from "@mui/material"
import ProductInformationStyles from "./ProductInformation.styles"
import Logo from "../../../../../../../../../assets/logo.svg"
import Image from "../../../../../../../../../components/image/Image"
import { CatalogApi } from "../../../../../../../../../features/catalog/catalog.api"
import CatalogUtils from "../../../../../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../../../../../features/catalog/catalog.constants"
import AbbSpinner from "../../../../../../projectConfiguration/components/cui/components/AbbSpinner/AbbSpinner"

interface ProductInformationProps {
    productId: string
}

export default function ProductInformation({ productId }: ProductInformationProps) {
    const product = CatalogApi.useProductQuery(productId).data?.product
    const shortText = product ? CatalogUtils.getCharacteristicValue<string>(product, Characteristics.ShortText.id) : ""
    const longText = product ? CatalogUtils.getCharacteristicValue<string>(product, Characteristics.LongText.id) : ""
    return product ? (
        <Stack spacing={1} sx={ProductInformationStyles.stack}>
            <Image
                src={CatalogUtils.getMediaUri(product, Characteristics.ProductImage.id)}
                style={ProductInformationStyles.image}
                fallbackImageProps={{ src: Logo, alt: "image of " + product.name }}
            />
            <Typography sx={ProductInformationStyles.title}>{product.name}</Typography>
            {shortText ? <Typography>{shortText}</Typography> : null}
            {longText ? <Box dangerouslySetInnerHTML={{ __html: longText }} /> : null}
        </Stack>
    ) : (
        <AbbSpinner />
    )
}
