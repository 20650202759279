import { MouseEvent } from "react"
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material"
import Icon from "../../icons/Icon"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"

interface ExpandButtonProps extends Omit<IconButtonProps, "onClick" | "component"> {
    expanded: boolean
    onToggle: (event: MouseEvent<HTMLButtonElement>) => void
}

export default function ExpandButton({ expanded, onToggle, ...props }: ExpandButtonProps) {
    return (
        <IconButton size="small" onClick={onToggle} {...props}>
            <Icon component={expanded ? KeyboardArrowDown : KeyboardArrowRight} />
        </IconButton>
    )
}
