import { L10n } from "@encoway/l10n"
import { Typography } from "@mui/material"
import Dialog, { DialogProps } from "../../../../components/dialog/Dialog"
import { ButtonPropsWithLabel } from "../../../../components/dialog/dialogActions/DialogActions"
import TranslationKeys from "../../../translations/TranslationKeys"
import DialogStyles from "../../../../components/dialog/Dialog.styles"
import theme from "../../../../App.theme"

interface LockedSalesDocumentDialogProps extends Pick<DialogProps, "open"> {
    projectCanBeOpened: boolean
    lockedBy?: string
    onOpenLockedProject: (readOnly?: boolean) => void
    onCancelOpen: () => void
}

export default function LockedSalesDocumentDialog({
    open,
    projectCanBeOpened,
    lockedBy,
    onOpenLockedProject,
    onCancelOpen
}: Readonly<LockedSalesDocumentDialogProps>) {
    const stealLockButton: ButtonPropsWithLabel = {
        label: TranslationKeys.project.lockedDialog.submitButtonLabel,
        variant: "contained",
        onClick: () => onOpenLockedProject(),
        sx: DialogStyles.blackButton
    }

    const readButton: ButtonPropsWithLabel = {
        label: TranslationKeys.project.actions.read,
        variant: "contained",
        onClick: () => onOpenLockedProject(true),
        sx: DialogStyles.grayButton
    }

    return (
        <Dialog
            open={open}
            onClose={onCancelOpen}
            title={L10n.format(TranslationKeys.project.lockedDialog.title)}
            message={L10n.format(TranslationKeys.project.lockedDialog.message, { user: lockedBy })}
            customDialogActions={projectCanBeOpened ? { actions: [readButton, stealLockButton] } : { actions: [readButton] }}
        >
            {projectCanBeOpened ? (
                <Typography color={theme.palette.warning.main}>{L10n.format(TranslationKeys.project.lockedDialog.unlockWarning)}</Typography>
            ) : null}
        </Dialog>
    )
}
