import Box from "@mui/material/Box"
import LogoImage from "../../../../../../assets/logo.svg"
import AppLogoStyles from "./AppLogo.styles"
import { SxProps } from "@mui/system"

interface AppLogoProps {
    sx?: SxProps
}

export default function AppLogo({ sx }: AppLogoProps) {
    const styles: SxProps = sx ? { ...AppLogoStyles.logo, ...sx } : AppLogoStyles.logo
    return <Box sx={styles} component="img" alt="Company Logo" src={LogoImage} />
}
