import { AbbLineItem } from "../../../../../../../../features/sales/sales.types"
import { ProjectConfiguration } from "../../../../../../../../features/sales/sales.api.types"
import { getAllConfigurableChildren } from "../../../../ProjectConfiguration.utils"
import TreeUtils from "../../../../../../../../utils/TreeUtils"
import SalesUtils from "../../../../../../../../features/sales/sales.utils"
import { GridColDef, GridValueGetter } from "@mui/x-data-grid-pro"

export function getRowId(lineItem: AbbLineItem) {
    return lineItem.lineItemId
}
export function getRows(lineItem: AbbLineItem, projectConfiguration: ProjectConfiguration) {
    const unitOptionsLineItemIds = SalesUtils.lineItems.getConfigurationInterfaceTargetLineItemIds(lineItem)
    if (unitOptionsLineItemIds) {
        return TreeUtils.findAllRecursively(
            projectConfiguration.lineItems,
            item => item.children,
            item => unitOptionsLineItemIds.includes(item.lineItem.lineItemId)
        ).map(child => child.lineItem)
    } else {
        const children = getAllConfigurableChildren(lineItem.lineItemId, projectConfiguration)
        return children.filter(child => child.lineItem.properties.ARTICLE_TYPE !== "UNIT_OPTIONS").map(child => child.lineItem)
    }
}

export default function setLineItemCellValue(value: any, row: AbbLineItem, field: string): AbbLineItem {
    row.properties[field] = value
    return row
}

export const getLineItemCellValue: GridValueGetter<AbbLineItem> = (value: never, row: AbbLineItem, colDef: GridColDef) => {
    return row.properties[colDef.field]
}
