import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Product } from "@encoway/c-services-js-client"
import ProductInformation from "./productInformation/ProductInformation"
import InfoIcon from "../../../../../../../../components/icons/infoIcon/InfoIcon"

export function ProductInformationCell(params: Readonly<GridRenderCellParams<Product>>) {
    return (
        <InfoIcon>
            <ProductInformation productId={params.row.id} />
        </InfoIcon>
    )
}
