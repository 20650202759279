export const CustomConfiguratorComponents = {
    FilterDetailDropdown: "filterdetaildropdown",
    Graph: "Graph",
    MatlabButton: "matlabButton",
    MatlabWarning: "matlabWarning",
    MultilineDisplay: "multilineDisplay",
    Table: "Table",
    Notification: "notification",
    CheckResult: "checkResult",
    Dimensioning: "dimensioning",
    DimensioningMv: "dimensioningMv",
    ValueCheckbox: "valueCheckbox",
    DefaultLayout: "defaultLayout",
    UnitOverviewTable: "unitOverviewTable"
} as const
