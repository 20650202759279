import theme from "../../../../../../../App.theme"

const TypeCodeCellStyles = {
    typecode: {
        container: {
            cursor: "pointer",
            justifyContent: "space-between"
        },
        text: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: "4px"
        },
        box: {
            padding: 1,
            backgroundColor: theme.palette.background.default,
            borderRadius: 1,
            borderWidth: 2,
            borderColor: theme.palette.divider,
            borderStyle: "solid"
        },
        button: {
            flexShrink: 0
        }
    }
}

export default TypeCodeCellStyles
