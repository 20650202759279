import { BorderLayoutContent, IBorderStyles } from "@encoway/cui-application-components"
import { BorderLayoutDefinition } from "@encoway/cui-application-components/src/components/CUIApp/CUIApp.types"

export const CUI_LAYOUT: BorderLayoutDefinition = [
    { name: BorderLayoutContent.VisualEditor, position: "west" },
    { name: BorderLayoutContent.Configuration, position: "center" }
]

export const CUI_BORDER_STYLES: IBorderStyles = {
    west: { flex: "0 1 0", width: 0 },
    center: { flex: "1 1 100%", width: "100%" }
}
