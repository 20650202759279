import { FormControl, FormControlProps, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import theme from "../../App.theme"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../features/translations/TranslationKeys"
import InfoIcon from "../icons/infoIcon/InfoIcon"
import compareStrings from "../../utils/compareStrings"

export interface DropdownMenuItem {
    value: any
    text: string
}

interface DropdownProps extends Omit<FormControlProps, "onChange"> {
    label: string
    value?: string | string[]
    menuItems: DropdownMenuItem[]
    onChange: SelectProps<any>["onChange"]
    infoText?: string
    unselect?: boolean
    cypressId: string
    placeholder?: string
    multiple?: boolean
    hideLabel?: boolean
    disableSorting?: boolean
}

export default function Dropdown({
    label,
    value,
    menuItems,
    onChange,
    infoText,
    unselect,
    cypressId,
    placeholder,
    multiple,
    hideLabel,
    disableSorting,
    ...props
}: Readonly<DropdownProps>) {
    const labelId = label.replace(" ", "_") + "_dropdown_label"
    const options = disableSorting
        ? menuItems
        : menuItems.map(item => ({ value: item.value, text: item.text })).sort((menuItem1, menuItem2) => compareStrings(menuItem1.text, menuItem2.text, false))
    return (
        <FormControl data-cy={`Dropdown.${cypressId}`} {...props}>
            <InputLabel data-cy={`Dropdown.InputLabel`} id={labelId} sx={{ display: hideLabel ? "none !important" : undefined }}>
                <span>{label}</span>
                {infoText && <InfoIcon>{infoText}</InfoIcon>}
            </InputLabel>
            <Select
                data-cy="Dropdown.Select"
                displayEmpty
                labelId={labelId}
                onChange={onChange}
                label={label}
                value={value ?? (multiple ? [] : "")}
                IconComponent={ExpandMore}
                multiple={multiple}
                renderValue={
                    !value || value.length === 0
                        ? () => (
                              <span style={{ color: theme.palette.text.disabled }}>
                                  {placeholder ?? L10n.format(TranslationKeys.components.dropdown.placeholder)}
                              </span>
                          )
                        : undefined
                }
            >
                {unselect && (
                    <MenuItem key={L10n.format(TranslationKeys.components.dropdown.unselectOption)} value="">
                        {L10n.format(TranslationKeys.components.dropdown.unselectOption)}
                    </MenuItem>
                )}
                {options.map(item => (
                    <MenuItem data-cy={`Dropdown.MenuItem.${item.value}`} key={item.text} value={item.value}>
                        {item.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
