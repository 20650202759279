import { useCallback, useState } from "react"

export default function useDialog() {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const open = useCallback(() => setIsOpen(true), [])
    const close = useCallback(() => setIsOpen(false), [])

    return {
        isOpen,
        open: open,
        close: close
    }
}
