import theme from "../../../../App.theme"

const ProductFiltersStyles = {
    gridItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        minWidth: 332
    },
    button: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.text.primary,
        height: 40
    }
}

export default ProductFiltersStyles
