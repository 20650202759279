import theme from "../../../../../App.theme"

const ProjectFormStyles = {
    header: {
        backgroundColor: theme.palette.divider,
        borderBottom: "1px solid " + theme.palette.text.secondary,
        alignItems: "center",
        padding: 2
    },
    contentContainer: {
        backgroundColor: theme.palette.background.default,
        padding: 3
    }
}

export default ProjectFormStyles
