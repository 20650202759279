import theme from "../../../../App.theme"

const DashboardWidgetStyles = {
    title: {
        fontSize: 22,
        fontWeight: "bold",
        color: theme.palette.text.secondary
    }
}

export default DashboardWidgetStyles
