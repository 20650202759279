import { useState } from "react"

export default function usePopover() {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | SVGElement | null>(null)
    const [timer, setTimer] = useState<NodeJS.Timeout>()

    const open = (anchorElement: HTMLElement | SVGElement, delay?: number) => {
        setTimer(setTimeout(() => setAnchorEl(anchorElement), delay))
    }

    const close = () => {
        setAnchorEl(null)
        if (timer) {
            clearTimeout(timer)
        }
    }

    return {
        anchorEl,
        open,
        isOpen: Boolean(anchorEl),
        close
    }
}
