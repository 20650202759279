import { Alert, IconButton } from "@mui/material"
import { CatalogApi } from "../../../../../features/catalog/catalog.api"
import { Characteristics as AbbCharacteristics } from "../../../../../features/catalog/catalog.constants"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { useAppSelector } from "../../../../../store/store"
import theme from "../../../../../App.theme"
import { announcementSelection } from "./Announcement.constant"
import { AnnouncementStyles } from "./Announcement.styles"
import Box from "@mui/material/Box"

export function Announcement() {
    const [closedAlerts, setClosedAlerts] = useState<string[]>([])
    const catalogService = useAppSelector(state => state.catalog.catalogService)

    const foundAnnouncements = CatalogApi.useProductsQuery(announcementSelection, { skip: !catalogService }).data?.products || []

    const announcements = foundAnnouncements
        .filter(announcement => !closedAlerts.includes(announcement.id))
        .filter(announcement => CatalogUtils.getCharacteristicValue<string>(announcement, AbbCharacteristics.LongText.id))
        .map(announcement => (
            <Alert
                key={announcement.id}
                severity="info"
                style={{
                    backgroundColor: CatalogUtils.getCharacteristicValue<string>(announcement, AbbCharacteristics.AlertColor.id) ?? theme.palette.warning.main
                }}
                action={
                    <IconButton size="small" color="inherit" onClick={() => setClosedAlerts([...closedAlerts, announcement.id])}>
                        <CloseIcon />
                    </IconButton>
                }
            >
                <Box
                    sx={AnnouncementStyles}
                    dangerouslySetInnerHTML={{ __html: CatalogUtils.getCharacteristicValue<string>(announcement, AbbCharacteristics.LongText.id) ?? "" }}
                ></Box>
            </Alert>
        ))
    return <div>{announcements}</div>
}
