import { BlockerFunction, useBlocker as routerUseBlocker, useBeforeUnload } from "react-router-dom"

export type ShouldBlock = boolean | BlockerFunction

export default function useBlocker(shouldBlock: ShouldBlock, shouldBlockUnload: boolean) {
    useBeforeUnload(event => {
        if (shouldBlockUnload) {
            event.preventDefault()
            event.returnValue = ""
            return event.returnValue
        }
    })

    return routerUseBlocker(shouldBlock)
}
