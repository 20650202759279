import { IconButton, Menu, MenuItem } from "@mui/material"
import { TreeItemStyles } from "./TreeItem.styles"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import { MoreVert, PermDataSetting, Settings } from "@mui/icons-material"
import usePopover from "../../../../../../hooks/usePopover"
import React, { MouseEvent, useState } from "react"
import { ProjectConfigurationLineItemWithParent } from "./TreeItem"
import SalesApi from "../../../../../../features/sales/sales.api"
import DeleteIcon from "@mui/icons-material/Delete"
import { useDeleteLineItemsHandler } from "../../../../../../features/sales/hooks/useDeleteLineItemsHandler"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { RenameDialog } from "../renameDialog/RenameDialog"
import SalesUtils from "../../../../../../features/sales/sales.utils"
import { useAppSelector } from "../../../../../../store/store"
import { ProjectConfigurationSearchParams } from "../../ProjectConfiguration.constants"
import { useSaveConfiguration } from "../../hooks/useSaveConfiguration"
import { useSearchParams } from "react-router-dom"
import { ViewIds } from "../../../../../../features/catalog/catalog.constants"
import { useUserInformation } from "../../../../../../features/oidc/hooks/useUserInformation"
import { OidcUtils } from "../../../../../../features/oidc/oidc.utils"

type LineItemActionsMenuButtonProps = {
    item: ProjectConfigurationLineItemWithParent
}

export const LineItemActionsMenuButton = ({ item }: LineItemActionsMenuButtonProps) => {
    const popover = usePopover()
    const userInformation = useUserInformation()
    const [duplicateLineItems] = SalesApi.useDuplicateLineItemsMutation()
    const deleteLineItemsHandler = useDeleteLineItemsHandler()
    const [renameDialogOpen, setRenameDialogOpen] = useState(false)
    const isProjectOptions = SalesUtils.lineItems.isProjectOptions(item.lineItem)
    const isConfigurable = SalesUtils.lineItems.isConfigurable(item.lineItem)
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const configurationIsSaved = useAppSelector(state => state.sales.configurationIsSaved)
    const saveConfiguration = useSaveConfiguration()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchParamViewId = searchParams.get(ProjectConfigurationSearchParams.VIEW_ID)
    const lineItemId = searchParams.get(ProjectConfigurationSearchParams.LINE_ITEM_ID)

    const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        popover.open(event.currentTarget)
    }

    const onMfkView = async () => {
        if (item.lineItem.lineItemId && !configurationIsSaved) {
            await saveConfiguration()
        }
        setSearchParams(
            item.lineItem.lineItemId
                ? { [ProjectConfigurationSearchParams.LINE_ITEM_ID]: item.lineItem.lineItemId, [ProjectConfigurationSearchParams.VIEW_ID]: ViewIds.MFK_VIEW }
                : undefined
        )
    }

    const onOpenConfigurationView = async () => {
        if (item.lineItem.lineItemId && !configurationIsSaved) {
            await saveConfiguration()
        }
        setSearchParams(item.lineItem.lineItemId ? { [ProjectConfigurationSearchParams.LINE_ITEM_ID]: item.lineItem.lineItemId } : undefined)
    }

    const onDuplicate = () => {
        duplicateLineItems({
            selectedLineItemIds: [item.lineItem.lineItemId],
            parentLineItemId: item.parent?.lineItem?.lineItemId,
            previousLineItem: "LAST"
        })
        popover.close()
    }
    const onRename = () => {
        setRenameDialogOpen(true)
        popover.close()
    }
    const onDelete = async () => {
        deleteLineItemsHandler.askToDeleteLineItems([item.lineItem.lineItemId])
        popover.close()
    }
    return (
        <>
            <IconButton sx={TreeItemStyles.iconButton} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu
                open={popover.isOpen}
                anchorEl={popover.anchorEl}
                onClose={popover.close}
                onClick={popover.close}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                sx={TreeItemStyles.menu}
            >
                {!isProjectOptions && !readOnly && item.lineItem.permission.includes("UPDATE") && (
                    <MenuItem onClick={onDuplicate}>
                        <ContentCopyIcon />
                        {L10n.format(TranslationKeys.lineItem.actions.duplicate)}
                    </MenuItem>
                )}
                {isConfigurable && OidcUtils.hasReadMfkRole(userInformation) && (!searchParamViewId || lineItemId !== item.lineItem.lineItemId) && (
                    <MenuItem onClick={onMfkView}>
                        <PermDataSetting />
                        {L10n.format(TranslationKeys.lineItem.actions.mfkView)}
                    </MenuItem>
                )}
                {isConfigurable && searchParamViewId && lineItemId === item.lineItem.lineItemId && (
                    <MenuItem onClick={onOpenConfigurationView}>
                        <Settings />
                        {L10n.format(TranslationKeys.lineItem.actions.openConfigurationView)}
                    </MenuItem>
                )}
                {!readOnly && item.lineItem.permission.includes("UPDATE") && (
                    <MenuItem onClick={onRename}>
                        <ModeEditIcon />
                        {L10n.format(TranslationKeys.lineItem.actions.rename)}
                    </MenuItem>
                )}
                {!isProjectOptions && !readOnly && item.lineItem.permission.includes("DELETE") && (
                    <MenuItem onClick={onDelete}>
                        <DeleteIcon />
                        {L10n.format(TranslationKeys.lineItem.actions.delete)}
                    </MenuItem>
                )}
            </Menu>
            {renameDialogOpen ? <RenameDialog item={item} onClose={() => setRenameDialogOpen(false)} /> : null}
        </>
    )
}
