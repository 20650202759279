import SalesApi from "../sales.api"
import { UpdateLineItemArgs } from "../sales.api.types"
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/react"
import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { UpdateServerResponse } from "@encoway/sales-api-js-client"
import TreeUtils from "../../../utils/TreeUtils"

type UpdateLineItemQueryUpdater = (
    arg: UpdateLineItemArgs,
    api: MutationLifecycleApi<UpdateLineItemArgs, BaseQueryFn<void, symbol, Error, {}, {}>, UpdateServerResponse, "salesApi">
) => Promise<void>

export const updateLineItemQueryUpdater: UpdateLineItemQueryUpdater = async ([lineItemId, properties], api) => {
    api.dispatch(
        SalesApi.util.updateQueryData("composition", undefined, data => {
            Object.values(data).forEach(lineItems => {
                const lineItem = lineItems.find(item => item.lineItemId === lineItemId)
                if (lineItem) {
                    Object.entries(properties).forEach(([property, value]) => {
                        lineItem.properties[property] = value
                    })
                }
            })
        })
    )
    api.dispatch(
        SalesApi.util.updateQueryData("projectConfiguration", undefined, data => {
            const item = TreeUtils.findRecursively(
                data.lineItems,
                item => item.children,
                item => item.lineItem.lineItemId === lineItemId
            )
            if (item) {
                Object.entries(properties).forEach(([property, value]) => {
                    item.lineItem.properties[property] = value
                })
            }
        })
    )
}
