import { useMemo } from "react"
import { createSearchParams, generatePath, useNavigate as useRouterNavigate } from "react-router-dom"
import RouterPaths from "../constants/RouterPaths"

import { ProductGroupIds } from "../../features/catalog/catalog.constants"

export default function useNavigate() {
    const navigate = useRouterNavigate()
    return useMemo(
        () => ({
            toDashboard: () => navigate(generatePath(RouterPaths.DASHBOARD)),
            toProjectsOverview: () => navigate(generatePath(RouterPaths.PROJECTS)),
            toProjectDetails: (salesDocumentId: string) => navigate(generatePath(RouterPaths.PROJECT_DETAILS, { salesDocumentId })),
            toProjectConfiguration: (salesDocumentId: string, lineItemId?: string) => {
                const pathname = generatePath(RouterPaths.PROJECT_CONFIGURATION, { salesDocumentId })
                navigate(lineItemId ? { pathname, search: createSearchParams({ lineItemId }).toString() } : pathname)
            },
            toProjectComposition: (salesDocumentId: string) => navigate(generatePath(RouterPaths.PROJECT_COMPOSITION, { salesDocumentId })),
            toProjectReports: (salesDocumentId: string) => navigate(generatePath(RouterPaths.PROJECT_REPORTS, { salesDocumentId })),
            toCatalog: (salesDocumentId: string) =>
                navigate(generatePath(RouterPaths.PROJECT_CATALOG, { salesDocumentId, productGroupIds: ProductGroupIds.Catalog })),
            toProductGroup: (salesDocumentId: string, productGroupIds: string[]) =>
                navigate(generatePath(RouterPaths.PROJECT_CATALOG, { salesDocumentId, productGroupIds: productGroupIds.join("+") })),
            back: () => navigate(-1),
            withSearchParams: (route: string, searchParams: Record<string, string>) => navigate(`${route}?${convertSearchParams(searchParams)}`)
        }),
        [navigate]
    )
}

function convertSearchParams(searchParams: Record<string, string>) {
    return Object.entries(searchParams).reduce((result: string, [key, value]) => (!result ? `${key}=${value}` : `${result}&${key}=${value}`), "")
}
