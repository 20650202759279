import Button, { ButtonProps } from "@mui/material/Button"
import MenuButton, { MenuButtonProps } from "../menuButton/MenuButton"
import useBlocker, { ShouldBlock } from "../../../router/hooks/useBlocker"
import { useLayoutEffect, useState } from "react"
import SaveButtonDialog from "./SaveButtonDialog"
import NavigateOptions from "../../../router/constants/NavigateOptions"

interface SaveButtonProps extends Omit<ButtonProps, "children" | "onClick"> {
    label: string
    onClick: (navigateOption: NavigateOptions) => any
    shouldBlockNavigation: ShouldBlock
    shouldBlockUnload: boolean
    menuItems?: MenuButtonProps["items"]
}

export default function SaveButton({ label, onClick, shouldBlockNavigation, shouldBlockUnload, menuItems, ...props }: SaveButtonProps) {
    const [saved, setSaved] = useState(false)
    const blocker = useBlocker(shouldBlockNavigation, shouldBlockUnload)

    useLayoutEffect(() => {
        if (saved && blocker.proceed) {
            setSaved(false)
            blocker.proceed()
        }
    }, [blocker, saved])

    const save = async (navigateOption: NavigateOptions) => {
        await onClick(navigateOption)
        setSaved(true)
    }

    return (
        <>
            {!menuItems ? (
                <Button data-cy="SaveButton" variant="contained" onClick={() => save(NavigateOptions.DontNavigate)} {...props}>
                    {label}
                </Button>
            ) : (
                <MenuButton
                    data-cy="SaveButton"
                    text={label}
                    onSelectItem={navigateOption => save(navigateOption)}
                    variant="contained"
                    {...props}
                    style={{ ...props.style }}
                    items={menuItems}
                />
            )}

            <SaveButtonDialog
                open={blocker.state === "blocked"}
                onSave={() => save(NavigateOptions.DontNavigate)}
                onConfirm={blocker.proceed!}
                onCancel={blocker.reset!}
            />
        </>
    )
}
