import { CharacteristicValueInfo } from "@encoway/c-services-js-client/src/catalog/types/response/characteristic/CharacteristicValueInfo"
import { Characteristic, CharacteristicValue } from "@encoway/sales-api-js-client"

/**
 * Merges aggregated characteristic values with filter characteristics.
 * @param aggregatedCharacteristicValues The object containing information about the aggregated possible values.
 * @param filterCharacteristics contains all filters that should be shown.
 */
export default function mergeFilters(aggregatedCharacteristicValues: Record<string, CharacteristicValueInfo[]>, filterCharacteristics: Characteristic[] = []) {
    return filterCharacteristics
        .map(filter => ({ ...filter, possibleValues: mergePossibleValues(filter.possibleValues, aggregatedCharacteristicValues[filter.characteristicId]) }))
        .filter(filterCharacteristic => filterCharacteristic.possibleValues.length > 0)
}

/**
 * Merges characteristic value info and possible values.
 * @param possibleValues The possible values of the default characteristics.
 * @param characteristicValueInfos The characteristic values of the aggregated characteristics.
 */
function mergePossibleValues(possibleValues: CharacteristicValue[], characteristicValueInfos?: CharacteristicValueInfo[]) {
    return (
        characteristicValueInfos?.reduce((result: CharacteristicValue[], characteristicValueInfo) => {
            const matchingCharacteristicValue = possibleValues.find(possibleValue => characteristicValueInfo.value === possibleValue.value)
            return !matchingCharacteristicValue ? result : result.concat([{ ...matchingCharacteristicValue, value: characteristicValueInfo.value }])
        }, []) ?? possibleValues
    )
}
