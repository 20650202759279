export default class DownloadFileService {
    static downloadFile(blob: Blob, filename: string) {
        const blobURL = window.URL.createObjectURL(blob)
        let tempLink = document.createElement("a")
        tempLink.style.display = "none"
        tempLink.href = blobURL
        tempLink.setAttribute("download", filename)
        tempLink.click()
        window.URL.revokeObjectURL(blobURL)
    }

    static base64ToBlob(data: string, mimeType: string) {
        const byteString = atob(data)
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ab], { type: mimeType })
    }

    static jsonToBlob(json: string) {
        const bytes = new TextEncoder().encode(json)
        return new Blob([bytes], {
            type: "application/json;charset=utf-8"
        })
    }
}
