import { GridFilterModel, GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro"
import { Constants, Paging, PagingSortDirection, SalesDocumentFilter } from "@encoway/sales-api-js-client"
import { DateSalesDocumentProperties, NumberSalesDocumentProperties, SalesDocumentsPaging } from "../sales.constants"

export default class SalesDocumentsUtils {
    static getFilter(filterModel?: GridFilterModel): SalesDocumentFilter {
        const filter = new SalesDocumentFilter()
        filterModel?.items.forEach(item => {
            if (item.value) {
                const type = this.getDocumentFilterType(item.field)
                filter.add(item.field, item.operator, item.value, type)
            }
        })
        return filter
    }

    static getPaging(paginationModel?: GridPaginationModel, sortModel?: GridSortModel): Paging {
        const sortDirection = sortModel?.at(0)?.sort?.toUpperCase() ?? SalesDocumentsPaging.DEFAULT_SORT_DIRECTION
        return new Paging()
            .offset(paginationModel ? paginationModel.page * paginationModel.pageSize : 0)
            .limit(paginationModel?.pageSize ? paginationModel?.pageSize : 100)
            .sortField(sortModel?.at(0)?.field ?? SalesDocumentsPaging.DEFAULT_SORT_FIELD)
            .sortDir(sortDirection as PagingSortDirection)
    }

    private static getDocumentFilterType(field: string) {
        if (Object.values(DateSalesDocumentProperties).includes(field as any)) {
            return Constants.Type.Date
        } else if (Object.values(NumberSalesDocumentProperties).includes(field as any)) {
            return Constants.Type.Numeric
        } else {
            return Constants.Type.String
        }
    }
}
