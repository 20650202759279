import { CatalogApi } from "../../../../catalog/catalog.api"
import { TableCell, TableRow, Typography } from "@mui/material"
import theme from "../../../../../App.theme"
import { Difference } from "./TooltipTitle.types"
import { getPossibleValueTranslation } from "./TooltipTitle.utils"

export interface TooltipTitleTableRowProps {
    parameterName: string
    difference: Difference
    showLineupDifferences: boolean
}

export function TooltipTitleTableRow({ parameterName, difference, showLineupDifferences }: Readonly<TooltipTitleTableRowProps>) {
    const characteristic = CatalogApi.useCharacteristicQuery(parameterName).data
    const translatedParameterName = characteristic?.name ?? parameterName
    const translatedConfigurationParameterValue = getPossibleValueTranslation(difference.configurationParameterValue, characteristic)
    const translatedProjectOptionsParameterValue = getPossibleValueTranslation(difference.projectOptionsParameterValue, characteristic)
    const translatedLineupOptionsParameterValue = getPossibleValueTranslation(difference.lineupOptionsParameterValue, characteristic)
    return (
        <TableRow sx={{ td: { px: 1, py: 0 } }}>
            <TableCell>
                <Typography fontWeight={theme.typography.fontWeightBold} whiteSpace="nowrap">
                    {translatedParameterName}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>{translatedConfigurationParameterValue}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{translatedProjectOptionsParameterValue}</Typography>
            </TableCell>
            {showLineupDifferences && (
                <TableCell>
                    <Typography>{translatedLineupOptionsParameterValue}</Typography>
                </TableCell>
            )}
        </TableRow>
    )
}
