const RouterPaths = {
    INDEX: "/",
    ROOT_PAGE: "/app",
    DASHBOARD: "/app/dashboard",
    PROJECTS: "/app/projects",
    PROJECT: "/app/projects/:salesDocumentId",
    PROJECT_DETAILS: "/app/projects/:salesDocumentId/details",
    PROJECT_CATALOG: "/app/projects/:salesDocumentId/catalog/:productGroupIds",
    PROJECT_CONFIGURATION: "/app/projects/:salesDocumentId/configuration",
    PROJECT_COMPOSITION: "/app/projects/:salesDocumentId/composition",
    PROJECT_REPORTS: "/app/projects/:salesDocumentId/reports",
    CONAN: "/app/conan/:configurationId"
}

export default RouterPaths
