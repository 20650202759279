import { Section, SectionProps } from "@encoway/cui-configurator-components"
import { AbbHorizontalSectionStyles, AbbSectionStyles } from "./AbbSection.styles"
import { ContentBlockIds } from "../../../../../../../../features/catalog/catalog.constants"
import { ContainerTO } from "@encoway/c-services-js-client"

const LAYOUT_SECTIONS = [ContentBlockIds.HorizontalLayout, ContentBlockIds.VerticalLayout]

const shouldDisplayLabel = (container: ContainerTO) =>
    container.parameters?.length > 0 || // default behavior
    LAYOUT_SECTIONS.includes(container.children.at(0)?.name as any)

export function AbbSection(props: SectionProps) {
    return (
        <Section
            {...props}
            hideLabel={LAYOUT_SECTIONS.includes(props.data?.name as any)}
            shouldDisplayLabel={shouldDisplayLabel}
            styles={props.data?.name === ContentBlockIds.HorizontalLayout ? AbbHorizontalSectionStyles : AbbSectionStyles}
            prefix=""
        />
    )
}
