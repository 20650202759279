import { Visualization } from "@encoway/visual-editor"
import { queryFn, QueryFnOptions } from "../../store/queryFn"

type VisualizationQueryFnOptions<Response, Arg> = Omit<QueryFnOptions<Response, Arg, Visualization>, "serviceSelector">

export function visualizationQueryFn<Response, Arg>(options: VisualizationQueryFnOptions<Response, Arg>) {
    return queryFn<Response, Arg, Visualization>({
        serviceSelector: state => state.visualization.visualization,
        ...options
    })
}
