import theme from "../../../App.theme"

const SearchInputStyles = {
    input: {
        width: 300,
        ":hover": {
            borderColor: theme.palette.text.secondary
        },
        borderRadius: 1
    },
    icon: {
        color: theme.palette.text.secondary,
        cursor: "pointer"
    }
}

export default SearchInputStyles
