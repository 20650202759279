import { UserInformation } from "../../../../../features/oidc/hooks/useUserInformation"
import { OidcUtils } from "../../../../../features/oidc/oidc.utils"
import { LineItemProperties } from "../../../../../features/sales/sales.constants"
import { GenericInput } from "../../../../../components/forms/genericForm/GenericForm"

export const filterInput = (input: GenericInput, userInformation?: UserInformation): boolean => {
    if (input.id === LineItemProperties.PLAIN_COST) {
        return OidcUtils.hasReadTotalCostRole(userInformation)
    } else {
        return true
    }
}
