import Button, { ButtonProps } from "@mui/material/Button"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../../features/translations/TranslationKeys"
import { Badge } from "@mui/material"
import { UnitOverviewTableStyles } from "./UnitOverviewTable.styles"

type DefineUnitOptionsButtonProps = Readonly<ButtonProps> & {
    numberOfSelectedUnits: number
}

export function DefineUnitOptionsButton({ numberOfSelectedUnits, ...props }: DefineUnitOptionsButtonProps) {
    return (
        <Badge badgeContent={numberOfSelectedUnits} color="error" showZero sx={UnitOverviewTableStyles.badge}>
            <Button {...props}>{L10n.format(TranslationKeys.pages.project.configuration.unitOverviewTable.defineUnitOptions)}</Button>
        </Badge>
    )
}
