import { CheckboxProps, NO_VALUE } from "@encoway/cui-configurator-components"
import { merge } from "lodash"
import { NOT_VALUES } from "./AbbValueCheckbox.constants"

export function parameterIsSupported(props: Readonly<CheckboxProps>) {
    return (props.data.values?.length === 2 && getFalseValues(props)?.length === 1) || props.data.values?.length === 1
}

export function adjustProps(props: Readonly<CheckboxProps>): CheckboxProps {
    //  use translated name and info props of true value
    const trueValue = getTrueValue(props)
    const adjustedProps = merge({}, props, { data: { ...trueValue, translatedName: trueValue?.translatedValue } })

    // translate custom false values to standard false value
    const falseValue = getFalseValue(adjustedProps)
    if (falseValue) {
        falseValue.value = NO_VALUE
    }

    // hide state icon which is rendered by parameter component
    const selectedValue = getSelectedValue(adjustedProps)
    if (selectedValue) {
        selectedValue.selectionSource = "" as any
    }

    return adjustedProps
}

export function getFalseValue(props: Readonly<CheckboxProps>) {
    return getFalseValues(props)?.at(0)
}

function getFalseValues(props: Readonly<CheckboxProps>) {
    return props.data.values?.filter(value => NOT_VALUES.includes(value.value))
}

function getTrueValue(props: Readonly<CheckboxProps>) {
    return props.data.values?.find(value => !NOT_VALUES.includes(value.value))
}

function getSelectedValue(props: Readonly<CheckboxProps>) {
    return props.data.values?.find(value => value.selected)
}
