import { useEffect, useState } from "react"
import { Http, useToken } from "@encoway/sales-showroom-auth"
import { ConfigurationService } from "@encoway/c-services-js-client"
import { useAppSelector } from "../../../../../store/store"
import { Settings } from "../../../../../settings"

export default function useConfigurationService() {
    const configurationId = useAppSelector(state => state.sales.configurationId)
    const tokenFunction = useToken()
    const [configurationService, setConfigurationService] = useState<ConfigurationService>()

    useEffect(() => {
        if (configurationId) {
            const http = Http.Bearer(tokenFunction).withCredentialsInclude()
            ConfigurationService.create(http, Settings.configuration.baseUrl, { configurationId }, Settings.locale).then(setConfigurationService)
        }
    }, [configurationId, tokenFunction])

    useEffect(() => {
        return () => {
            configurationService?.disposeHeartbeat()
        }
    }, [configurationService])

    return configurationService
}
