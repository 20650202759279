import theme from "../../../../../../../App.theme"
import { SxProps } from "@mui/system"
import { BomNode } from "../../LineItemsDataGrid"

export const ProductNameCellStyles = (bomNode: BomNode, withExpandButton: boolean) => {
    return {
        stack: {
            alignItems: "center",
            paddingLeft: (withExpandButton ? bomNode.level * 10 - 20 : bomNode.level * 10) + "px"
        },
        typography: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            cursor: bomNode.lineItem.properties.configurable ? "pointer" : undefined,
            ":hover": {
                color: bomNode.lineItem.properties.configurable ? theme.palette.text.secondary : undefined
            }
        } as SxProps
    }
}
