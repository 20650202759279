import { useCallback, useEffect, useState } from "react"
import { ConfigurationService, ContainerTO, ParameterTO } from "@encoway/c-services-js-client"
import { ConfigurationElement } from "../../types/configuration.types"
import { View } from "../ActionBar/ActionBar"
import { AuthTokenProvider, useToken } from "@encoway/sales-showroom-auth"
import { ConanService } from "../../service/ConanService"
import { useBroadcastChannel } from "../../../../hooks/useBroadcastChannel"

const transformContainer = (container: ContainerTO, parentId: string, index: number): ConfigurationElement => {
    const id = `${parentId}_${container.id}_${index}`
    const childContainers = container.children.map((c, i) => transformContainer(c, id, i))
    const childParameters: ConfigurationElement[] = container.parameters.map((p, i) => transformParameter(p, id, i))
    return {
        id: id,
        name: container.name || "",
        type: "CONTAINER",
        children: childContainers.concat(childParameters)
    }
}

const fetchConfigurationState = async (
    configurationService: ConfigurationService,
    view: View,
    tokenFunction: AuthTokenProvider
): Promise<ConfigurationElement> => {
    const configurationId = configurationService.configurationId
    const configuration = await ConanService.fetchConfig(configurationId, view, tokenFunction)
    let children: ConfigurationElement[] = []
    const id = `${configurationId}`
    if (configuration.rootContainer) {
        children.push(transformContainer(configuration.rootContainer, id, 0))
    }
    return {
        id: id,
        name: configuration.name || "",
        children: children,
        type: "CONFIGURATION",
        isComparison: (configuration.properties as any)["isComparison"] === "true"
    } as ConfigurationElement
}

const transformParameter = (parameter: ParameterTO, parentId: string, index: number): ConfigurationElement => {
    const id = `${parentId}_${parameter.id}_${index}`
    return {
        id: id,
        type: parameter?.properties?.["isComparison"] === "true" ? "PARAMETER_COMPARISON" : "PARAMETER",
        name: parameter.name || "",
        value: parameter.terminal ? parameter.values?.find(v => v.selected)?.value : "",
        displayUnit: parameter.displayUnit?.unit,
        minMaxValue: `${parameter.minValue ?? "-∞"} to ${parameter.maxValue ?? "∞"}`,
        possibleValues: `[${parameter.values?.map(v => v.value).join(", ")}]`,
        mandatory: parameter.mandatory,
        setByDefault: parameter.setByDefault,
        setBySystem: parameter.terminal && !parameter.undoable,
        setByUser: parameter.terminal && parameter.undoable && !parameter.setByDefault,
        invisible: parameter.facets?.["SYS:INVISIBLE"] === "1",
        children: []
    }
}

export const useConfigurationState = (
    configurationService: ConfigurationService,
    view: View,
    comparisonFile: string,
    setFetchingConfiguration: (b: boolean) => void
) => {
    const [configurationState, setConfigurationState] = useState<ConfigurationElement>()
    const broadcastChannel = useBroadcastChannel("configuration")
    const tokenFunction = useToken()
    const fetchConfiguration = useCallback(async () => {
        setFetchingConfiguration(true)
        try {
            const configuration = await fetchConfigurationState(configurationService, view, tokenFunction)
            setConfigurationState(configuration)
        } finally {
            setFetchingConfiguration(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationService, view, tokenFunction, comparisonFile, setConfigurationState, setFetchingConfiguration])
    useEffect(() => {
        fetchConfiguration()
    }, [fetchConfiguration])
    useEffect(() => {
        if (broadcastChannel) {
            broadcastChannel.onmessage = fetchConfiguration
        }
    }, [broadcastChannel, fetchConfiguration])

    return configurationState
}
