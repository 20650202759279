import { GridColDef, GridSortModel, useGridApiRef } from "@mui/x-data-grid-pro"
import { ProductQuickSelectionDataGridStyles, LoaderGridStyles } from "./ProductQuickSelectionDataGrid.styles"
import DataGrid from "../../../../../../components/dataGrid/DataGrid"
import CatalogUtils from "../../../../../../features/catalog/catalog.utils"
import { useSearchParams } from "react-router-dom"
import { CatalogApi } from "../../../../../../features/catalog/catalog.api"
import { ViewIds } from "../../../../../../features/catalog/catalog.constants"
import { createContext, memo, useEffect, useMemo, useState } from "react"
import { ProductQuickSelectionColumns } from "./ProductQuickSelectionDataGrid.constants"
import { getCharacteristicColumns, getRowClassName, getRowId } from "./ProductQuickSelectionDataGrid.utils"
import { L10n } from "@encoway/l10n"
import { getProductSelection } from "../../ProductSelection.utils"
import { ProductSelectionData } from "../ProductQuickSelection"
import { Product } from "@encoway/c-services-js-client"
import { ProductSelectionSearchParams } from "../../ProductSelection.constants"

interface ProductQuickSelectionDataGridProps {
    productGroupId: string
    characteristicFilters: Record<string, any>
    updateProductQuantity: (productGroupId: string, product: Product, quantity: number) => void
    selectedProducts: ProductSelectionData
}

export const ProductQuickSelectionDataGridContext = createContext<ProductQuickSelectionDataGridProps | undefined>(undefined)

function ProductQuickSelectionDataGrid(props: Readonly<ProductQuickSelectionDataGridProps>) {
    const [sortModel, setSortModel] = useState<GridSortModel>()
    const [searchParams] = useSearchParams()
    const searchValue = searchParams.get(ProductSelectionSearchParams.SEARCH) ?? undefined
    const gridApiRef = useGridApiRef()

    const productSelection = getProductSelection(props.productGroupId, props.characteristicFilters, false, searchValue, ViewIds.QUICK_SELECTION)
    const productsQuery = CatalogApi.useProductsQuery(productSelection)

    const characteristics = useMemo(() => {
        return productsQuery.currentData ? CatalogUtils.sortCharacteristicsByView(productsQuery.currentData, ViewIds.QUICK_SELECTION).characteristics : []
    }, [productsQuery.currentData])

    useEffect(() => {
        setSortModel(characteristics.map(characteristic => ({ field: characteristic.id, sort: "asc" })))
    }, [characteristics])

    const columns = useMemo(() => {
        const copiedColumns: GridColDef[] = ProductQuickSelectionColumns.map(column => ({ ...column, headerName: L10n.format(column.headerName!) }))
        copiedColumns.splice(2, 0, ...getCharacteristicColumns(characteristics))
        return copiedColumns
    }, [characteristics])

    if (!columns || !productsQuery.currentData?.products || productsQuery.currentData?.products.length === 0) {
        return (
            <DataGrid
                rows={[]}
                columns={[]}
                hideFooter
                style={LoaderGridStyles}
                loading={productsQuery.isFetching}
                slotProps={{ noRowsOverlay: { error: productsQuery.error?.message, onRetry: productsQuery.refetch } }}
            />
        )
    } else {
        return (
            <ProductQuickSelectionDataGridContext.Provider value={props}>
                <DataGrid
                    apiRef={gridApiRef}
                    sx={ProductQuickSelectionDataGridStyles}
                    rows={productsQuery.currentData?.products ?? []}
                    getRowId={getRowId}
                    getRowClassName={getRowClassName}
                    columns={columns}
                    hideFooter
                    disableRowSelectionOnClick
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    autosizeOptions={{
                        includeOutliers: true,
                        includeHeaders: true
                    }}
                    autosizeOnMount
                />
            </ProductQuickSelectionDataGridContext.Provider>
        )
    }
}

export default memo(ProductQuickSelectionDataGrid)
