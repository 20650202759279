import { Divider, IconButton, Menu, MenuItem } from "@mui/material"
import ViewArrayIcon from "@mui/icons-material/ViewArray"
import { L10n } from "@encoway/l10n"
import { NumberValue } from "@encoway/c-services-js-client"
import { LayoutIconEntry } from "./LayoutIconEntry"
import FocusConfiguration from "../../../../../../assets/layouticons/menu_icon-FocusConfiguration.svg"
import FocusVisualization from "../../../../../../assets/layouticons/menu_icon-FocusVisualization.svg"
import HideConfiguration from "../../../../../../assets/layouticons/menu_icon-HideConfiguration.svg"
import ShowConfiguration from "../../../../../../assets/layouticons/menu_icon-ShowConfiguration.svg"
import HideConfigurationTree from "../../../../../../assets/layouticons/menu_icon-HideConfigurationTree.svg"
import ShowVisualization from "../../../../../../assets/layouticons/menu_icon-ShowVisualization.svg"
import HideVisualization from "../../../../../../assets/layouticons/menu_icon-HideVisualization.svg"
import ShowConfigurationTree from "../../../../../../assets/layouticons/menu_icon-ShowConfigurationTree.svg"
import ShowOnlyConfiguration from "../../../../../../assets/layouticons/menu_icon-ShowOnlyConfiguration.svg"
import ResetLayout from "../../../../../../assets/layouticons/menu_icon-ResetLayout.svg"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import useConfigurationProduct from "../../../../../../features/sales/hooks/useConfigurationProduct"
import CatalogUtils from "../../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../../features/catalog/catalog.constants"
import usePopover from "../../../../../../hooks/usePopover"
import { SalesSlice } from "../../../../../../features/sales/sales.slice"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"

export function LayoutMenuButton() {
    const configurationTreeIsAvailable = useAppSelector(state => state.sales.configurationTreeIsAvailable)
    const visualizationIsAvailable = useAppSelector(state => state.sales.visualizationIsAvailable)
    const hideConfigurationTree = useAppSelector(state => state.sales.hideConfigurationTree)
    const hideConfiguration = useAppSelector(state => state.sales.hideConfiguration)
    const hideVisualization = useAppSelector(state => state.sales.hideVisualization)

    const product = useConfigurationProduct()

    const disableConfigurationTree = product
        ? CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.HideConfigurationTree.id)?.value === 1
        : false

    const popover = usePopover()
    const dispatch = useAppDispatch()

    const toggleConfigurationTree = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: !hideConfigurationTree }))
        popover.close()
    }

    const toggleConfiguration = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfiguration: !hideConfiguration }))
        popover.close()
    }

    const toggleVisualization = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideVisualization: !hideVisualization }))
        popover.close()
    }

    const focusConfiguration = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideVisualization: true, hideConfiguration: false }))
        popover.close()
    }

    const focusVisualization = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: true, hideVisualization: false, hideConfiguration: false }))
        popover.close()
    }

    const showOnlyConfiguration = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: true, hideVisualization: true, hideConfiguration: false }))
        popover.close()
    }

    const resetLayout = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideVisualization: false, hideConfiguration: false }))
        popover.close()
    }

    const onlyConfigurationIsShown = hideConfigurationTree && hideVisualization && !hideConfiguration
    const configurationIsFocused = !hideConfigurationTree && hideVisualization && !hideConfiguration
    const visualizationIsFocused = hideConfigurationTree && !hideVisualization && !hideConfiguration
    const layoutIsDefault = !hideConfigurationTree && !hideVisualization && !hideConfiguration

    return (
        <>
            <IconButton
                onClick={event => popover.open(event.currentTarget)}
                sx={{ width: "fit-content", backgroundColor: popover.isOpen ? theme => theme.palette.divider : undefined }}
            >
                <ViewArrayIcon />
            </IconButton>
            <Menu
                anchorEl={popover.anchorEl}
                open={popover.isOpen}
                onClose={popover.close}
                disableScrollLock
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {configurationTreeIsAvailable && (
                    <MenuItem onClick={toggleConfigurationTree} disabled={disableConfigurationTree}>
                        {hideConfigurationTree || disableConfigurationTree ? (
                            <LayoutIconEntry
                                icon={ShowConfigurationTree}
                                label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.showConfigurationTree)}
                            />
                        ) : (
                            <LayoutIconEntry
                                icon={HideConfigurationTree}
                                label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.hideConfigurationTree)}
                            />
                        )}
                    </MenuItem>
                )}
                {visualizationIsAvailable && (
                    <MenuItem onClick={toggleVisualization}>
                        {hideVisualization ? (
                            <LayoutIconEntry
                                icon={ShowVisualization}
                                label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.showVisualization)}
                            />
                        ) : (
                            <LayoutIconEntry
                                icon={HideVisualization}
                                label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.hideVisualization)}
                            />
                        )}
                    </MenuItem>
                )}
                <MenuItem onClick={toggleConfiguration}>
                    {hideConfiguration ? (
                        <LayoutIconEntry
                            icon={ShowConfiguration}
                            label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.showConfiguration)}
                        />
                    ) : (
                        <LayoutIconEntry
                            icon={HideConfiguration}
                            label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.hideConfiguration)}
                        />
                    )}
                </MenuItem>
                <Divider />
                <MenuItem onClick={showOnlyConfiguration} disabled={onlyConfigurationIsShown}>
                    <LayoutIconEntry
                        icon={ShowOnlyConfiguration}
                        label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.showOnlyConfiguration)}
                    />
                </MenuItem>
                <MenuItem onClick={focusConfiguration} disabled={configurationIsFocused}>
                    <LayoutIconEntry
                        icon={FocusConfiguration}
                        label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.focusConfiguration)}
                    />
                </MenuItem>
                <MenuItem onClick={focusVisualization} disabled={visualizationIsFocused}>
                    <LayoutIconEntry
                        icon={FocusVisualization}
                        label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.focusVisualization)}
                    />
                </MenuItem>
                <Divider />
                <MenuItem onClick={resetLayout} disabled={layoutIsDefault}>
                    <LayoutIconEntry icon={ResetLayout} label={L10n.format(TranslationKeys.pages.project.configuration.layoutOptions.resetLayout)} />
                </MenuItem>
            </Menu>
        </>
    )
}
