import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import { SalesService, UpdateServerResponse } from "@encoway/sales-api-js-client"
import { AbbLineItem } from "./sales.types"
import SalesApi from "./sales.api"
import { queryFn, QueryFnOptions } from "../../store/queryFn"
import { LineItemsArgs } from "./sales.api.types"

export type SalesQueryFnOptions<Response, Arg> = Omit<QueryFnOptions<Response, Arg, SalesService>, "serviceSelector">

export function salesQueryFn<Response, Arg>(options: SalesQueryFnOptions<Response, Arg>) {
    return queryFn<Response, Arg, SalesService>({
        serviceSelector: state => state.sales.salesService,
        ...options
    })
}

export function getAddedLineItem(response: UpdateServerResponse): AbbLineItem | undefined {
    return Object.values(response.addedChildLineItems).at(0)?.at(0)
}

export function getUpdatedLineItem(lineItemId: string, response: UpdateServerResponse): AbbLineItem | undefined {
    return Object.values(response.changedLineItems).find(lineItem => lineItem.lineItemId === lineItemId)
}

export async function getLineItems(api: BaseQueryApi, lineItemId?: string) {
    const lineItemsQuery = api.dispatch(SalesApi.endpoints.lineItems.initiate(lineItemId))
    lineItemsQuery.unsubscribe()
    return await lineItemsQuery.unwrap()
}

export async function getLineItemsToExpand(api: BaseQueryApi, lineItemId?: string): Promise<string[]> {
    const lineItems = await getLineItems(api, lineItemId)
    const nonLeafLineItemIds = lineItems.filter(lineItem => !lineItem.properties.isLeaf).map(lineItem => lineItem.lineItemId)
    const promiseResult = await Promise.allSettled(nonLeafLineItemIds.map(lineItemId => getLineItemsToExpand(api, lineItemId)))
    const childLineItemsToExpand = promiseResult.reduce((result: string[], entry) => (entry.status === "fulfilled" ? result.concat(entry.value) : result), [])
    return nonLeafLineItemIds.concat(childLineItemsToExpand)
}

export function getAllExpandedLineItems(api: BaseQueryApi, lineItemId: string): string[] {
    const children = SalesApi.endpoints.composition.select()(api.getState() as any).data?.[lineItemId]
    return children?.flatMap(lineItem => getAllExpandedLineItems(api, lineItem.lineItemId)).concat(lineItemId) ?? []
}

export function mapMoveLineItemsArgs(args: LineItemsArgs) {
    return {
        selectedLineItems: args.selectedLineItemIds.map(lineItemId => ({ lineItemId })),
        parentLineItem: args.parentLineItemId ? { lineItemId: args.parentLineItemId } : null,
        previousLineItem: args.previousLineItem
    }
}
