/* eslint-disable */
import { Stack, StackItem } from "@fluentui/react/lib/Stack"
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling"
import { TextField } from "@fluentui/react/lib/TextField"
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Constants as RestApiConstants, ParameterTO } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"
import { ComponentFactory, Constants as ReactConfiguratorConstants } from "@encoway/react-configurator"
import { ComponentName, DefaultOnGetPlaceholder, InputFieldProps, InputFieldStyles, READY_STATE_NOT_READY } from "@encoway/cui-configurator-components"
import { IInputFieldStyles } from "@encoway/cui-configurator-components/src/components/InputField/InputField.types"
import { getUnitDisplayValue } from "@encoway/cui-configurator-components/dist/esm/helperFunctions"
import { useValidation } from "../../validation/customValidation" /* CUSTOMIZING */
import toFormattedString from "../../../../../../../../utils/toFormattedString"

function getSelectedValue(parameterTO: ParameterTO) {
    if (parameterTO.terminal && parameterTO.values) {
        return parameterTO.values.find(v => v?.selected)?.translatedValue
    }
}

function getUnformattedSelectedValue(parameterTO: ParameterTO) {
    if (parameterTO.terminal && parameterTO.values) {
        const unformattedSelectedValue = parameterTO.values.find(v => v?.selected)?.value
        if (parameterTO.valueType !== "STRING") {
            const unformattedSelectedValueAsNumber = unformattedSelectedValue ? parseFloat(unformattedSelectedValue) : NaN

            if (!isNaN(unformattedSelectedValueAsNumber)) {
                return toFormattedString(unformattedSelectedValueAsNumber, {
                    maximumFractionDigits: parameterTO.valueType === "INTEGER" ? 0 : 20
                }) /* CUSTOMIZING */
            }
        }
        return unformattedSelectedValue
    }
}

function determineBoundaryForValueRange(parameterTO: Record<string, unknown>, propName: string) {
    const value = parameterTO[propName]
    if (value && String(value).indexOf("inf") === -1) {
        return value
    }
}

function getPlaceholder(parameterTO: ParameterTO) {
    const minValue = determineBoundaryForValueRange(parameterTO as unknown as Record<string, unknown>, "minValue")
    const maxValue = determineBoundaryForValueRange(parameterTO as unknown as Record<string, unknown>, "maxValue")
    if (minValue && maxValue) {
        return minValue === maxValue ? `${minValue}` : `${minValue} - ${maxValue}`
    }
    if (minValue) {
        return `>= ${minValue}`
    }
    if (maxValue) {
        return `<= ${maxValue}`
    }
}

const getPlaceHolderFunc = (data: ParameterTO, onGetPlaceholder?: (data: ParameterTO, defaultGet: DefaultOnGetPlaceholder) => string) => {
    return onGetPlaceholder ? onGetPlaceholder(data, getPlaceholder) : getPlaceholder(data)
}

const stateIconStyles = {
    root: { marginTop: "0.5rem" }
}
const StateIcon = (props: any) => {
    return ComponentFactory.instanceOf(ComponentName.StateIcon, {
        ...props,
        styles: stateIconStyles
    })
}
const BurgerMenu = (props: any) => {
    return ComponentFactory.instanceOf(ComponentName.BurgerMenu, props)
}

/**
 * The InputField component renders a ParameterTO as MS Fluent TextField. It can be of type string or numeric, the latter can have a defined range of possibles values.
 * It has a built-in validation mechanism and is also styled accordingly in case of mandatory, disabled or error.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/InputField/InputField.tsx)
 * - [InputFieldStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/InputField/InputField.styles.ts)
 * - [InputFieldProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/InputField/InputField.types.ts)
 * - [MS Fluent TextField](https://developer.microsoft.com/de-DE/fluentui#/controls/web/textField)
 *
 * @visibleName InputField
 */
function IInputField(props: InputFieldProps) {
    const { styles, theme, data, ...delegatedProps } = props
    const classNames = classNamesFunction()(styles, theme) as IProcessedStyleSet<IInputFieldStyles>
    const initialFormattedValue = getSelectedValue(data) || ""
    const initialUnformattedValue = getUnformattedSelectedValue(data) || ""
    const unit = getUnitDisplayValue(data.displayUnit)
    const readOnly = props.options?.readOnly
    const isEditable = data.editable && !readOnly

    const ref = useRef<any>(null)

    const initialValue = ref.current && ref.current.state.isFocused ? initialUnformattedValue : initialFormattedValue

    const [currentValue, setCurrentValue] = useState(initialValue)
    const [error, setError] = useState<string | undefined>()
    const [ignoreBlur, setIgnoreBlur] = useState(false)
    const validate = useValidation(
        "InputField",
        setError,
        data.originalMinValue,
        data.originalMaxValue,
        data.unformattedOriginalMinValue,
        data.unformattedOriginalMaxValue
    )

    useEffect(() => {
        // whenever there is an error in the props:
        if (data.id === props.error?.id) {
            setError(
                L10n.format("Configuration.InputField.input.invalid", {
                    input: currentValue
                })
            )
        }
    }, [props.error, data.id, currentValue])

    useEffect(() => {
        setIgnoreBlur(false)
        setCurrentValue(initialValue)
        if (data.valueType !== "STRING") {
            validate(initialUnformattedValue, {
                updateError: true,
                removeError: true
            })
        }
    }, [data, initialValue])

    useEffect(() => {
        if (data.valueType !== "STRING" && data.undoable) {
            setError(undefined)
        }
    }, [data])

    const setValue = (value: any) => {
        setIgnoreBlur(true)
        props.onValueChanged(data, value, RestApiConstants.ValueFormat.Unformatted)
    }

    const undoValue = () => {
        setValue(ReactConfiguratorConstants.Undo)
    }

    const submit = () => {
        if (currentValue === "" && data.undoable) {
            undoValue()
        } else if (initialUnformattedValue === currentValue) {
            // do nothing
        } else if (data.valueType !== "STRING") {
            const validatedNumber = validate(currentValue)
            if (!isNaN(validatedNumber)) {
                setValue(validatedNumber)
            }
        } else {
            setValue(currentValue)
        }
    }

    const renderPlaceholder = () => {
        return !data.terminal && !readOnly ? getPlaceHolderFunc(data) : ""
    }

    let className = classNames.textField
    if (readOnly) {
        className = mergeStyles(className, classNames.disabled)
    } else if (isEditable) {
        className = mergeStyles(className, classNames.enabled)
        if (data.readyState === READY_STATE_NOT_READY) {
            className = mergeStyles(className, classNames.notReady)
        }
    }
    if (error) {
        className = mergeStyles(className, classNames.error)
    }

    const onFocus = useCallback(
        event => {
            const validatedNumber = validate(event.target.value)

            if (!isNaN(validatedNumber)) {
                setCurrentValue(initialUnformattedValue)
            }
        },
        [validate, initialUnformattedValue]
    )

    return (
        <Stack className={classNames.root}>
            {ComponentFactory.instanceOf(ComponentName.Parameter, {
                ...delegatedProps,
                data,
                hideStateIcon: true
            })}
            <Stack horizontal className={classNames.horizontalStack}>
                <StackItem grow={2}>
                    <TextField
                        componentRef={input => {
                            ref.current = input
                        }}
                        className={className}
                        suffix={unit}
                        disabled={!isEditable}
                        value={currentValue}
                        onFocus={onFocus}
                        onChange={event => {
                            const newValue = event.currentTarget.value || ""
                            setCurrentValue(newValue)
                            if (data.valueType !== "STRING") {
                                if (newValue) {
                                    validate(newValue, {
                                        updateError: true,
                                        removeError: true
                                    })
                                }
                            }
                        }}
                        onKeyPress={e => e.key === "Enter" && submit()}
                        onBlur={() => {
                            if (currentValue === initialUnformattedValue) {
                                setCurrentValue(initialFormattedValue)
                            }

                            if (!ignoreBlur) {
                                submit()
                            }
                        }}
                        placeholder={renderPlaceholder()}
                        errorMessage={error}
                    />
                </StackItem>
                <StateIcon {...props} />
                <BurgerMenu {...props} />
            </Stack>
        </Stack>
    )
}

/**
 * The InputField component renders a ParameterTO as MS Fluent TextField.
 *
 * @see InputFieldStyles
 * @see InputFieldProps
 */
export const CuiInputFieldWithCustomValueFormatting = styled(IInputField, InputFieldStyles)
CuiInputFieldWithCustomValueFormatting.displayName = "InputField"
