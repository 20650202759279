import { Button, IconButton, Stack } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { Characteristics, ProductGroupIds } from "../../../../features/catalog/catalog.constants"
import { CreateLineupButton } from "../../../../features/sales/components/createLineupButton/CreateLineupButton"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import { LayoutMenuButton } from "./components/layoutMenuButton/LayoutMenuButton"
import React, { useCallback, useMemo } from "react"
import { useAppSelector } from "../../../../store/store"
import useNavigate from "../../../../router/hooks/useNavigate"
import { ProjectConfigurationContainerIds } from "./ProjectConfiguration.constants"
import { useFullscreen } from "../../../../hooks/useFullscreen"
import SalesApi from "../../../../features/sales/sales.api"
import { AbbLineItem } from "../../../../features/sales/sales.types"
import { useSaveConfiguration } from "./hooks/useSaveConfiguration"

interface ProjectConfigurationHeaderProps {
    lineItem?: AbbLineItem
}

export function ProjectConfigurationHeader({ lineItem }: Readonly<ProjectConfigurationHeaderProps>) {
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const salesDocumentReadOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const configurationId = useAppSelector(state => state.sales.configurationId)
    const [applyConfigurationInterfaceDecisions] = SalesApi.useApplyConfigurationInterfaceDecisionsMutation()
    const configurationIsSaved = useAppSelector(state => state.sales.configurationIsSaved)
    const navigate = useNavigate()
    const saveConfiguration = useSaveConfiguration()

    const fullscreenElementGetter = useCallback(
        () => document.getElementsByClassName(ProjectConfigurationContainerIds.PROJECT_CONFIGURATION_CONTAINER)[0] as HTMLElement,
        []
    )
    const fullscreen = useFullscreen(fullscreenElementGetter)

    const addProduct = () => {
        if (salesDocument.properties.PRODUCT_CATEGORY) {
            const productGroupIds = [ProductGroupIds.Catalog, ProductGroupIds[salesDocument.properties.PRODUCT_CATEGORY as keyof typeof ProductGroupIds]]
            navigate.toProductGroup(salesDocument.salesDocumentId, productGroupIds)
        } else {
            navigate.toCatalog(salesDocument.salesDocumentId)
        }
    }

    const apply = async () => {
        if (lineItem) {
            const lineItemId = lineItem.properties.LINEUP_ARTICLE_LINE_ITEM_ID ?? lineItem.lineItemId
            await saveConfiguration()
            if (
                lineItem?.properties.ARTICLE_TYPE === "PROJECT_OPTIONS" ||
                lineItem?.properties.FOLDER_TYPE === "LINEUP" ||
                lineItem?.properties.ARTICLE_TYPE === "UNIT_OPTIONS"
            ) {
                await applyConfigurationInterfaceDecisions({ lineItemId })
            }
        }
    }

    const enableApplyButton = configurationId && !configurationIsSaved

    const showCreateLineupBtn: boolean = useMemo(() => {
        return !!salesDocument.properties.PRODUCT_CATEGORY && salesDocument.properties.PRODUCT_CATEGORY !== Characteristics.ProductCategory.possibleValues.MV
    }, [salesDocument.properties.PRODUCT_CATEGORY])

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
                <Button variant="contained" endIcon={<AddIcon />} onClick={addProduct} disabled={salesDocumentReadOnly}>
                    {L10n.format(TranslationKeys.pages.project.composition.addLineItemButtonLabel)}
                </Button>
                {showCreateLineupBtn && <CreateLineupButton variant="contained" color="secondary" disabled={salesDocumentReadOnly} />}
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
                <Button variant="contained" disabled={!enableApplyButton} onClick={apply}>
                    {L10n.format(TranslationKeys.pages.project.configuration.configurationInterfaceDecisions.applyButtonLabel)}
                </Button>
                <IconButton onClick={fullscreen.activate}>
                    <FullscreenIcon />
                </IconButton>
                <LayoutMenuButton />
            </Stack>
        </Stack>
    )
}
