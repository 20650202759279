import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../features/translations/TranslationKeys"
import Dialog, { DefaultDialogActionsProps, DialogProps } from "../../dialog/Dialog"
import { ButtonPropsWithLabel } from "../../dialog/dialogActions/DialogActions"
import DialogStyles from "../../dialog/Dialog.styles"

interface SaveButtonDialogProps extends Pick<DialogProps, "open">, Pick<DefaultDialogActionsProps, "onCancel" | "onConfirm"> {
    onSave: () => void
}

export default function SaveButtonDialog({ open, onConfirm, onCancel, onSave }: SaveButtonDialogProps) {
    const saveButton: ButtonPropsWithLabel = {
        label: TranslationKeys.components.unsavedChangesDialog.save,
        variant: "contained",
        onClick: onSave,
        sx: { flex: "none" }
    }

    const declineButton: ButtonPropsWithLabel = {
        label: TranslationKeys.components.unsavedChangesDialog.decline,
        variant: "contained",
        onClick: onConfirm,
        sx: DialogStyles.grayButton
    }

    const cancelButton: ButtonPropsWithLabel = {
        label: TranslationKeys.components.unsavedChangesDialog.cancel,
        variant: "contained",
        onClick: onCancel,
        sx: DialogStyles.grayButton
    }

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            title={L10n.format(TranslationKeys.components.unsavedChangesDialog.title)}
            message={L10n.format(TranslationKeys.components.unsavedChangesDialog.message)}
            customDialogActions={{
                actions: [saveButton, declineButton, cancelButton]
            }}
        />
    )
}
