import { ICUIAppStyles } from "@encoway/cui-application-components"

export const CuiStyles: ICUIAppStyles = {
    root: {
        display: "none",
        ".configuration": {
            order: 1
        }
    }
}
