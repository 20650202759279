import theme from "../../../../../../../../App.theme"
import { IStateIconStyles } from "@encoway/cui-configurator-components"

export const AbbStateIconStyles: IStateIconStyles = {
    root: {
        alignSelf: "center",
        margin: 0,
        lineHeight: "20px"
    },
    icon: {
        fontSize: "20px"
    },
    warningIcon: {
        color: theme.palette.warning.main
    }
}
