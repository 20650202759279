import LogoImage from "../../assets/logo.svg"

export class BrowserNotificationService {
    static addEventListeners() {
        document.addEventListener("mousedown", BrowserNotificationService.askForPermission)
        document.addEventListener("touchstart", BrowserNotificationService.askForPermission)
    }

    static askForPermission() {
        if (window.Notification && Notification.permission !== "granted" && Notification.permission !== "denied") {
            Notification.requestPermission()
            document.removeEventListener("mousedown", BrowserNotificationService.askForPermission)
            document.removeEventListener("touchstart", BrowserNotificationService.askForPermission)
        }
    }

    static notify(title: string, message?: string) {
        if (window.Notification && Notification.permission === "granted") {
            const notification = new Notification(title, { body: message, icon: LogoImage })
            document.addEventListener("visibilitychange", () => {
                if (document.visibilityState === "visible") {
                    notification.close()
                }
            })
        }
    }
}
