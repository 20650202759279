import { getProductGroupData } from "./ProductGroupCard.utils"
import useTextBox from "../../../../../components/textBox/useTextBox"
import TextBox from "../../../../../components/textBox/TextBox"
import { ProductGroupCardStyles } from "./ProductGroupCard.styles"
import { Skeleton } from "@mui/material"

interface ProductGroupCardTextBoxProps {
    data?: ReturnType<typeof getProductGroupData>
    textBox: ReturnType<typeof useTextBox>
    disabled: boolean
}

export function ProductGroupCardTextBox({ data, textBox, disabled }: ProductGroupCardTextBoxProps) {
    return data ? (
        <TextBox
            text={data.shortText ?? ""}
            sx={ProductGroupCardStyles.description(disabled)}
            expand={textBox.isExpanded}
            onExpansionChange={textBox.setIsExpanded}
            characterLimit={135}
            buttonProps={{ sx: ProductGroupCardStyles.expandDescriptionButton(disabled) }}
        />
    ) : (
        <>
            <Skeleton sx={ProductGroupCardStyles.description(disabled)} />
            <Skeleton sx={ProductGroupCardStyles.description(disabled)} />
            <Skeleton sx={ProductGroupCardStyles.description(disabled)} />
        </>
    )
}
