import { getTheme, ILabelStyles } from "@fluentui/react"

const theme = getTheme()

const AbbParameterLabelStyles: ILabelStyles = {
    root: {
        ":after": {
            color: theme.palette.themePrimary
        },
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }
}

export default AbbParameterLabelStyles
