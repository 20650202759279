import { GridEditInputCell, GridRenderEditCellParams } from "@mui/x-data-grid-pro"
import { Tooltip } from "@mui/material"

export const renderEditCell = (params: GridRenderEditCellParams) => {
    return (
        <Tooltip open={!!params.error} title={params.error ?? ""}>
            <GridEditInputCell {...params} />
        </Tooltip>
    )
}
