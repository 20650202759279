import { LinkedTree, LinkedTreeProps } from "@encoway/cui-configurator-components"
import AbbLinkedTreeStyles from "./AbbLinkedTree.styles"
import { Portal } from "@mui/material"
import { ProjectConfigurationContainerIds } from "../../../../ProjectConfiguration.constants"
import useConfigurationProduct from "../../../../../../../../features/sales/hooks/useConfigurationProduct"
import CatalogUtils from "../../../../../../../../features/catalog/catalog.utils"
import { NumberValue } from "@encoway/c-services-js-client"
import { Characteristics } from "../../../../../../../../features/catalog/catalog.constants"

export default function AbbLinkedTree(props: Readonly<LinkedTreeProps>) {
    const product = useConfigurationProduct()
    const hideTreeCharacteristicValue = product
        ? CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.HideConfigurationTree.id)?.value === 1
        : false
    return hideTreeCharacteristicValue ? null : (
        <Portal container={() => document.getElementById(ProjectConfigurationContainerIds.CONFIGURATION_TREE)}>
            <LinkedTree {...props} styles={AbbLinkedTreeStyles} />
        </Portal>
    )
}
