import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Visualization } from "@encoway/visual-editor"

export interface VisualizationState {
    visualization?: Visualization
}

const initialState: VisualizationState = {}

const VisualizationSlice = createSlice({
    name: "visualizationSlice",
    initialState,
    reducers: {
        setVisualization: (state, action: PayloadAction<VisualizationState["visualization"]>) => {
            // @ts-ignore
            state.visualization = action.payload
        }
    }
})

export default VisualizationSlice
