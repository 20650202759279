import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import ExpandButton from "../../../../../../../components/buttons/expandButton/ExpandButton"
import { Checkbox, Stack } from "@mui/material"
import SalesApi from "../../../../../../../features/sales/sales.api"
import { BomNode } from "../../LineItemsDataGrid"

export default function QuoteRelevantCell(params: GridRenderCellParams<BomNode>) {
    const [expandLineItem] = SalesApi.useExpandLineItemMutation()
    const [collapseLineItem] = SalesApi.useCollapseLineItemMutation()
    const toggleLineItem = () => (params.row.expanded ? collapseLineItem(params.row.lineItem.lineItemId) : expandLineItem(params.row.lineItem.lineItemId))
    return params.row.level !== 0 ? null : (
        <Stack direction="row" justifyContent="end" alignItems="center" width="100%">
            {!params.row.lineItem.properties.isLeaf ? <ExpandButton expanded={params.row.expanded} onToggle={toggleLineItem} /> : null}
            <Checkbox />
        </Stack>
    )
}
