import { Characteristics, Filter, Selection, Sort } from "@encoway/c-services-js-client"
import { Characteristics as AbbCharacteristics, ProductGroupIds } from "../../../../../features/catalog/catalog.constants"

export const announcementSelection = new Selection()
    .sort(new Sort().id())
    .characteristics(new Characteristics().all())
    .filter(
        Filter.boolFilter()
            .must(Filter.productGroupFilter().id(ProductGroupIds.Announcements))
            .must(Filter.characteristicFilter().id(AbbCharacteristics.AlertActive.id).equal(1))
    )
