import { Box } from "@mui/material"
import { TreeItem as MuiTreeItem, useTreeItemState } from "@mui/x-tree-view"
import { ProjectConfigurationLineItem } from "../../../../../../features/sales/sales.api.types"
import { TreeItemDragAndDropLabel } from "./TreeItem.DragAndDropLabel"
import { TreeItemStyles } from "./TreeItem.styles"
import { ProjectConfigurationContainerIds } from "../../ProjectConfiguration.constants"
import { AbbLineItemWithParent } from "../../../../../../features/sales/sales.types"

export interface ProjectConfigurationLineItemWithParent extends ProjectConfigurationLineItem, AbbLineItemWithParent {
    parent?: ProjectConfigurationLineItemWithParent
}

interface TreeItemProps {
    item: ProjectConfigurationLineItemWithParent
}

export function TreeItem({ item }: Readonly<TreeItemProps>) {
    const depth = (item?.lineItem?.properties?.positionnumber?.match(/\./g) || []).length

    const isSelected = useTreeItemState(item.lineItem.lineItemId).selected

    return (
        <MuiTreeItem itemId={item.lineItem.lineItemId} label={<TreeItemDragAndDropLabel item={item} />} sx={TreeItemStyles.treeItem}>
            {isSelected && (
                <Box id={ProjectConfigurationContainerIds.CONFIGURATION_TREE} sx={{ marginLeft: `${depth * 2.5 + 3.5}em` }} data-cy="AbbLinkedTree" />
            )}
            {item.children.map(child => (
                <TreeItem key={child.lineItem.lineItemId} item={{ ...child, parent: item }} />
            ))}
        </MuiTreeItem>
    )
}
