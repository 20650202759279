import { getTheme } from "@fluentui/react"
import { IDetailDropdownStyles } from "@encoway/cui-configurator-components"
import CuiStyleConstants from "../../constants/CuiStyleConstants"

const theme = getTheme()

const AbbDetailDropdownStyles: IDetailDropdownStyles = {
    dropdown: {
        selectors: {
            ".ms-Dropdown-title": {
                borderWidth: CuiStyleConstants.FIELD_BORDER_WIDTH,
                borderRadius: CuiStyleConstants.FIELD_BORDER_RADIUS
            },
            ".ms-Dropdown-titleIsPlaceHolder": {
                borderColor: CuiStyleConstants.FIELD_BORDER_COLOR
            },
            ".ms-Dropdown-caretDown": {
                color: theme.palette.neutralPrimary,
                fontSize: theme.fonts.tiny.fontSize
            }
        }
    },
    optionConflict: {
        ":hover": {
            color: theme.palette.neutralSecondary
        }
    }
}

export default AbbDetailDropdownStyles
