import { createApi } from "@reduxjs/toolkit/query/react"
import { ConfigurationStatus } from "./configuration.types"
import { baseQuery } from "../../store/baseQuery"

export const ConfigurationApiTags = {
    CONFIGURATION_STATUS: "configurationStatus"
}

export const ConfigurationApi = createApi({
    reducerPath: "configurationApi",
    baseQuery: baseQuery,
    tagTypes: Object.values(ConfigurationApiTags),
    refetchOnMountOrArgChange: true,
    endpoints: builder => ({
        status: builder.query<ConfigurationStatus, string>({
            providesTags: [ConfigurationApiTags.CONFIGURATION_STATUS],
            query: configurationId => `/api/features/configuration/${configurationId}/status`
        })
    })
})
