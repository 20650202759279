import { GenericFormProps, GenericInput } from "./GenericForm"

export class GenericFormUtils {
    public static validateInput(input: GenericInput, value: any) {
        return !(input.mandatory && !value) && !(input.type === "email" && !/\S{1,255}@\S{1,255}\.\S{1,255}/.test(value as string))
    }

    public static getNumberOfInvalidInputs(inputs: GenericInput[], values: GenericFormProps["values"]) {
        return inputs.filter(input => !GenericFormUtils.validateInput(input, values[input.id])).length
    }

    public static isFormValid(inputs: GenericInput[], values: GenericFormProps["values"]) {
        return GenericFormUtils.getNumberOfInvalidInputs(inputs, values) === 0
    }
}
