import { Theme } from "@mui/material"

export const ProjectConfigurationStyles = {
    container: {
        backgroundColor: (theme: Theme) => theme.palette.background.paper,
        overflowX: "hidden"
    },
    tree: (showConfigurationTree: boolean) => ({
        backgroundColor: (theme: Theme) => theme.palette.background.default,
        borderRadius: (theme: Theme) => theme.shape.borderRadius,
        display: showConfigurationTree ? undefined : "none",
        flex: "0 0 auto",
        maxWidth: "100%",
        overflow: "auto",
        padding: 2
    }),
    visualization: (showVisualization: boolean) => ({
        display: showVisualization ? undefined : "none",
        flex: "1 0 auto"
    }),
    configuration: (showConfiguration: boolean, showVisualization: boolean) => ({
        borderRadius: (theme: Theme) => theme.shape.borderRadius,
        display: showConfiguration ? undefined : "none",
        flex: showVisualization ? "0 0 auto" : "1 0 auto",
        overflow: "auto",
        width: "360px"
    })
}
