import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type HTMLEncodedErrorMessage = Error & {
    activateDangerouslySetInnerHTML: boolean
}

export type TError = HTMLEncodedErrorMessage | Error

interface ErrorState {
    error?: TError
}

const initialState: ErrorState = {
    error: undefined
}

const ErrorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<TError | undefined>) => {
            state.error = action.payload
        }
    }
})

export default ErrorSlice
