import { useCallback, useEffect, useState } from "react"
import { L10n } from "@encoway/l10n"
import { Box } from "@mui/material"
import useDialog from "../../../../../components/dialog/useDialog"
import { BrowserNotificationService } from "../../../../../features/browserNotification/BrowserNotificationService"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import Dialog from "../../../../../components/dialog/Dialog"

const ONE_SECOND = 1000
const ONE_MINUTE = 60 * ONE_SECOND
const SESSION_LIFETIME = 45 * ONE_MINUTE

type SessionExpirationCountdownProps = {
    logout: () => any
}

const SessionExpirationCountdown = ({ logout }: SessionExpirationCountdownProps) => {
    const [leftTime, setLeftTime] = useState<number>(SESSION_LIFETIME)
    const [lastActiveDate, setLastActiveDate] = useState<Date>(new Date())
    const dialog = useDialog()
    const [loggedOut, setLoggedOut] = useState(false)

    const updateRemainingTime = useCallback(() => {
        setLeftTime(SESSION_LIFETIME - (new Date().getTime() - lastActiveDate.getTime()) + ONE_SECOND - 1)
    }, [lastActiveDate])

    const onUserEvent = useCallback(() => {
        setLastActiveDate(new Date())
        setLeftTime(SESSION_LIFETIME)
    }, [])

    useEffect(() => {
        if (leftTime < 0 && !loggedOut) {
            logout()
            setLoggedOut(true)
        } else if (leftTime >= 5 * ONE_MINUTE && leftTime < 5 * ONE_MINUTE + ONE_SECOND) {
            dialog.open()
            BrowserNotificationService.notify(
                L10n.format(TranslationKeys.components.header.sessionTimeout.browserNotification.title),
                L10n.format(TranslationKeys.components.header.sessionTimeout.browserNotification.message)
            )
        }
    }, [leftTime, logout, dialog, loggedOut])

    useEffect(() => {
        window.addEventListener("mousedown", onUserEvent)
        window.addEventListener("keydown", onUserEvent)
        window.addEventListener("touchstart", onUserEvent)
        const interval = setInterval(updateRemainingTime, ONE_SECOND)
        return () => {
            window.removeEventListener("mousedown", onUserEvent)
            window.removeEventListener("keydown", onUserEvent)
            window.removeEventListener("touchstart", onUserEvent)
            clearInterval(interval)
        }
    }, [onUserEvent, updateRemainingTime])

    const formatLeftTime = () => {
        const minutes = Math.floor(leftTime / ONE_MINUTE)
        const seconds = Math.floor((leftTime - minutes * ONE_MINUTE) / ONE_SECOND)
        return (minutes >= 10 ? minutes : "0" + minutes) + ":" + (seconds >= 10 ? seconds : "0" + seconds)
    }

    return (
        <>
            <Dialog
                open={dialog.isOpen && !loggedOut}
                onClose={() => {
                    logout()
                    setLoggedOut(true)
                }}
                title={L10n.format(TranslationKeys.components.header.sessionTimeout.dialog.title, { time: formatLeftTime() })}
                message={L10n.format(TranslationKeys.components.header.sessionTimeout.dialog.message)}
                defaultDialogActions={{
                    onConfirm: dialog.close,
                    onCancel: () => {
                        logout()
                        setLoggedOut(true)
                    },
                    confirmButtonLabel: TranslationKeys.components.header.sessionTimeout.dialog.confirmButtonLabel,
                    cancelButtonLabel: TranslationKeys.components.header.sessionTimeout.dialog.cancelButtonLabel
                }}
            />
            <Dialog
                open={loggedOut}
                onClose={() => {
                    window.location.reload()
                }}
                title={L10n.format(TranslationKeys.components.header.sessionTimeout.dialog.loggedOutTitle, { time: formatLeftTime() })}
                message={L10n.format(TranslationKeys.components.header.sessionTimeout.dialog.loggedOutMessage)}
            />
            <Box>{L10n.format(TranslationKeys.components.header.sessionTimeout.timeUntil, { time: formatLeftTime() })}</Box>
        </>
    )
}

export default SessionExpirationCountdown
