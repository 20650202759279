import { Product } from "@encoway/c-services-js-client"
import { Button, Card, Skeleton, Stack, Typography } from "@mui/material"
import { Add, Settings } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import ProductCardStyles from "./ProductCard.styles"
import ABBLogo from "../../../../../assets/logo.svg"
import useTextBox from "../../../../../components/textBox/useTextBox"
import TextBox from "../../../../../components/textBox/TextBox"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import Image from "../../../../../components/image/Image"
import { NewTabLink } from "../../../../../components/links/newTabLink/NewTabLink"
import { useAppSelector } from "../../../../../store/store"
import { getProductData } from "./ProductCard.utils"
import { useCallback, useMemo } from "react"
import useDialog from "../../../../../components/dialog/useDialog"
import SalesApi from "../../../../../features/sales/sales.api"
import { AddToLineupDialog } from "../addToLineupDialog/AddToLineupDialog"
import { getAddedLineItem } from "../../../../../features/sales/sales.api.utils"
import useNavigate from "../../../../../router/hooks/useNavigate"
import { ProductSelectionPosition } from "../ProductSelection.constants"

interface ProductCardProps {
    product?: Product
    disabled?: boolean
}

export const ProductCard = ({ product, disabled = false }: ProductCardProps) => {
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const textBox = useTextBox()
    const configureDialog = useDialog()
    const addProductDialog = useDialog()
    const productData = useMemo(() => (product ? getProductData(product) : undefined), [product])
    const sx = ProductCardStyles(!!productData?.isConfigurable, disabled)

    const [addItems] = SalesApi.useAddItemsMutation()
    const navigate = useNavigate()

    const addProduct = useCallback(
        (product: Product, parentId?: string) =>
            addItems({
                articlesToInsert: [{ articleId: product.id, articleName: product.name, quantity: 1 }],
                parentId,
                position: ProductSelectionPosition.LAST
            }),
        [addItems]
    )

    const configureProduct = useCallback(
        async (product: Product, parentId?: string) => {
            const response = await addItems({
                articlesToInsert: [{ articleId: product.id, quantity: 1 }],
                parentId,
                position: ProductSelectionPosition.LAST
            }).unwrap()
            const addedLineItem = response ? getAddedLineItem(response) : undefined
            if (addedLineItem?.lineItemId) {
                navigate.toProjectConfiguration(salesDocument.salesDocumentId, addedLineItem.lineItemId)
            }
        },
        [navigate, salesDocument.salesDocumentId, addItems]
    )

    const onClickConfigureButton = () => {
        if (productData?.isLineupCompatible) {
            configureDialog.open()
        } else {
            configureProduct(product!)
        }
    }

    const onClickAddProductButton = () => {
        if (productData?.isLineupCompatible) {
            addProductDialog.open()
        } else {
            addProduct(product!)
        }
    }

    return (
        <Card data-cy={`ProductCard.Card.${productData?.id}`} component={Stack} spacing={2} sx={sx.card}>
            <Stack spacing={2} sx={sx.stack}>
                {productData ? (
                    <Image src={productData?.imageSrc} fallbackImageProps={{ src: ABBLogo, alt: "image of " + productData?.name, sx: sx.productImage }} />
                ) : (
                    <Skeleton variant="rounded" width="100%" height={200} />
                )}
                {productData ? (
                    <Typography data-cy={"Typography:ProductCard"} sx={sx.productName}>
                        {productData.name}
                    </Typography>
                ) : (
                    <Skeleton width="100%" sx={sx.productName} />
                )}
            </Stack>

            <TextBox
                text={productData?.shortText ?? ""}
                sx={sx.description}
                expand={textBox.isExpanded}
                onExpansionChange={textBox.setIsExpanded}
                characterLimit={100}
                buttonProps={{ sx: sx.expandDescriptionButton }}
            />

            <Stack spacing={2} sx={sx.stack}>
                <NewTabLink
                    text={L10n.format(TranslationKeys.pages.project.catalog.link)}
                    href={productData?.catalogLink}
                    disabled={disabled || !productData}
                />
                <Button
                    data-cy="OpenConfigureButton"
                    variant="contained"
                    size="small"
                    disabled={disabled || readOnly || !productData?.isConfigurable || !product}
                    startIcon={<Settings sx={sx.configureButtonIcon} />}
                    onClick={onClickConfigureButton}
                    sx={sx.configureButton}
                >
                    {L10n.format(TranslationKeys.pages.project.catalog.productSelection.configureButtonLabel)}
                </Button>
                {configureDialog.isOpen && <AddToLineupDialog onSubmit={lineUpId => configureProduct(product!, lineUpId)} onClose={configureDialog.close} />}
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={disabled || readOnly || !productData || !product}
                    startIcon={<Add />}
                    onClick={onClickAddProductButton}
                    sx={sx.addCompositionButton}
                >
                    {L10n.format(TranslationKeys.pages.project.catalog.productSelection.addToCompositionButtonLabel)}
                </Button>
                {addProductDialog.isOpen && <AddToLineupDialog onSubmit={lineUpId => addProduct(product!, lineUpId)} onClose={addProductDialog.close} />}
            </Stack>
        </Card>
    )
}
