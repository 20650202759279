import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../translations/TranslationKeys"
import { ConfigurationInterfaceDecisionsDifference } from "../../../sales.types"
import { TooltipTitleTableRow } from "./TooltipTitle.TableRow"
import { mergeDifferences } from "./TooltipTitle.utils"

export interface ConfigurationInterfaceDecisionsDifferencesIconTooltipTitleProps {
    projectOptionsDifferences: ConfigurationInterfaceDecisionsDifference[]
    lineupOptionsDifferences?: ConfigurationInterfaceDecisionsDifference[]
}

export function TooltipTitle({
    projectOptionsDifferences,
    lineupOptionsDifferences
}: Readonly<ConfigurationInterfaceDecisionsDifferencesIconTooltipTitleProps>) {
    const differences = mergeDifferences(projectOptionsDifferences, lineupOptionsDifferences)
    const showLineupDifferences = !!lineupOptionsDifferences
    return (
        <Stack spacing={1}>
            <Typography>{L10n.format(TranslationKeys.pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.message)}</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                                {L10n.format(TranslationKeys.pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.parameterName)}
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                                {L10n.format(TranslationKeys.pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.parameterValue)}
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                                {L10n.format(TranslationKeys.pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.projectOptionValue)}
                            </TableCell>
                            {showLineupDifferences && (
                                <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    {L10n.format(
                                        TranslationKeys.pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.lineupOptionValue
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(differences).map(([parameterName, difference]) => (
                            <TooltipTitleTableRow
                                key={parameterName}
                                parameterName={parameterName}
                                difference={difference}
                                showLineupDifferences={!!lineupOptionsDifferences}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}
