import theme from "../../../../../App.theme"
import { ProductGroupCardSize } from "./ProductGroupCard"

export const ProductGroupCardStyles = {
    card: (disabled: boolean, size: ProductGroupCardSize, descriptionIsExpanded: boolean) => ({
        backgroundColor: disabled ? theme.palette.background.default : "transparent",
        border: "1px solid " + theme.palette.divider,
        height: getCardHeight(size, descriptionIsExpanded),
        width: size === ProductGroupCardSize.Big ? 350 : 300,
        flexDirection: size === ProductGroupCardSize.Big ? "column" : "row",
        ":hover": {
            boxShadow: disabled ? "none" : theme.shadows.at(5),
            "& img": {
                height: size === ProductGroupCardSize.Default || !disabled ? 50 : 100,
                width: size === ProductGroupCardSize.Default || !disabled ? 50 : 100
            },
            "& h6": {
                fontSize: disabled ? theme.typography.h6.fontSize : theme.typography.body1.fontSize
            },
            "& button": {
                display: disabled ? "none" : "inherit"
            }
        }
    }),
    image: (disabled: boolean, size: ProductGroupCardSize) => ({
        display: "block",
        height: size === ProductGroupCardSize.Big ? 100 : 50,
        width: size === ProductGroupCardSize.Big ? 100 : 50,
        objectFit: "contain",
        flexShrink: 0,
        opacity: disabled ? 0.5 : 1,
        transition: `width ${theme.transitions.duration.complex}ms, height ${theme.transitions.duration.complex}ms`,
        "@media (hover: none)": {
            height: size === ProductGroupCardSize.Big && disabled ? 100 : 50,
            width: size === ProductGroupCardSize.Big && disabled ? 100 : 50
        }
    }),
    name: (disabled: boolean) => ({
        fontWeight: "bold",
        opacity: disabled ? 0.5 : 1,
        transition: `font-size ${theme.transitions.duration.complex}ms`,
        "@media (hover: none)": {
            fontSize: disabled ? theme.typography.h6.fontSize : theme.typography.body1.fontSize
        }
    }),
    description: (disabled: boolean) => ({
        fontSize: theme.typography.body2.fontSize,
        opacity: disabled ? 0.5 : 1
    }),
    expandDescriptionButton: (disabled: boolean) => ({
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body2.fontSize,
        marginBottom: 1,
        opacity: disabled ? 0.5 : 1,
        ":hover": {
            backgroundColor: "transparent",
            textDecoration: "underline"
        }
    }),
    button: (size: ProductGroupCardSize) => ({
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
        display: "none",
        minWidth: size === ProductGroupCardSize.Big ? "inherit" : 0,
        width: size === ProductGroupCardSize.Big ? "100%" : 150,
        maxWidth: size === ProductGroupCardSize.Big ? "100%" : 150,
        overflow: "hidden",
        marginTop: "auto !important",
        ":hover": {
            backgroundColor: theme.palette.divider
        },
        "@media (hover: none)": {
            backgroundColor: theme.palette.divider,
            display: "inherit"
        }
    })
}

function getCardHeight(size: ProductGroupCardSize, descriptionIsExpanded: boolean) {
    if (descriptionIsExpanded) {
        return "100%"
    } else if (size === ProductGroupCardSize.Big) {
        return 300
    } else {
        return 250
    }
}
