import { ITabStyles } from "@encoway/cui-configurator-components"
import theme from "../../../../../../../../App.theme"

const AbbTabStyles: ITabStyles = {
    selected: {
        backgroundColor: "transparent",
        borderBottom: `2px solid ${theme.palette.common.black}`,
        span: {
            color: theme.palette.text.primary
        },
        ":hover": {
            backgroundColor: "transparent"
        }
    },
    tab: {
        backgroundColor: "transparent",
        color: theme.palette.text.secondary,
        paddingRight: 0,
        paddingLeft: 0,
        marginRight: 10,
        marginLeft: 10,
        ":hover": {
            backgroundColor: "transparent"
        }
    },
    text: {
        color: theme.palette.text.secondary
    }
}

export default AbbTabStyles
