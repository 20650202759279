import { useMemo } from "react"
import { useParams as useRouterParams } from "react-router-dom"

export default function useParams() {
    const params = useRouterParams()

    return useMemo(
        () => ({
            salesDocumentId: params.salesDocumentId,
            lineItemId: params.lineItemId,
            productId: params.productId,
            productGroupIds: params.productGroupIds?.split("+")
        }),
        [params]
    )
}
