import { GridOverlay } from "@mui/x-data-grid-pro"
import AbbSpinner from "../../../../pages/projectPage/components/projectConfiguration/components/cui/components/AbbSpinner/AbbSpinner"

export function LoadingOverlay() {
    return (
        <GridOverlay>
            <AbbSpinner />
        </GridOverlay>
    )
}
