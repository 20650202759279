import Box from "@mui/material/Box"
import MainMenuStyles from "./MainMenu.styles"
import Button from "@mui/material/Button"
import { NavLink } from "react-router-dom"
import { ReactChild } from "react"

interface MenuEntry {
    label: string
    route: string
    icon?: ReactChild
}

interface MainMenuProps {
    menuEntries: MenuEntry[]
}

export default function MainMenu({ menuEntries }: MainMenuProps) {
    return (
        <Box sx={MainMenuStyles.wrapper}>
            {menuEntries.map(entry => (
                <Button startIcon={entry.icon} component={NavLink} key={entry.label} to={entry.route} sx={MainMenuStyles.link}>
                    {entry.label}
                </Button>
            ))}
        </Box>
    )
}
