import { useMemo } from "react"
import { GenericInput, InputType } from "../../../components/forms/genericForm/GenericForm"
import { CatalogApi } from "../../catalog/catalog.api"
import CatalogUtils from "../../catalog/catalog.utils"
import SalesApi from "../sales.api"
import { CurrencyPermissions } from "../sales.api.types"

const filterByPermissions = (characteristicId: string, value: string, permissions?: CurrencyPermissions) => {
    if (!permissions) {
        return false
    } else {
        const permissionsForCharacteristic: string[] | undefined = (permissions as any)?.[characteristicId]
        if (permissionsForCharacteristic) {
            return permissionsForCharacteristic.includes(value)
        } else {
            return true
        }
    }
}

export function useSalesDocumentPropertyInput(
    salesDocumentPropertyId: string,
    characteristicId: string,
    mandatory?: boolean,
    unselect?: boolean,
    skip?: boolean
): GenericInput {
    const characteristic = CatalogApi.useCharacteristicQuery(characteristicId, { skip }).data
    const permissions = SalesApi.useCurrencyPermissionsQuery()
    return useMemo(
        () => ({
            type: InputType.Dropdown,
            id: salesDocumentPropertyId,
            label: characteristic?.name ?? "",
            mandatory: mandatory,
            unselect: unselect,
            values: characteristic
                ? CatalogUtils.getPossibleValues(characteristic, true)
                      .map(pv => ({ value: pv.id, text: pv.value as string }))
                      .filter(val => filterByPermissions(characteristicId, val.value, permissions.data))
                : []
        }),
        [characteristic, mandatory, salesDocumentPropertyId, permissions, characteristicId, unselect]
    )
}
