const AppTitleWithLogoStyles = {
    link: {
        height: {
            xs: "1.375rem",
            sm: "2.063rem"
        },
        mr: {
            xs: "auto",
            sm: 2
        },
        ml: {
            xs: "auto",
            sm: 0
        },
        display: "flex",
        alignItems: "center"
    },
    logo: {
        height: {
            xs: "0.5rem",
            sm: "1.5rem"
        },
        mr: 5,
        mb: 0
    },
    type: {
        mr: {
            xs: "auto",
            sm: 2
        },
        ml: {
            xs: "auto",
            sm: 2
        },
        color: "text.secondary",
        textDecoration: "none"
    },
    title: {
        fontSize: 15,
        fontWeight: "bold",
        mb: {
            xs: "auto",
            sm: 0
        },
        mr: {
            xs: "auto",
            sm: 1
        },
        ml: {
            xs: "auto",
            sm: 1
        },
        color: "text.primary",
        textDecoration: "none"
    },
    subtitle: {
        fontSize: 15,
        mt: {
            xs: "auto",
            sm: 0
        },
        mr: {
            xs: "auto",
            sm: 1
        },
        ml: {
            xs: "auto",
            sm: 1
        },
        color: "text.secondary",
        textDecoration: "none"
    }
}

export default AppTitleWithLogoStyles
