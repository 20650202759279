const LanguageStyles = {
    icon: {
        color: "text.secondary",
        mr: 0.5
    },
    button: {
        color: "text.primary",
        fontSize: 12,
        fontWeight: "bold",
        ":hover": {
            cursor: "initial",
            textDecoration: "none"
        }
    }
}

export default LanguageStyles
