import { OpenIdConnect } from "@encoway/sales-showroom-auth"
import { PropsWithChildren } from "react"
import { ContentWithToken } from "./ContentWithToken"
import { PublicEnvironment } from "../../../../features/environment/environment.types"

type AuthorizedContentProps = PropsWithChildren<Pick<PublicEnvironment, "clientId" | "issuerUri" | "expectedIssuerUri">>

export default function AuthorizedContent({ clientId, issuerUri, expectedIssuerUri, children }: AuthorizedContentProps) {
    /**
     * Removes the state and scope parameter from the url. The ABB CIAM adds a state that is not expected.
     * As discussed with Sameer Nahar, this is by design and we should ignore the added state by the ABB CIAM.
     * @param props the props with children.
     */
    return (
        <OpenIdConnect
            clientId={clientId}
            realm=""
            authenticationServerUrl={issuerUri}
            expectedIssuerUrl={expectedIssuerUri || issuerUri}
            redirectUri={window.location.protocol + "//" + window.location.host + window.location.pathname}
        >
            <ContentWithToken>{children}</ContentWithToken>
        </OpenIdConnect>
    )
}
