import { ConfigurationTree } from "../ConfigurationTree/ConfigurationTree"
import React, { useState } from "react"
import { ConfigurationElement } from "../../types/configuration.types"
import { Grid, Stack } from "@mui/material"
import { ConfigurationDetails } from "../ConfigurationDetails/ConfigurationDetails"
import { useSelectedNode } from "./useSelectedNode"
import { useFilteredConfigurationState } from "../ConAnApp/useFilteredConfigurationState"
import { SearchField } from "./SearchField"

type ConfigurationBoardProps = {
    configurationState: ConfigurationElement
}

export const ConfigurationBoard = ({ configurationState }: ConfigurationBoardProps) => {
    const [searchString, setSearchString] = useState<string>("")
    const filteredConfigurationState = useFilteredConfigurationState(configurationState, searchString)
    const { selectedElement, onNodeSelect } = useSelectedNode(filteredConfigurationState)
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={3} overflow="hidden">
                    <Stack direction="row" style={{ padding: "8px", textAlign: "center" }}>
                        <SearchField searchString={searchString} setSearchString={setSearchString} />
                    </Stack>
                    {filteredConfigurationState ? (
                        <ConfigurationTree rootConfigurationElement={filteredConfigurationState} onSelectedItemsChange={onNodeSelect} />
                    ) : null}
                </Grid>
                <Grid item xs={9} overflow="hidden">
                    {selectedElement ? <ConfigurationDetails selectedElement={selectedElement} /> : null}
                </Grid>
            </Grid>
        </div>
    )
}
