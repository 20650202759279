import { CheckboxProps, NO_VALUE } from "@encoway/cui-configurator-components"
import AbbCheckbox from "../AbbCheckbox/AbbCheckbox"
import { MessageBar, MessageBarType } from "@fluentui/react"
import { AbbValueCheckboxStyles } from "./AbbValueCheckbox.styles"
import { adjustProps, getFalseValue, parameterIsSupported } from "./AbbValueCheckbox.utils"
import { ComponentFactory } from "@encoway/react-configurator"
import { ComponentName } from "@encoway/cui-configurator-components/src/constants"

export function AbbValueCheckbox(props: Readonly<CheckboxProps>) {
    return (
        <div>
            {ComponentFactory.instanceOf(ComponentName.Parameter, props)}
            {parameterIsSupported(props) ? (
                <AbbCheckbox
                    {...adjustProps(props)}
                    styles={AbbValueCheckboxStyles}
                    onValueChanged={(data, value, valueFormat) =>
                        props.onValueChanged(data, value === NO_VALUE ? getFalseValue(props)?.value : value, valueFormat)
                    }
                />
            ) : (
                <MessageBar messageBarType={MessageBarType.error}>
                    The valueCheckbox widget supports only characteristics with exactly two possible values. One of them must be "NOT" or "-".
                </MessageBar>
            )}
        </div>
    )
}
