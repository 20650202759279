import theme from "../../../App.theme"
import { DropdownMenuItem } from "../../dropdown/Dropdown"

const MenuButtonStyles = {
    button: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    item: (value: any, item: DropdownMenuItem) => ({
        color: item.value === value ? theme.palette.text.disabled : "inherit"
    })
}

export default MenuButtonStyles
