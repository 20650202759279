import { InputFieldProps } from "@encoway/cui-configurator-components"
import { Button } from "@mui/material"
import dimensioningApi from "../../../../../../../../features/dimensioning/dimensioning.api"
import DownloadFileService from "../../../../../../../../features/download/DownloadFileService"
import { Characteristics } from "../../../../../../../../features/catalog/catalog.constants"
import CuiUtils from "../../utils/CuiUtils"

/**
 * Just a simple button for debugging. WILL BE REMOVED AFTER CALCULATION HAS BEEN FINISHED. JUST FOR TESTING!!!
 */
export const AbbDimensioningMv = (props: InputFieldProps) => {
    const [triggerLazyDimensioningMvQuery, { isFetching }] = dimensioningApi.useLazyDimensioningMvQuery()
    const dimensioningFunction = CuiUtils.readViewportPropertyValue(
        props.data.viewPortProperties?.[Characteristics.MvDimensioningCalculationFunction.id] as string
    )
    const onTriggerDimensioning = async () => {
        const result = await triggerLazyDimensioningMvQuery(
            {
                configurationId: (props.options as any).appSettings.configuration.options.configurationId,
                dimensioningFunction: dimensioningFunction || Characteristics.MvDimensioningCalculationFunction.possibleValues.ELECTRICAL_DESIGN
            },
            false
        )
        if (result.data) {
            DownloadFileService.downloadFile(result.data, "MV_Dimensioning.json")
        } else {
            console.warn("no result data to download available")
        }
    }
    return (
        <Button variant="contained" size="large" onClick={onTriggerDimensioning} disabled={isFetching}>
            TEST MV DIMENSIONING
        </Button>
    )
}
