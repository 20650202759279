import { getTheme } from "@fluentui/react"
import { CSSProperties } from "react"

const theme = getTheme()

const AbbTabsFooterNextButtonStyles: CSSProperties = {
    backgroundColor: theme.palette.themePrimary,
    color: theme.palette.white,
    borderWidth: 0,
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20
}

export default AbbTabsFooterNextButtonStyles
