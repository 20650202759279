import { useCallback } from "react"
import { VisualizationApi } from "../visualization.api"
import useParams from "../../../router/hooks/useParams"
import useConfigurationProduct from "../../sales/hooks/useConfigurationProduct"
import useCuiAppSettings from "../../../pages/projectPage/components/projectConfiguration/hooks/useCuiAppSettings"

export function useLazyArQuery() {
    const [loadAr, result] = VisualizationApi.useLazyArQuery()
    const cuiAppSettings = useCuiAppSettings()
    const params = useParams()
    const product = useConfigurationProduct()

    const fetch = useCallback(
        () => loadAr({ product: product!, id: params.lineItemId, cuiAppSettings: cuiAppSettings! }),
        [cuiAppSettings, loadAr, params.lineItemId, product]
    )

    return { fetch, result }
}
