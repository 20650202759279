const AppStyles = {
    wrapper: {
        minHeight: "100%",
        height: "100%",
        width: "100%",
        pb: 2
    },
    modalProps: {
        styles: {
            main: {
                maxWidth: "90% !important"
            }
        }
    }
}

export default AppStyles
