import { getProductGroupData } from "./ProductGroupCard.utils"
import Image from "../../../../../components/image/Image"
import ABBLogo from "../../../../../assets/logo.svg"
import { ProductGroupCardStyles } from "./ProductGroupCard.styles"
import { Skeleton } from "@mui/material"
import { ProductGroupCardSize } from "./ProductGroupCard"

interface ProductGroupCardImageProps {
    data?: ReturnType<typeof getProductGroupData>
    disabled: boolean
    size: ProductGroupCardSize
}

export function ProductGroupCardImage({ data, disabled, size }: ProductGroupCardImageProps) {
    return data ? (
        <Image
            src={data.imageSrc}
            fallbackImageProps={{
                src: ABBLogo,
                alt: "image of " + data.name,
                sx: ProductGroupCardStyles.image(disabled, size)
            }}
        />
    ) : (
        <Skeleton variant="rounded" sx={ProductGroupCardStyles.image(disabled, size)} />
    )
}
