import { GridColDef } from "@mui/x-data-grid-pro"
import { ValueRenderer } from "./ValueRenderer"
import { IconsRenderer } from "./IconsRenderer"

export const COLUMNS: GridColDef[] = [
    {
        field: "",
        minWidth: 30,
        renderCell: IconsRenderer
    },
    {
        field: "name",
        minWidth: 300
    },
    {
        field: "value",
        minWidth: 300,
        renderCell: ValueRenderer
    },
    {
        field: "possibleValues",
        minWidth: 400,
        renderCell: ValueRenderer
    },
    {
        field: "displayUnit",
        minWidth: 100
    }
]
