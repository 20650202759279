import { ConfigurationElement } from "../../types/configuration.types"
import React, { useCallback, useState } from "react"
import { SimpleTreeView } from "@mui/x-tree-view"
import { ConfigurationTreeItem } from "../ConfigurationTreeItem/ConfigurationTreeItem"
import { Button, Stack } from "@mui/material"

type ConfigurationTreeProps = {
    rootConfigurationElement: ConfigurationElement
    onSelectedItemsChange: (event: React.SyntheticEvent, nodeId: string | null) => void
}

const getAllItemIds = (configurationElement: ConfigurationElement): string[] => {
    return configurationElement.children.reduce((result: string[], child) => result.concat(getAllItemIds(child)), []).concat(configurationElement.id)
}

export const ConfigurationTree = ({ rootConfigurationElement, onSelectedItemsChange }: ConfigurationTreeProps) => {
    const [expandedItems, setExpandedItems] = useState<string[]>([])
    const onItemExpansionToggle = useCallback((_event: React.SyntheticEvent, itemId: string, expand: boolean) => {
        setExpandedItems(prevState => (expand ? prevState.concat(itemId) : prevState.filter(id => id !== itemId)))
    }, [])
    const expandAll = useCallback(() => {
        setExpandedItems(getAllItemIds(rootConfigurationElement))
    }, [rootConfigurationElement])
    const collapseAll = useCallback(() => {
        setExpandedItems([])
    }, [])
    return (
        <Stack>
            <Stack direction="row" spacing={2} justifyContent="center" padding={2}>
                <Button variant="contained" color="secondary" onClick={expandAll}>
                    Expand all
                </Button>
                <Button variant="contained" color="secondary" onClick={collapseAll}>
                    Collapse all
                </Button>
            </Stack>
            <SimpleTreeView
                expandedItems={expandedItems}
                onItemExpansionToggle={onItemExpansionToggle}
                onSelectedItemsChange={onSelectedItemsChange}
                aria-label="file system navigator"
                style={{ userSelect: "none" }}
            >
                <ConfigurationTreeItem configurationElement={rootConfigurationElement} />
            </SimpleTreeView>
        </Stack>
    )
}
