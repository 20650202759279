import { DropdownMenuItem } from "../../components/dropdown/Dropdown"
import { SalesDocumentProperties, SalesDocumentsPaging } from "../../features/sales/sales.constants"
import TranslationKeys from "../../features/translations/TranslationKeys"
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid"

export const searchAllProjectColumnsDropdownMenuItem = { value: "ALL", text: TranslationKeys.pages.projects.searchDropdownSearchAllOption }
export const ProjectSearchColumns: DropdownMenuItem[] = [
    searchAllProjectColumnsDropdownMenuItem,
    { value: SalesDocumentProperties.QUOTE_ID, text: TranslationKeys.project.properties.id },
    { value: SalesDocumentProperties.DRIVESMART_ID, text: TranslationKeys.project.properties.driveSmart6Id },
    { value: SalesDocumentProperties.PROJECT_NAME, text: TranslationKeys.project.properties.projectName },
    { value: SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER, text: TranslationKeys.project.properties.salesforceOpportunityNumber },
    { value: SalesDocumentProperties.CUSTOMER, text: TranslationKeys.project.properties.customer },
    { value: SalesDocumentProperties.CREATED_BY, text: TranslationKeys.project.properties.createdBy },
    { value: SalesDocumentProperties.MODIFIED_BY, text: TranslationKeys.project.properties.modifiedBy },
    { value: SalesDocumentProperties.QUOTE_STATUS, text: TranslationKeys.project.properties.status }
]

export const initialGridSortModel: GridSortModel = [
    {
        field: SalesDocumentsPaging.DEFAULT_SORT_FIELD,
        sort: SalesDocumentsPaging.DEFAULT_SORT_DIRECTION
    } as const
]

export const initialGridPaginationModel: GridPaginationModel = {
    page: SalesDocumentsPaging.INITIAL_PAGE,
    pageSize: SalesDocumentsPaging.INITIAL_PAGE_SIZE
} as const
