import { Settings } from "../settings"
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import { RootState } from "./store.types"

export default function prepareHeaders(headers: Headers, { getState }: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">) {
    const token = (getState() as RootState).oidc.token
    if (token) headers.set("Authorization", `Bearer ${token}`)
    headers.set("Accept-Language", Settings.locale)
    return headers
}
