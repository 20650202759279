import { ProjectSearchColumns, searchAllProjectColumnsDropdownMenuItem } from "./ProjectsPage.constants"
import { L10n } from "@encoway/l10n"
import { SelectChangeEvent } from "@mui/material/Select"

export function getTranslatedProjectSearchColumns() {
    return ProjectSearchColumns.map(column => ({
        ...column,
        text: L10n.format(column.text)
    }))
}

export const searchColumnsReducer = (state: string[], event: SelectChangeEvent<string[]>) => {
    const value = event.target.value
    const allOption = searchAllProjectColumnsDropdownMenuItem.value
    if (typeof value === "string") {
        return value.split(",")
    }
    if (!state.includes(allOption) && value.includes(allOption)) {
        return [allOption]
    }
    return value.filter(option => option !== allOption)
}
