import { useMemo } from "react"
import { cloneDeep, merge } from "lodash"
import { defaultSettings, IAppSettings } from "@encoway/cui-application-components"
import { L10n } from "@encoway/l10n"
import { useAppSelector } from "../../../../../store/store"
import { useSettings } from "../../../../../hooks/useSettings"
import useConfigurationProduct from "../../../../../features/sales/hooks/useConfigurationProduct"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"
import { FetchApi } from "../../../../../features/fetch/fetch.api"

export default function useCuiAppSettings(): IAppSettings | undefined {
    const configurationId = useAppSelector(state => state.sales.configurationId)
    const articleName = useAppSelector(state => state.sales.configurationProductId)
    const readOnly = useAppSelector(state => state.sales.configurationReadOnly)
    const settings = useSettings()
    const visualizationSettings = useVisualizationSettings()
    return useMemo(
        () =>
            !configurationId || !visualizationSettings
                ? undefined
                : merge(cloneDeep(defaultSettings), settings, {
                      locale: L10n.currentFullLocale(),
                      visualization: { settings: visualizationSettings },
                      configuration: {
                          options: {
                              configurationId: configurationId,
                              articleName: articleName,
                              readOnly: readOnly
                          }
                      }
                  }),
        [configurationId, settings, visualizationSettings, articleName, readOnly]
    )
}

function useVisualizationSettings() {
    const configurationProduct = useConfigurationProduct()
    const mediaUri = configurationProduct ? CatalogUtils.getMediaUri(configurationProduct, Characteristics.VisualizationSettings.id) : undefined
    const productSettingsQuery = FetchApi.useFetchQuery(mediaUri ?? "", { skip: !mediaUri })
    return useMemo(
        () =>
            configurationProduct && (!mediaUri || productSettingsQuery.isSuccess || productSettingsQuery.isError)
                ? merge({}, defaultSettings.visualization?.settingsConfiguration, productSettingsQuery.data?.settings)
                : undefined,
        [configurationProduct, mediaUri, productSettingsQuery.data?.settings, productSettingsQuery.isError, productSettingsQuery.isSuccess]
    )
}
