import { CatalogService } from "@encoway/c-services-js-client"
import { queryFn, QueryFnOptions } from "../../store/queryFn"

type CatalogQueryFnOptions<Response, Arg> = Omit<QueryFnOptions<Response, Arg, CatalogService>, "serviceSelector">

export function catalogQueryFn<Response, Arg>(options: CatalogQueryFnOptions<Response, Arg>) {
    return queryFn<Response, Arg, CatalogService>({
        serviceSelector: state => state.catalog.catalogService,
        ...options
    })
}
