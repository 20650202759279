import { L10n } from "@encoway/l10n"
import { Stack } from "@mui/material"
import { DataGridProProps, GridActionsCellItem, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-pro"
import ProjectsDataGridColumns from "./ProjectsDataGridColumns"
import DataGrid from "../../../../components/dataGrid/DataGrid"
import Icon from "../../../../components/icons/Icon"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { AbbSalesDocumentEntity } from "../../../../features/sales/sales.types"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"
import SalesUtils from "../../../../features/sales/sales.utils"
import ProjectsDataGridStyles from "./ProjectsDataGrid.styles"
import AutoStoriesIcon from "@mui/icons-material/AutoStories"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import LockPersonIcon from "@mui/icons-material/LockPerson"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import EditIcon from "@mui/icons-material/Edit"

interface ProjectDataGridProps extends Omit<DataGridProProps, "columns"> {
    rows: AbbSalesDocumentEntity[]
    onSelectProject: (salesDocument: AbbSalesDocumentEntity, readOnly: boolean) => void
    onDeleteProject: (salesDocument: AbbSalesDocumentEntity) => void
    onCopyProject: (salesDocumentId: string) => any
}

export default function ProjectsDataGrid({ onSelectProject, onDeleteProject, onCopyProject, ...dataGridProps }: Readonly<ProjectDataGridProps>) {
    const renderLockCell = (params: GridRenderCellParams<AbbSalesDocumentEntity>) => {
        return params.row.properties.quote_locked_by ? <LockPersonIcon /> : null
    }

    const renderPermissionCell = (params: GridRenderCellParams<AbbSalesDocumentEntity>) => {
        return params.value.includes("UPDATE") ? <EditIcon /> : <AutoStoriesIcon />
    }

    const renderStatusCell = (params: GridRenderCellParams<AbbSalesDocumentEntity>) => {
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <Icon
                    component={SalesUtils.salesDocument.getStatusIcon(params.row.properties.quote_status)}
                    color={SalesUtils.salesDocument.getStatusColor(params.row.properties.quote_status)}
                />
                <span>{SalesUtils.salesDocument.getStatusTranslation(params.row.properties.quote_status)}</span>
            </Stack>
        )
    }

    const getActions = (params: GridRowParams<AbbSalesDocumentEntity>) => [
        <GridActionsCellItem
            key="editAction"
            icon={<ModeEditIcon />}
            label={L10n.format(TranslationKeys.project.actions.open)}
            onClick={() => onSelectProject(params.row, false)}
            disabled={!params.row.permission.includes("UPDATE")}
            showInMenu
        />,
        <GridActionsCellItem
            key="readAction"
            icon={<AutoStoriesIcon />}
            label={L10n.format(TranslationKeys.project.actions.read)}
            onClick={() => onSelectProject(params.row, true)}
            showInMenu
        />,
        <GridActionsCellItem
            key="copyAction"
            icon={<ContentCopyIcon />}
            label={L10n.format(TranslationKeys.project.actions.duplicate)}
            onClick={() => onCopyProject(params.row.salesDocumentId)}
            disabled={!params.row.permission.includes("UPDATE")}
            showInMenu
        />,
        <GridActionsCellItem
            key="deleteAction"
            icon={<DeleteIcon />}
            label={L10n.format(TranslationKeys.project.actions.delete)}
            onClick={() => onDeleteProject(params.row)}
            disabled={!params.row.permission.includes("DELETE")}
            showInMenu
        />
    ]

    return (
        <DataGrid
            getRowId={params => params.salesDocumentId}
            columns={ProjectsDataGridColumns(renderLockCell, renderPermissionCell, renderStatusCell, getActions)}
            filterMode="server"
            paginationMode="server"
            sx={ProjectsDataGridStyles}
            sortingMode="server"
            disableRowSelectionOnClick
            onRowClick={params => onSelectProject(params.row, false)}
            localeText={{
                noRowsLabel: L10n.format(TranslationKeys.pages.projects.noProjectsFound),
                noResultsOverlayLabel: L10n.format(TranslationKeys.pages.projects.noProjectResults)
            }}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        [SalesDocumentProperties.CREATED_AT]: false,
                        [SalesDocumentProperties.MODIFIED_BY]: false,
                        [SalesDocumentProperties.DRIVESMART_ID]: false
                    }
                }
            }}
            {...dataGridProps}
        />
    )
}
