import { Parameter, ParameterProps } from "@encoway/cui-configurator-components"
import { ParameterTO } from "@encoway/c-services-js-client"
import { IRenderFunction } from "@fluentui/react"
import { AbbParameterLabel } from "./AbbParameterLabel"

type AbbParameterProps = ParameterProps & { hideParameter: boolean }

const AbbParameter = (props: AbbParameterProps) => {
    const renderLabel: IRenderFunction<ParameterTO> = data => {
        return data ? <AbbParameterLabel {...data} /> : null
    }
    if (props.hideParameter) {
        return null
    } else {
        return <Parameter {...props} onRenderLabel={renderLabel} />
    }
}

export default AbbParameter
