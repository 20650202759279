import LineItemsUtils from "./utils/LineItemsUtils"
import SalesDocumentUtils from "./utils/SalesDocumentUtils"
import SalesDocumentsUtils from "./utils/SalesDocumentsUtils"
import SalesConfigurationUtils from "./utils/SalesConfigurationUtils"

export default class SalesUtils {
    static salesDocument = SalesDocumentUtils
    static salesDocuments = SalesDocumentsUtils
    static lineItems = LineItemsUtils
    static configuration = SalesConfigurationUtils
}
