import { Stack } from "@mui/material"
import useProjectForms from "../../hooks/useProjectForms/useProjectForms"
import ProjectForm from "./projectForm/ProjectForm"

export default function ProjectDetails() {
    const forms = useProjectForms()
    return (
        <Stack spacing={3}>
            {forms.map(form => (
                <ProjectForm key={form.title} {...form} />
            ))}
        </Stack>
    )
}
