import { Stack, StackProps } from "@mui/material"
import { Tab } from "../Tabs"

interface TabLabelProps extends StackProps {
    tab?: Tab
}

export default function TabLabel({ tab, ...stackProps }: TabLabelProps) {
    return (
        <Stack alignItems="center" direction="row" spacing={0.5} {...stackProps}>
            {tab ? (
                <>
                    {tab.icon}
                    <span data-cy={tab.cypressId}>{tab.label}</span>
                </>
            ) : null}
        </Stack>
    )
}
