const DialogActionsStyles = {
    actions: {
        padding: 2,
        display: "flex",
        columnGap: 2,
        flexDirection: "row"
    }
}

export default DialogActionsStyles
