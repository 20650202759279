import { ContainerTO, walkParameters } from "@encoway/c-services-js-client"
import CuiUtils from "../../utils/CuiUtils"
import InvisibleCuiContainers from "../../constants/InvisibleCuiContainers"
import { ComponentName } from "@encoway/cui-configurator-components"

export function getFilteredData(rootContainer: ContainerTO) {
    return {
        ...rootContainer,
        children: getFilteredChildren(rootContainer)
    }
}

function getFilteredChildren(container: ContainerTO): ContainerTO[] {
    return container.children.filter(child => !containerShouldBeHidden(child)).map(child => ({ ...child, children: getFilteredChildren(child) }))
}

function containerShouldBeHidden(container: ContainerTO) {
    return isInvisibleContainer(container) || isEmptyContainer(container)
}

function isInvisibleContainer(container: ContainerTO) {
    return InvisibleCuiContainers.some(namePrefix => container.name.startsWith(namePrefix))
}

function isEmptyContainer(container: ContainerTO) {
    return !hasVisibleParameters(container) && !hasVisibleContainers(container)
}

function hasVisibleContainers(container: ContainerTO) {
    return CuiUtils.walkContainers(
        container,
        c => !isInvisibleContainer(c) && c.viewPort !== ComponentName.Hidden,
        c => c
    ).some(hasVisibleParameters)
}

function hasVisibleParameters(container: ContainerTO) {
    return (
        walkParameters(
            container,
            p => p.viewPort !== ComponentName.Hidden,
            p => p
        ).length > 0
    )
}
