import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import getBasename from "../../router/utils/getBasename"
import CatalogUtils from "../catalog/catalog.utils"
import { Characteristics } from "../catalog/catalog.constants"
import { FetchApi } from "../fetch/fetch.api"
import { L10n } from "@encoway/l10n"
import { Settings } from "../../settings"
import BusySlice from "../busy/busy.slice"

export const TranslationsApi = createApi({
    reducerPath: "translationsApi",
    baseQuery: fakeBaseQuery<Error>(),
    endpoints: builder => ({
        translations: builder.query<object, string>({
            queryFn: async (language, api) => {
                api.dispatch(BusySlice.actions.setBusy())
                try {
                    const contentBlockQuery = api.dispatch(
                        FetchApi.endpoints?.fetch.initiate({
                            url: getBasename() + "/api/catalog/products/application_texts",
                            headers: { "Accept-Language": language }
                        })
                    )
                    contentBlockQuery.unsubscribe()
                    const response = await contentBlockQuery.unwrap()

                    const mediaQuery = api.dispatch(
                        FetchApi.endpoints?.fetch.initiate({
                            url: CatalogUtils.getMediaUri(response.product, Characteristics.Translations.id)!,
                            headers: { "Accept-Language": language }
                        })
                    )
                    mediaQuery.unsubscribe()
                    const translations = await mediaQuery.unwrap()

                    L10n.currentLocale(language)
                    L10n.source("ui", { [language]: flattenTranslationObject(translations) }, true)
                    await L10n.reloadResources(language)

                    const catalogService = (api.getState() as any).catalog.catalogService
                    if (catalogService) {
                        catalogService.useLanguage(language)
                    }

                    Settings.locale = language

                    return { data: translations }
                } finally {
                    api.dispatch(BusySlice.actions.setIdle())
                }
            }
        })
    })
})

function flattenTranslationObject(translationObject: Object) {
    const result: Record<string, string> = {}
    transformTranslationObject("", translationObject, result)
    return result
}

function transformTranslationObject(objKey: string, translationObject: Object | string, objectTo: Record<string, string>) {
    Object.entries(translationObject).forEach(([key, value]) => {
        if (typeof value === "string") {
            objectTo[objKey + key] = value
        } else if (value !== null && typeof value === "object") {
            transformTranslationObject(objKey + key + ".", value, objectTo)
        }
    })
}
