import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import DataGrid from "../../../../../components/dataGrid/DataGrid"
import Row from "./components/row/Row"
import { createContext, useEffect, useMemo, useState } from "react"
import lineItemsDataGridColumns from "./constants/lineItemsDataGridColumns"
import { GridCellEditStopParams, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-pro"
import LineItemsDataGridStyles from "./LineItemsDataGrid.styles"
import { AbbLineItem, AbbLineItemWithParent } from "../../../../../features/sales/sales.types"
import { LineItemProperties } from "../../../../../features/sales/sales.constants"
import SalesApi from "../../../../../features/sales/sales.api"
import { filterColumn, getFooterRow, getRowClassName, getRows, isCellEditable, mapColumn } from "./LineItemDataGrid.utils"
import { useAppSelector } from "../../../../../store/store"
import { useUserInformation } from "../../../../../features/oidc/hooks/useUserInformation"
import { cloneDeep } from "lodash"
import { ProjectCompositionView } from "../constants/ProjectCompositionView"

export interface BomNode extends AbbLineItemWithParent {
    expanded: boolean
    level: number
    parent?: BomNode
    children?: BomNode[]
}

interface LineItemDataGridProps {
    onConfigure: (lineItem: AbbLineItem, readOnly?: boolean) => void
    onDelete: (lineItem: AbbLineItem) => void
    onGenerateDocument: (lineItem: AbbLineItem) => void
    projectCompositionView: string
}

export const LineItemDataGridContext = createContext<LineItemDataGridProps | undefined>(undefined)

export default function LineItemsDataGrid(props: Readonly<LineItemDataGridProps>) {
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const composition = SalesApi.useCompositionQuery().data
    const [updateLineItem] = SalesApi.useUpdateLineItemMutation()
    const [updateSalesDocument] = SalesApi.useUpdateSalesDocumentMutation()
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({})
    const userInformation = useUserInformation()

    useEffect(() => {
        setColumnVisibilityModel({
            [LineItemProperties.ABB_LINE_ITEM_ID]: false,
            [LineItemProperties.CONFIGURATION_NAME]: false,
            [LineItemProperties.CREATED_BY]: false,
            [LineItemProperties.CREATED_AT]: false,
            [LineItemProperties.MODIFIED_AT]: false,
            [LineItemProperties.MODIFIED_BY]: false,
            [LineItemProperties.ARTICLE_ID]: props.projectCompositionView === ProjectCompositionView.PROJECT_VIEW,
            [LineItemProperties.PLAIN_LIST_PRICE]:
                props.projectCompositionView === ProjectCompositionView.CALCULATION_VIEW ||
                props.projectCompositionView === ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW,
            [LineItemProperties.PLAIN_LIST_PRICE_WITH_CURRENCY]:
                props.projectCompositionView === ProjectCompositionView.CALCULATION_VIEW ||
                props.projectCompositionView === ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW,
            [LineItemProperties.TRANSFER_PRICE_DISCOUNT_PERCENTAGE]: props.projectCompositionView === ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW,
            [LineItemProperties.TRANSFER_PRICE_DISCOUNTED_UNIT_PRICE]: props.projectCompositionView === ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW,
            [LineItemProperties.TRANSFER_PRICE_DISCOUNT_ACCUMULATED_PERCENTAGE]:
                props.projectCompositionView === ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW,
            [LineItemProperties.TRANSFER_PRICE_DISCOUNTED_TOTAL_PRICE]: props.projectCompositionView === ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW,
            [LineItemProperties.MULTIPLIER]: props.projectCompositionView === ProjectCompositionView.CALCULATION_VIEW,
            [LineItemProperties.PRICEGROUP]: props.projectCompositionView === ProjectCompositionView.CALCULATION_VIEW,
            [LineItemProperties.TOTAL_LIST_PRICE]:
                props.projectCompositionView === ProjectCompositionView.CALCULATION_VIEW ||
                props.projectCompositionView === ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW,
            [LineItemProperties.PLAIN_COST]: props.projectCompositionView === ProjectCompositionView.COST_VIEW,
            [LineItemProperties.PLAIN_COST_WITH_CURRENCY]: props.projectCompositionView === ProjectCompositionView.COST_VIEW,
            [LineItemProperties.TOTAL_COST]: props.projectCompositionView === ProjectCompositionView.COST_VIEW,
            [LineItemProperties.GROSS_MARGIN]:
                props.projectCompositionView === ProjectCompositionView.COST_VIEW ||
                props.projectCompositionView === ProjectCompositionView.TRANSFER_PRICE_DISCOUNT_VIEW
        })
    }, [props.projectCompositionView])

    const rows = useMemo(() => getRows(cloneDeep(composition)), [composition])

    const columns: GridColDef[] = useMemo(
        () =>
            lineItemsDataGridColumns()
                .filter(column => filterColumn(column, userInformation))
                .map(column => mapColumn(column, readOnly)),
        [userInformation, readOnly]
    )

    const onCellEditStop = (params: GridCellEditStopParams<BomNode>) => {
        // NOTE: Workaround for PSU-5531 to transform no user input to the value -999 to be handled in the advanced calculation plugin
        if (params.field === LineItemProperties.TRANSFER_PRICE_DISCOUNT_PERCENTAGE && params.value === null) {
            if (params.row.lineItem.isSalesDocument) {
                updateSalesDocument([{}, { [params.field]: params.value }])
            } else {
                updateLineItem([params.row.lineItem.lineItemId, { [LineItemProperties.TRANSFER_PRICE_DISCOUNT_PERCENTAGE]: -999 }])
            }
        } else if (params.row.lineItem.isSalesDocument) {
            updateSalesDocument([{}, { [params.field]: params.value }])
        } else {
            updateLineItem([params.row.lineItem.lineItemId, { [params.field]: params.value }])
        }
    }

    return (
        <LineItemDataGridContext.Provider value={props}>
            <DataGrid
                rows={rows}
                pinnedRows={{ bottom: [getFooterRow(salesDocument, userInformation)] }}
                getRowId={row => row.lineItem.lineItemId}
                getRowClassName={getRowClassName}
                rowHeight={40}
                sx={LineItemsDataGridStyles}
                columns={columns}
                hideFooter
                disableRowSelectionOnClick
                isCellEditable={isCellEditable}
                onCellEditStop={onCellEditStop}
                slots={{ row: Row }}
                autoHeight={false}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={setColumnVisibilityModel}
                localeText={{
                    noRowsLabel: L10n.format(TranslationKeys.pages.project.composition.noLineItemsFound),
                    noResultsOverlayLabel: L10n.format(TranslationKeys.pages.project.composition.noLineItemResults)
                }}
            />
        </LineItemDataGridContext.Provider>
    )
}
