import { useEffect, useState } from "react"
import { Backdrop, Stack, Typography } from "@mui/material"
import AbbSpinner from "./AbbSpinner"
import theme from "../../../../../../../../App.theme"
import { useAppSelector } from "../../../../../../../../store/store"

const AbbOverlaySpinner = () => {
    const busyTexts = useAppSelector(state => state.busy.texts)
    const [shouldRender, setShouldRender] = useState<boolean>(false)
    const [blurredElement, setBlurredElement] = useState<HTMLElement | null>(null)

    useEffect(() => {
        const timeout = setTimeout(() => setShouldRender(true), 400)
        return () => clearTimeout(timeout)
    }, [])

    useEffect(() => {
        if (shouldRender && !blurredElement) {
            const element = document.activeElement as HTMLElement | null
            setBlurredElement(element)
            element?.blur()
        }
    }, [shouldRender, blurredElement])

    useEffect(() => {
        return () => {
            blurredElement?.focus()
        }
    }, [blurredElement])

    return shouldRender ? (
        <Backdrop component={Stack} open sx={{ zIndex: theme.zIndex.tooltip + 1, backgroundColor: "rgba(255, 255, 255, 0.3)" }}>
            <AbbSpinner />
            {busyTexts.map(text => (
                <Typography key={text} color="primary">
                    {text}
                </Typography>
            ))}
        </Backdrop>
    ) : (
        <Backdrop open sx={{ zIndex: theme.zIndex.tooltip + 1, backgroundColor: "rgba(255, 255, 255, 0)" }} />
    )
}

export default AbbOverlaySpinner
