import { Breadcrumb } from "../../../../components/breadcrumbs/Breadcrumbs"
import { generatePath } from "react-router-dom"
import RouterPaths from "../../../../router/constants/RouterPaths"
import { Aggregation, Characteristics as CharacteristicsFilter, Filter, ProductGroup, Search, Selection } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { Characteristics, ViewIds } from "../../../../features/catalog/catalog.constants"

export function getProductSelection(
    productGroupId: string,
    characteristicFilters: Record<string, any>,
    includeSubGroups: boolean,
    searchValue?: string,
    characteristicViewId?: string
) {
    const filter = Filter.boolFilter().must(Filter.productGroupFilter().id(productGroupId).includeSubgroups(includeSubGroups))
    Object.entries(characteristicFilters).forEach(([characteristicId, value]) => filter.must(Filter.characteristicFilter().id(characteristicId).equal(value)))

    const selection = new Selection()
        .limit(1000)
        .filter(filter)
        .characteristics(characteristicViewId ? new CharacteristicsFilter().viewId(characteristicViewId) : new CharacteristicsFilter().all())
        .aggregation(new Aggregation().viewId(ViewIds.SALES_FILTER))

    if (searchValue) selection.search(new Search().term(`*${searchValue}*`).idSearch(true).characteristic([Characteristics.ShortText.id]))

    return selection
}

export function getBreadcrumbs(productGroups: ProductGroup[], search: boolean, salesDocumentId: string) {
    return productGroups
        .map((_group, index) => getBreadcrumb(productGroups, index, salesDocumentId))
        .concat(search ? { label: L10n.format(TranslationKeys.pages.project.catalog.searchResultBreadcrumb) } : [])
}

function getBreadcrumb(productGroups: ProductGroup[], index: number, salesDocumentId: string): Breadcrumb {
    const productGroupIds = productGroups
        .slice(0, index + 1)
        .map(g => g.id)
        .join("+")
    const route = generatePath(RouterPaths.PROJECT_CATALOG, { salesDocumentId, productGroupIds })
    return { label: productGroups[index].name!, route }
}
