import { getTheme } from "@fluentui/react"
import { IInputFieldStyles } from "@encoway/cui-configurator-components/src/components/InputField/InputField.types"
import { ParameterTO } from "@encoway/c-services-js-client"
import CuiStyleConstants from "../../constants/CuiStyleConstants"

const theme = getTheme()

const AbbInputFieldStyles = (parameterTO: ParameterTO): IInputFieldStyles => {
    return {
        enabled: {
            selectors: {
                ".ms-TextField-suffix": {
                    borderLeft: parameterTO.displayUnit ? "solid " + CuiStyleConstants.FIELD_BORDER_WIDTH + "px" + CuiStyleConstants.FIELD_BORDER_COLOR : "",
                    borderRadius: parameterTO.displayUnit ? 2 : 0,
                    backgroundColor: parameterTO.displayUnit ? theme.palette.themeLighter : "transparent"
                }
            }
        },
        textField: {
            selectors: {
                ".ms-TextField-fieldGroup": {
                    borderWidth: CuiStyleConstants.FIELD_BORDER_WIDTH,
                    borderRadius: CuiStyleConstants.FIELD_BORDER_RADIUS
                }
            }
        },
        notReady: {
            selectors: {
                ".ms-TextField-fieldGroup": {
                    borderColor: theme.palette.themePrimary
                }
            }
        }
    }
}

export default AbbInputFieldStyles
