import { Button } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../translations/TranslationKeys"
import { CreateLineupButtonDialog } from "./CreateLineupButton.Dialog"
import useDialog from "../../../../components/dialog/useDialog"
import { ButtonProps } from "@mui/material/Button"

export function CreateLineupButton(props: Readonly<Omit<ButtonProps, "onClick" | "children">>) {
    const dialog = useDialog()
    return (
        <>
            <Button {...props} onClick={dialog.open}>
                {L10n.format(TranslationKeys.lineItem.lineup.create.button.label)}
            </Button>
            {dialog.isOpen && <CreateLineupButtonDialog onClose={dialog.close} />}
        </>
    )
}
