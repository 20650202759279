import { DropTargetMonitor, XYCoord } from "react-dnd"
import { RefObject } from "react"

export type DragAndDropHoverPosition = "top" | "center" | "bottom" | undefined

/**
 * Gets the hover position from the given dnd monitor and ref to the element.
 * @param monitor the dnd monitor.
 * @param ref the ref of the html element.
 */
export const getDragAndDropHoverPosition = (monitor: DropTargetMonitor, ref: RefObject<HTMLElement>): "top" | "bottom" | "center" | undefined => {
    const rect = ref.current?.getBoundingClientRect()
    const clientOffset = monitor.getClientOffset()
    return rect && clientOffset ? getHoverPosition(clientOffset, rect) : undefined
}

const getHoverPosition = (clientOffset: XYCoord, elementRect: DOMRect): DragAndDropHoverPosition => {
    if (clientOffset.y >= elementRect.y && clientOffset.y <= elementRect.y + elementRect.height) {
        if (clientOffset.y < elementRect.y + elementRect.height / 4) {
            return "top"
        } else if (clientOffset.y > elementRect.y + elementRect.height - elementRect.height / 4) {
            return "bottom"
        } else {
            return "center"
        }
    }
}
