import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ContainerTO } from "@encoway/c-services-js-client"
import { SalesService } from "@encoway/sales-api-js-client"
import SalesApi from "./sales.api"
import SalesUtils from "./sales.utils"

export interface SalesState {
    salesService?: SalesService
    salesDocumentIsSaved: boolean
    expandedLineItems: string[]
    lineItemsToDelete?: string[]
    configurationIsSaved: boolean
    configurationId?: string
    configurationContainer?: ContainerTO
    configurationProductId?: string
    salesDocumentReadOnly: boolean
    configurationReadOnly: boolean
    configurationTreeIsAvailable: boolean
    visualizationIsAvailable: boolean
    hideConfigurationTree: boolean
    hideVisualization: boolean
    hideConfiguration: boolean
}

const initialState: SalesState = {
    salesDocumentIsSaved: true,
    expandedLineItems: [],
    configurationIsSaved: true,
    salesDocumentReadOnly: false,
    configurationReadOnly: false,
    configurationTreeIsAvailable: false,
    visualizationIsAvailable: false,
    hideConfigurationTree: false,
    hideVisualization: false,
    hideConfiguration: false
}

export const SalesSlice = createSlice({
    name: "salesSlice",
    initialState,
    reducers: {
        setSalesService: (state, action: PayloadAction<SalesService>) => {
            state.salesService = action.payload
        },

        setLineItemsToDelete: (state, action: PayloadAction<string[] | undefined>) => {
            state.lineItemsToDelete = action.payload
        },

        setConfigurationContainer: (state, action: PayloadAction<ContainerTO | undefined>) => {
            state.configurationContainer = action.payload
        },

        setConfigurationIsSavedToFalse: state => {
            state.configurationIsSaved = false
        },

        setSalesDocumentReadOnly: (state, action: PayloadAction<boolean>) => {
            state.salesDocumentReadOnly = action.payload
            state.configurationReadOnly = action.payload
        },

        setConfigurationReadOnly: (state, action: PayloadAction<boolean>) => {
            state.configurationReadOnly = action.payload
        },

        setConfigurationTreeIsAvailable: (state, action: PayloadAction<boolean>) => {
            state.configurationTreeIsAvailable = action.payload
        },

        setVisualizationIsAvailable: (state, action: PayloadAction<boolean>) => {
            state.visualizationIsAvailable = action.payload
        },

        changeConfigurationLayout: (
            state,
            action: PayloadAction<Partial<Pick<SalesState, "hideConfigurationTree" | "hideVisualization" | "hideConfiguration">>>
        ) => {
            state.hideConfigurationTree = action.payload.hideConfigurationTree ?? state.hideConfigurationTree
            state.hideVisualization = action.payload.hideVisualization ?? state.hideVisualization
            state.hideConfiguration = action.payload.hideConfiguration ?? state.hideConfiguration
        }
    },
    extraReducers: builder => {
        builder.addMatcher(SalesApi.endpoints.salesDocument.matchFulfilled, (state, action) => {
            if (!action.payload.permission.includes("UPDATE")) {
                state.salesDocumentReadOnly = true
                state.configurationReadOnly = true
            }
        })

        builder.addMatcher(SalesApi.endpoints.expandLineItem.matchFulfilled, (state, action) => {
            state.expandedLineItems = Array.from(new Set(state.expandedLineItems.concat(action.meta.arg.originalArgs)))
        })

        builder.addMatcher(SalesApi.endpoints.expandAllLineItems.matchFulfilled, (state, action) => {
            state.expandedLineItems = action.payload
        })

        builder.addMatcher(SalesApi.endpoints.collapseLineItem.matchFulfilled, (state, action) => {
            state.expandedLineItems = state.expandedLineItems.filter(id => !action.payload.includes(id))
        })

        builder.addMatcher(SalesApi.endpoints.collapseAllLineItems.matchFulfilled, state => {
            state.expandedLineItems = []
        })

        builder.addMatcher(SalesApi.endpoints?.addItems.matchFulfilled, state => {
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.addFolder.matchFulfilled, state => {
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.createLineup.matchFulfilled, state => {
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.addCustomLineItem.matchFulfilled, state => {
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.moveLineItems.matchFulfilled, state => {
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.updateLineItem.matchFulfilled, state => {
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.duplicateLineItems.matchFulfilled, state => {
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.deleteLineItems.matchRejected, state => {
            state.lineItemsToDelete = undefined
        })

        builder.addMatcher(SalesApi.endpoints.deleteLineItems.matchFulfilled, (state, action) => {
            state.salesDocumentIsSaved = false
            state.lineItemsToDelete = undefined
            state.expandedLineItems = state.expandedLineItems.filter(id => !action.payload.removeLineItemIds.includes(id))
        })

        builder.addMatcher(SalesApi.endpoints.createConfiguration.matchFulfilled, (state, action) => {
            const response = SalesUtils.configuration.readConfigurationResponse(action.payload)
            state.configurationId = response.configurationId
            state.configurationProductId = response.product
            state.configurationIsSaved = false
            state.configurationTreeIsAvailable = false
            state.visualizationIsAvailable = false
            state.hideConfigurationTree = false
            state.hideConfiguration = false
            state.hideVisualization = false
        })

        builder.addMatcher(SalesApi.endpoints.openConfiguration.matchFulfilled, (state, action) => {
            const response = SalesUtils.configuration.readConfigurationResponse(action.payload)
            state.configurationId = response.configurationId
            state.configurationProductId = response.product
            state.configurationIsSaved = true
            state.configurationTreeIsAvailable = false
            state.visualizationIsAvailable = false
            state.hideConfigurationTree = false
            state.hideConfiguration = false
            state.hideVisualization = false
        })

        builder.addMatcher(SalesApi.endpoints.viewConfiguration.matchFulfilled, (state, action) => {
            const response = SalesUtils.configuration.readConfigurationResponse(action.payload)
            state.configurationId = response.configurationId
            state.configurationProductId = response.product
            state.configurationIsSaved = true
            state.configurationTreeIsAvailable = false
            state.visualizationIsAvailable = false
            state.hideConfigurationTree = false
            state.hideConfiguration = false
            state.hideVisualization = false
        })

        builder.addMatcher(SalesApi.endpoints.stopConfiguration.matchFulfilled, state => {
            state.configurationId = undefined
            state.configurationProductId = undefined
            state.configurationIsSaved = true
            state.configurationReadOnly = state.salesDocumentReadOnly
            state.configurationTreeIsAvailable = false
            state.visualizationIsAvailable = false
            state.hideConfigurationTree = false
            state.hideVisualization = false
            state.hideConfiguration = false
        })

        builder.addMatcher(SalesApi.endpoints.saveConfiguration.matchFulfilled, state => {
            state.configurationIsSaved = true
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.addConfiguration.matchFulfilled, state => {
            state.configurationIsSaved = true
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.openSalesDocument.matchFulfilled, state => {
            state.salesDocumentIsSaved = true
            state.expandedLineItems = []
        })

        builder.addMatcher(SalesApi.endpoints.closeSalesDocument.matchFulfilled, state => {
            state.salesDocumentIsSaved = true
            state.salesDocumentReadOnly = false
            state.configurationReadOnly = false
        })

        builder.addMatcher(SalesApi.endpoints.updateSalesDocument.matchFulfilled, state => {
            state.salesDocumentIsSaved = false
        })

        builder.addMatcher(SalesApi.endpoints.saveSalesDocument.matchFulfilled, state => {
            state.salesDocumentIsSaved = true
        })

        builder.addMatcher(SalesApi.endpoints.setSalesDocumentStatus.matchFulfilled, state => {
            state.salesDocumentIsSaved = true
        })
    }
})
