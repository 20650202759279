import { Button } from "@mui/material"
import { GridRowClassNameParams } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"
import { AbbDimensioningDcsColumns } from "./AbbDimensioningDcsColumns"
import { getErrorMessage, getIdsOfBestDcDimensioningResults, getIdsOfEnabledDcDimensioningResults, mapDimensioningDcColumns } from "../AbbDimensioningDc.utils"
import { DcsDimensioningResultEntry } from "../../../../../../../../../features/dimensioning/dimensioning.types"
import dimensioningApi from "../../../../../../../../../features/dimensioning/dimensioning.api"
import { CatalogApi } from "../../../../../../../../../features/catalog/catalog.api"
import { Characteristics } from "../../../../../../../../../features/catalog/catalog.constants"
import TranslationKeys from "../../../../../../../../../features/translations/TranslationKeys"
import DataGrid from "../../../../../../../../../components/dataGrid/DataGrid"
import { AbbDimensioningDataGridProps } from "../AbbDimensioningDc"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"

export function AbbDimensioningDcsDataGrid({ configurationId, parameterId, renderProductNameCell, ...dataGridProps }: Readonly<AbbDimensioningDataGridProps>) {
    const dimensioningDcsQuery = dimensioningApi.useDimensioningDcsQuery({ configurationId, parameterId })

    const characteristics = [
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleFrameSize.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleDimension.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcThyristorMaxTemp.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcProductName.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcThyristorTemp.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcCurrent.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcMargin.id).data
    ]

    const highlighted = getIdsOfBestDcDimensioningResults(dimensioningDcsQuery.data)
    const enabled = getIdsOfEnabledDcDimensioningResults(dimensioningDcsQuery.data)

    const getRowClassName = (params: GridRowClassNameParams<DcsDimensioningResultEntry>) => (highlighted.includes(params.row.id) ? "highlighted" : "")

    const error = dimensioningDcsQuery.error as FetchBaseQueryError
    const errorMessage = getErrorMessage(error)

    return (
        <>
            <Button variant="contained" size="large" onClick={dimensioningDcsQuery.refetch}>
                {L10n.format(TranslationKeys.pages.project.configuration.dimensioningButtonLabel)}
            </Button>
            <DataGrid
                {...dataGridProps}
                columns={mapDimensioningDcColumns(AbbDimensioningDcsColumns, characteristics, renderProductNameCell)}
                rows={dimensioningDcsQuery.data?.filter(r => enabled.includes(r.id)) ?? []}
                getRowClassName={getRowClassName}
                loading={dimensioningDcsQuery.isFetching}
                slotProps={{ noRowsOverlay: { error: errorMessage, onRetry: dimensioningDcsQuery.refetch } }}
            />
        </>
    )
}
