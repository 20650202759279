import React from "react"
import { Box } from "@mui/material"
import { TableStyles } from "./Table.styles"
import { InputFieldProps } from "@encoway/cui-configurator-components"
import { ContainerTO } from "@encoway/c-services-js-client"
import { ComponentFactory } from "@encoway/react-configurator"
import CuiUtils from "../../utils/CuiUtils"

type ColumnHeaderProps = {
    column: ContainerTO
    widgetProps: InputFieldProps
}

const ColumnHeader = ({ column, widgetProps }: ColumnHeaderProps) => {
    return (
        <>
            {CuiUtils.hasParameters(column) ? (
                column.parameters.map(parameterTO => (
                    <Box key={parameterTO.id} component="td" sx={TableStyles.td}>
                        {ComponentFactory.instanceOf(parameterTO.viewPort!, { ...widgetProps, data: parameterTO, hideParameter: true })}
                    </Box>
                ))
            ) : (
                <Box key={column.id} component="th" sx={TableStyles.th}>
                    {column.translatedName}
                </Box>
            )}
        </>
    )
}

export default ColumnHeader
