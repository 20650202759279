import jwt_decode from "jwt-decode"
import { useAppSelector } from "../../../store/store"
import { useMemo } from "react"
import { EnvironmentApi } from "../../environment/environment.api"

export type UserInformation = {
    name?: string
    username?: string
    roles?: string[]
}

export const useUserInformation = (): UserInformation | undefined => {
    const token = useAppSelector(state => state.oidc.token)
    const environment = EnvironmentApi.useEnvironmentQuery(undefined, { skip: !token }).data
    return useMemo((): UserInformation | undefined => {
        if (token) {
            const jwtPayload: any = jwt_decode(token)
            return {
                username: jwtPayload.username || jwtPayload.preferred_username,
                name: jwtPayload.name,
                roles: environment?.roles
            }
        }
    }, [token, environment])
}
