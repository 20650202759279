import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import MobileMenuStyles from "./MobileMenu.styles"
import { MouseEvent, useState } from "react"
import MenuItem from "@mui/material/MenuItem"
import { NavLink } from "react-router-dom"

interface MenuEntry {
    label: string
    route: string
}

interface MobileMenuProps {
    menuEntries: MenuEntry[]
}

export default function MobileMenu({ menuEntries }: MobileMenuProps) {
    const [anchorElNav, setAnchorElNav] = useState<HTMLButtonElement | undefined>()

    const handleOpenNavMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(undefined)
    }

    return (
        <Box sx={MobileMenuStyles.wrapper}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={MobileMenuStyles.menu}
            >
                {menuEntries.map(entry => (
                    <MenuItem component={NavLink} key={entry.label} to={entry.route} sx={MobileMenuStyles.menuItem} onClick={handleCloseNavMenu}>
                        {entry.label}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}
