import theme from "../../../../App.theme"

const RecentProjectsWidgetStyles = {
    card: {
        width: 250,
        height: 250,
        justifyContent: "space-between",
        cursor: "pointer"
    },
    button: {
        height: 60,
        padding: "0 30px"
    },
    buttonIcon: {
        transform: "scale(1.5)"
    },
    viewAllCard: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        width: 250,
        height: 250,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    }
}

export default RecentProjectsWidgetStyles
