import { getTheme } from "@fluentui/react"
import { IFilterDetailDropdownStyles } from "./AbbFilterDetailDropdown.types"
import CuiStyleConstants from "../../constants/CuiStyleConstants"

const theme = getTheme()

const AbbFilterDetailDropdownStyles: IFilterDetailDropdownStyles = {
    dropdown: {
        selectors: {
            ".ms-Dropdown-title": {
                borderWidth: CuiStyleConstants.FIELD_BORDER_WIDTH,
                borderRadius: CuiStyleConstants.FIELD_BORDER_RADIUS
            },
            ".ms-Dropdown-titleIsPlaceHolder": {
                borderColor: CuiStyleConstants.FIELD_BORDER_COLOR
            },
            ".ms-Dropdown-caretDown": {
                color: theme.palette.neutralPrimary,
                fontSize: theme.fonts.tiny.fontSize
            }
        }
    },
    optionConflict: {
        ":hover": {
            color: theme.palette.neutralSecondary
        }
    },
    searchBox: {
        borderWidth: CuiStyleConstants.FIELD_BORDER_WIDTH,
        borderRadius: CuiStyleConstants.FIELD_BORDER_RADIUS,
        selectors: {
            ".ms-SearchBox-icon": {
                color: theme.palette.neutralPrimary
            },
            ".ms-SearchBox-icon:hover": {
                color: theme.palette.neutralPrimary
            }
        }
    }
}

export default AbbFilterDetailDropdownStyles
