import Icon from "../../../../components/icons/Icon"
import { getStatusColor, getStatusIcon, getStatusTranslation } from "./LineItemReadyStateIcon.utils"

interface LineItemReadyStateIconProps {
    readyState: string
}

export function LineItemReadyStateIcon({ readyState }: Readonly<LineItemReadyStateIconProps>) {
    return <Icon component={getStatusIcon(readyState)} color={getStatusColor(readyState)} tooltipProps={{ title: getStatusTranslation(readyState) }} />
}
