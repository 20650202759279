import DashboardWidget from "../dashboardWidget/DashboardWidget"
import { Box, Grid } from "@mui/material"
import NewsCard from "./newsCard/NewsCard"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { CatalogApi } from "../../../../features/catalog/catalog.api"
import compareStrings from "../../../../utils/compareStrings"
import CatalogUtils from "../../../../features/catalog/catalog.utils"
import { Characteristics, ProductGroupIds } from "../../../../features/catalog/catalog.constants"
import { useMemo } from "react"
import { ErrorAlert } from "../../../../features/error/components/errorAlert/ErrorAlert"
import { Filter, Selection } from "@encoway/c-services-js-client"
import { Characteristics as CharacteristicsFilter } from "@encoway/c-services-js-client/dist/esm/catalog/characteristics"

const productGroupFilter = Filter.productGroupFilter().id(ProductGroupIds.News)
const characteristicsFilter = new CharacteristicsFilter().all()
const selection = new Selection().filter(productGroupFilter).characteristics(characteristicsFilter)

export default function NewsWidget() {
    const productsQuery = CatalogApi.useProductsQuery(selection)

    const news = useMemo(() => {
        return (productsQuery.data?.products ?? [])
            .slice()
            .sort((n1, n2) =>
                compareStrings(
                    CatalogUtils.getCharacteristicValue(n1, Characteristics.Date.id),
                    CatalogUtils.getCharacteristicValue(n2, Characteristics.Date.id),
                    true
                )
            )
    }, [productsQuery.data?.products])

    return (
        <DashboardWidget title={L10n.format(TranslationKeys.pages.dashboard.newsWidget.title)}>
            <Box>
                <Grid container spacing={4}>
                    {productsQuery.isError && <ErrorAlert error={productsQuery.error.message} onRetry={productsQuery.refetch} />}
                    {productsQuery.isFetching || productsQuery.isLoading ? (
                        [1, 2, 3].map(value => (
                            <Grid key={value} item>
                                <NewsCard />
                            </Grid>
                        ))
                    ) : (
                        <>
                            {news.map(product => (
                                <Grid item key={product.id}>
                                    <NewsCard product={product} />
                                </Grid>
                            ))}
                        </>
                    )}
                </Grid>
            </Box>
        </DashboardWidget>
    )
}
