import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Cloud, Compare, Computer, Person, PriorityHigh, VisibilityOff } from "@mui/icons-material"
import { Tooltip } from "@mui/material"
import { ConfigurationElement } from "../../types/configuration.types"

export const IconsRenderer = (params: GridRenderCellParams<ConfigurationElement>) => {
    const { mandatory, setByUser, setBySystem, setByDefault, invisible, type } = params.row
    return (
        <>
            {type === "PARAMETER_COMPARISON" ? (
                <Tooltip title="comparison (value from loaded configuration)">
                    <Compare style={{ color: "red" }} />
                </Tooltip>
            ) : null}
            {setByUser ? (
                <Tooltip title="set by user">
                    <Person />
                </Tooltip>
            ) : null}
            {setBySystem ? (
                <Tooltip title="set by system">
                    <Computer />
                </Tooltip>
            ) : null}
            {setByDefault ? (
                <Tooltip title="set by default">
                    <Cloud />
                </Tooltip>
            ) : null}
            {mandatory ? (
                <Tooltip title="mandatory">
                    <PriorityHigh />
                </Tooltip>
            ) : null}
            {invisible ? (
                <Tooltip title="invisible">
                    <VisibilityOff />
                </Tooltip>
            ) : null}
        </>
    )
}
