import { createContext, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { SimpleTreeView } from "@mui/x-tree-view"
import { Box, Stack } from "@mui/material"
import SalesApi from "../../../../features/sales/sales.api"
import { ProjectConfigurationStyles } from "./ProjectConfiguration.styles"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { ResizeBar } from "../../../../components/resizeBar/ResizeBar"
import { useSearchParams } from "react-router-dom"
import { getAllLineItemIdsToCollapse, getAllLineItemIdsToExpand, getLineItem, getViewId } from "./ProjectConfiguration.utils"
import { useSaveConfiguration } from "./hooks/useSaveConfiguration"
import { TreeItem } from "./components/treeItem/TreeItem"
import { ProjectConfigurationContainerIds, ProjectConfigurationSearchParams } from "./ProjectConfiguration.constants"
import Cui from "./components/cui/Cui"
import { ProjectConfigurationHeader } from "./ProjectConfiguration.Header"
import { ProjectConfigurationLineItem } from "../../../../features/sales/sales.api.types"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { isNil } from "lodash"
import { SalesSlice } from "../../../../features/sales/sales.slice"
import Dialog from "../../../../components/dialog/Dialog"
import { useDeleteLineItemsHandler } from "../../../../features/sales/hooks/useDeleteLineItemsHandler"
import BusySlice from "../../../../features/busy/busy.slice"

export const ProjectConfigurationTreeContext = createContext<{
    expandItem: (item: ProjectConfigurationLineItem) => void
    collapseItem: (item: ProjectConfigurationLineItem) => void
}>({
    expandItem: (_item: ProjectConfigurationLineItem) => undefined,
    collapseItem: (_item: ProjectConfigurationLineItem) => undefined
})

export function ProjectConfiguration() {
    const [expandedItems, setExpandedItems] = useState<string[]>([])

    const [searchParams, setSearchParams] = useSearchParams()
    const lineItemId = searchParams.get(ProjectConfigurationSearchParams.LINE_ITEM_ID)
    const searchParamViewId = searchParams.get(ProjectConfigurationSearchParams.VIEW_ID)

    const stackRef = useRef<HTMLDivElement>(null)
    const configurationRef = useRef<HTMLDivElement>(null)
    const configurationTreeRef = useRef<HTMLUListElement>(null)

    const saveConfiguration = useSaveConfiguration()

    const projectConfigurationQuery = SalesApi.useProjectConfigurationQuery()
    const [viewConfiguration] = SalesApi.useViewConfigurationMutation()
    const [openConfiguration] = SalesApi.useOpenConfigurationMutation()
    const [stopConfiguration] = SalesApi.useStopConfigurationMutation()

    const configurationIsSaved = useAppSelector(state => state.sales.configurationIsSaved)
    const readOnly = useAppSelector(state => state.sales.configurationReadOnly)
    const visualizationIsAvailable = useAppSelector(state => state.sales.visualizationIsAvailable)
    const hideConfigurationTree = useAppSelector(state => state.sales.hideConfigurationTree)
    const hideVisualization = useAppSelector(state => state.sales.hideVisualization)
    const hideConfiguration = useAppSelector(state => state.sales.hideConfiguration)

    const lineItem = useMemo(() => {
        return lineItemId && projectConfigurationQuery.currentData ? getLineItem(lineItemId, projectConfigurationQuery.currentData)?.lineItem : undefined
    }, [lineItemId, projectConfigurationQuery.currentData])

    const isConfigurable = lineItem?.properties.configurable || lineItem?.properties.FOLDER_TYPE === "LINEUP"
    const viewId = getViewId(searchParamViewId, lineItem)
    const showConfigurationTree = !hideConfigurationTree
    const showVisualization = isConfigurable && visualizationIsAvailable && !hideVisualization
    const showConfiguration = isConfigurable && !hideConfiguration
    const showLeftResizeBar = showConfigurationTree && (showVisualization || showConfiguration)
    const showRightResizeBar = showConfiguration && showVisualization
    const configurationLineItemId = lineItem?.properties.FOLDER_TYPE === "LINEUP" ? lineItem.properties.LINEUP_ARTICLE_LINE_ITEM_ID! : lineItem?.lineItemId

    const dispatch = useAppDispatch()
    const deleteLineItemsHandler = useDeleteLineItemsHandler()
    const focusVisualization = searchParams.get(ProjectConfigurationSearchParams.FOCUS_VISUALIZATION)

    const startConfiguration = useCallback(
        async (lineItemId: string) => {
            dispatch(BusySlice.actions.setBusy)
            try {
                await stopConfiguration()
                if (readOnly) {
                    await viewConfiguration([lineItemId, viewId])
                } else {
                    await openConfiguration([lineItemId, viewId])
                }
            } finally {
                dispatch(BusySlice.actions.setIdle)
            }
        },
        [openConfiguration, readOnly, stopConfiguration, viewConfiguration, viewId, dispatch]
    )

    const changeConfigurationLayout = useCallback(() => {
        if (!isNil(focusVisualization)) {
            dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideVisualization: false, hideConfiguration: true }))
        } else {
            dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideVisualization: false, hideConfiguration: false }))
        }
    }, [dispatch, focusVisualization])

    useEffect(() => {
        if (isConfigurable && configurationLineItemId) {
            startConfiguration(configurationLineItemId).then(() => changeConfigurationLayout())
        }
    }, [configurationLineItemId, isConfigurable, startConfiguration, stopConfiguration, changeConfigurationLayout, viewId])

    useEffect(() => {
        return () => {
            stopConfiguration()
        }
    }, [stopConfiguration])

    useEffect(() => {
        changeConfigurationLayout()
    }, [changeConfigurationLayout])

    useEffect(() => {
        if (lineItemId && projectConfigurationQuery.currentData) {
            setExpandedItems(prevState => {
                const ids = prevState.concat(getAllLineItemIdsToExpand(lineItemId, projectConfigurationQuery.currentData!.lineItems))
                return Array.from(new Set(ids))
            })
        }
    }, [lineItemId, projectConfigurationQuery.currentData])

    const onSelectedItemsChange = useCallback(
        async (_event: SyntheticEvent, newLineItemId: string | null) => {
            if (newLineItemId !== lineItemId) {
                if (newLineItemId && !configurationIsSaved) {
                    dispatch(BusySlice.actions.setBusy)
                    try {
                        await saveConfiguration()
                    } finally {
                        dispatch(BusySlice.actions.setIdle)
                    }
                }
                setSearchParams(newLineItemId ? { [ProjectConfigurationSearchParams.LINE_ITEM_ID]: newLineItemId } : undefined)
            }
        },
        [configurationIsSaved, saveConfiguration, setSearchParams, lineItemId, dispatch]
    )

    const collapseItem = useCallback((item: ProjectConfigurationLineItem) => {
        setExpandedItems(prevState => {
            const lineItemIdsToCollapse = getAllLineItemIdsToCollapse(item)
            return prevState.filter(id => !lineItemIdsToCollapse.includes(id))
        })
    }, [])

    const expandItem = useCallback((item: ProjectConfigurationLineItem) => {
        setExpandedItems(prevState => prevState.concat(item.lineItem.lineItemId))
    }, [])

    const contextValue = useMemo(() => ({ collapseItem, expandItem }), [collapseItem, expandItem])

    const treeIsEmpty = (projectConfigurationQuery.currentData?.lineItems.length ?? 0) === 0

    const confirmDelete = () => {
        searchParams.delete(ProjectConfigurationSearchParams.LINE_ITEM_ID)
        setSearchParams(searchParams)
        deleteLineItemsHandler.deleteLineItems()
    }

    const stopPropagationOnFocus: React.FocusEventHandler<HTMLUListElement> = (e: React.FocusEvent<HTMLUListElement>) => {
        e.stopPropagation()
    }

    return (
        <ProjectConfigurationTreeContext.Provider value={contextValue}>
            <Stack spacing={2} flexGrow={1} display="flex" overflow="auto">
                <ProjectConfigurationHeader lineItem={lineItem} />
                <Stack
                    ref={stackRef}
                    direction="row"
                    spacing={1}
                    flexGrow={1}
                    overflow="auto"
                    className={ProjectConfigurationContainerIds.PROJECT_CONFIGURATION_CONTAINER}
                    sx={ProjectConfigurationStyles.container}
                >
                    {treeIsEmpty ? (
                        L10n.format(TranslationKeys.pages.project.configuration.emptyTreeMessage)
                    ) : (
                        <SimpleTreeView
                            expandedItems={expandedItems}
                            ref={configurationTreeRef}
                            sx={ProjectConfigurationStyles.tree(showConfigurationTree)}
                            selectedItems={lineItemId}
                            onSelectedItemsChange={onSelectedItemsChange}
                            onFocusCapture={stopPropagationOnFocus}
                        >
                            {projectConfigurationQuery.currentData?.lineItems.map(item => <TreeItem key={item.lineItem.lineItemId} item={item} />)}
                        </SimpleTreeView>
                    )}
                    {showLeftResizeBar && <ResizeBar target={configurationTreeRef} direction={-1} axis="x" />}
                    <Box id={ProjectConfigurationContainerIds.VISUALIZATION} sx={ProjectConfigurationStyles.visualization(showVisualization)} />
                    {showRightResizeBar && <ResizeBar target={configurationRef} direction={1} axis="x" />}
                    <Box
                        id={ProjectConfigurationContainerIds.CONFIGURATION}
                        ref={configurationRef}
                        sx={ProjectConfigurationStyles.configuration(showConfiguration, showVisualization)}
                    ></Box>
                    {isConfigurable && (
                        <Box sx={{ display: "none" }}>
                            <Cui lineItem={lineItem} />
                        </Box>
                    )}
                </Stack>

                <Dialog
                    open={deleteLineItemsHandler.isAskingToDeleteLineItems}
                    onClose={deleteLineItemsHandler.cancelDeletingLineItems}
                    title={L10n.format(TranslationKeys.lineItem.deleteDialog.title)}
                    message={L10n.format(TranslationKeys.lineItem.deleteDialog.message)}
                    defaultDialogActions={{
                        onConfirm: confirmDelete,
                        onCancel: deleteLineItemsHandler.cancelDeletingLineItems,
                        confirmButtonLabel: TranslationKeys.lineItem.deleteDialog.confirmButtonLabel,
                        cancelButtonLabel: TranslationKeys.lineItem.deleteDialog.cancelButtonLabel
                    }}
                />
            </Stack>
        </ProjectConfigurationTreeContext.Provider>
    )
}
