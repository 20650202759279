import theme from "../../../../App.theme"

const HelpWidgetStyles = {
    card: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        width: "100%",
        textDecoration: "none",
        cursor: "pointer",
        ":hover": {
            textDecoration: "none"
        }
    },
    stack: {
        width: "100%",
        padding: 4
    },
    headline: {
        fontSize: 26,
        fontWeight: 400
    },
    text: {
        fontWeight: 100
    },
    icons: {
        fontSize: 60,
        stroke: theme.palette.common.black,
        strokeWidth: 1
    }
}

export default HelpWidgetStyles
