import SearchIcon from "@mui/icons-material/Search"
import Input, { InputProps } from "../Input"
import SearchInputStyles from "./SearchInput.styles"
import { useEffect, useState } from "react"

type SearchInputProps = Omit<InputProps, "label"> & {
    onSearch: (searchValue: string) => void
    keepSearchValue?: boolean
    label?: string
}

export default function SearchInput({ onSearch, keepSearchValue, ...props }: SearchInputProps) {
    const [searchValue, setSearchValue] = useState<string>("")

    useEffect(() => {
        if (keepSearchValue === false) {
            setSearchValue("")
        }
    }, [keepSearchValue])

    return (
        <Input
            value={searchValue}
            label="search"
            hiddenLabel={!props.label}
            sx={SearchInputStyles.input}
            InputProps={{ endAdornment: <SearchIcon sx={SearchInputStyles.icon} onClick={() => onSearch(searchValue)} /> }}
            {...props}
            onChange={event => setSearchValue(event.target.value)}
            onKeyUp={e => {
                if (e.key === "Enter") {
                    // @ts-ignore
                    onSearch(e.target.value)
                }
            }}
        />
    )
}
