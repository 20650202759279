import { configureStore } from "@reduxjs/toolkit"
import { EnvironmentApi } from "../features/environment/environment.api"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { FetchApi } from "../features/fetch/fetch.api"
import BusySlice from "../features/busy/busy.slice"
import ErrorSlice from "../features/error/error.slice"
import SnackbarSlice from "../features/snackbar/snackbar.slice"
import { SalesSlice } from "../features/sales/sales.slice"
import dimensioningApi from "../features/dimensioning/dimensioning.api"
import { MatlabApi } from "../features/matlab/matlab.api"
import { Dispatch, RootState } from "./store.types"
import ProgressSlice from "../features/progress/progress.slice"
import { GraphApi } from "../features/graph/graph.api"
import CatalogSlice from "../features/catalog/catalog.slice"
import OidcSlice from "../features/oidc/oidc.slice"
import { CatalogApi } from "../features/catalog/catalog.api"
import SalesApi from "../features/sales/sales.api"
import { VisualizationApi } from "../features/visualization/visualization.api"
import VisualizationSlice from "../features/visualization/visualization.slice"
import { TranslationsSlice } from "../features/translations/translations.slice"
import { TranslationsApi } from "../features/translations/translations.api"
import { ConfigurationApi } from "../features/configuration/configuration.api"

export const store = configureStore({
    reducer: {
        sales: SalesSlice.reducer,
        catalog: CatalogSlice.reducer,
        visualization: VisualizationSlice.reducer,
        busy: BusySlice.reducer,
        snackbar: SnackbarSlice.reducer,
        error: ErrorSlice.reducer,
        progress: ProgressSlice.reducer,
        oidc: OidcSlice.reducer,
        translations: TranslationsSlice.reducer,
        [EnvironmentApi.reducerPath]: EnvironmentApi.reducer,
        [ConfigurationApi.reducerPath]: ConfigurationApi.reducer,
        [dimensioningApi.reducerPath]: dimensioningApi.reducer,
        [MatlabApi.reducerPath]: MatlabApi.reducer,
        [GraphApi.reducerPath]: GraphApi.reducer,
        [FetchApi.reducerPath]: FetchApi.reducer,
        [SalesApi.reducerPath]: SalesApi.reducer,
        [CatalogApi.reducerPath]: CatalogApi.reducer,
        [VisualizationApi.reducerPath]: VisualizationApi.reducer,
        [TranslationsApi.reducerPath]: TranslationsApi.reducer
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({ serializableCheck: false })
            .concat(EnvironmentApi.middleware)
            .concat(ConfigurationApi.middleware)
            .concat(dimensioningApi.middleware)
            .concat(MatlabApi.middleware)
            .concat(GraphApi.middleware)
            .concat(FetchApi.middleware)
            .concat(SalesApi.middleware)
            .concat(CatalogApi.middleware)
            .concat(VisualizationApi.middleware)
            .concat(TranslationsApi.middleware)
    }
})

export const useAppDispatch: () => Dispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
