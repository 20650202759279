const GraphStyles = {
    root: {
        textAlign: "center"
    },
    plot: {
        fontWeight: "1px",
        overflowX: "hidden"
    },
    errorBox: {
        whiteSpace: "pre-wrap",
        textAlign: "left",
        width: "100%"
    }
}

export default GraphStyles
