import { ReactNode } from "react"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Characteristic } from "@encoway/c-services-js-client"
import { DcsDimensioningResultEntry, DctDimensioningResultEntry } from "../../../../../../../../features/dimensioning/dimensioning.types"
import CatalogUtils from "../../../../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../../../../features/catalog/catalog.constants"
import { numberFormatter } from "../../../../../../../../components/dataGrid/dataGridValueFormatters"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../../features/translations/TranslationKeys"

export enum DcType {
    DCT = "DCT",
    DCS = "DCS"
}

export function determineDcType(productName: string): DcType {
    const productAbbreviation = productName.substring(0, 3)
    return Object.values(DcType)
        .filter(dcType => dcType === productAbbreviation)
        .map(key => DcType[key])[0]
}

export function mapDimensioningDcColumns(
    columns: GridColDef[],
    characteristics: (Characteristic | undefined)[],
    renderProductNameCell: (params: GridRenderCellParams<DcsDimensioningResultEntry>) => ReactNode
) {
    return columns.map(column => {
        const characteristic = characteristics.find(characteristic => characteristic?.id === column.field)
        return {
            ...column,
            headerName: characteristic ? CatalogUtils.joinNameAndUnit(characteristic) : "",
            renderCell: column.field === Characteristics.DcProductName.id ? renderProductNameCell : undefined,
            type: characteristic?.valueType === "NUMBER" ? "number" : undefined,
            valueFormatter: characteristic?.valueType === "NUMBER" ? numberFormatter : undefined
        } as GridColDef
    })
}

export function getIdsOfEnabledDcDimensioningResults(entries?: DcsDimensioningResultEntry[] | DctDimensioningResultEntry[]) {
    return getIdsOfBestDcDimensioningResults(entries).concat(
        entries
            ?.filter(r => r.DC_Margin < 0)
            .slice(-3)
            .map(r => r.id) ?? []
    )
}

export function getIdsOfBestDcDimensioningResults(entries?: DcsDimensioningResultEntry[] | DctDimensioningResultEntry[]) {
    return (
        entries
            ?.filter(r => r.DC_Margin >= 0)
            .slice(0, 3)
            .map(r => r.id) ?? []
    )
}

export function getErrorMessage(error: FetchBaseQueryError) {
    let errorMessage = (error?.data as Error | undefined)?.message
    if (error?.status === 400) {
        const data = error.data as { translatedParameterName: string }
        errorMessage = L10n.format(TranslationKeys.error.missingMandatoryValue, { translatedParameterName: data.translatedParameterName })
    }
    return errorMessage
}
