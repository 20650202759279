import { useMemo } from "react"
import { ConfigurationElement } from "../../types/configuration.types"

const check = (searchString: string, element: ConfigurationElement, path: ConfigurationElement[]) => {
    if (element.name.toLowerCase().includes(searchString.toLowerCase())) {
        path.forEach(p => {
            p.matches = true
        })
        element.matches = true
    } else {
        element.matches = false
    }
    element.children.forEach(child => check(searchString, child, path.concat(element)))
}

const filter = (element: ConfigurationElement): ConfigurationElement => {
    return {
        ...element,
        children: element.children.filter(e => e.matches).map(e => filter(e))
    }
}

/**
 * Filters the configuration state by the given search string
 * @param configurationState the actual configuration state.
 * @param searchString the search string used as filter, maybe empty
 */
export const useFilteredConfigurationState = (configurationState: ConfigurationElement, searchString?: string) => {
    return useMemo(() => {
        check(searchString ?? "", configurationState, [])
        return filter(configurationState)
    }, [configurationState, searchString])
}
