import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import Input, { InputProps } from "../../../input/Input"
import { GenericInput } from "../GenericForm"
import { HTMLInputTypeAttribute, useCallback, useEffect, useState } from "react"

interface GenericFormInputProps extends Omit<InputProps, "onBlur" | "label" | "onChange"> {
    input: GenericInput
    value: string
    disabled?: boolean
    error: boolean
    onBlur: (inputId: string, value: string | number) => void
}

export default function GenericFormInput({ input, value, disabled, error, onBlur, ...props }: Readonly<GenericFormInputProps>) {
    const [controlledValue, setControlledValue] = useState(value)
    const updateState = useCallback(
        (newValue: string) => {
            if (!input.maxLength || newValue.length <= input.maxLength) {
                setControlledValue(newValue)
            }
        },
        [input]
    )

    useEffect(() => {
        updateState(value)
    }, [updateState, value])

    return (
        <Input
            type={input.type as HTMLInputTypeAttribute}
            value={controlledValue}
            label={L10n.format(input.label)}
            data-cy={`GenericFormInput.${input.id}`}
            placeholder={L10n.format(TranslationKeys.components.input.inputPlaceholder)}
            required={input.mandatory}
            disabled={disabled}
            error={error}
            onChange={event => updateState(event.target.value)}
            infoText={input.informationText}
            onBlur={() => onBlur(input.id, input.type === "number" ? parseFloat(controlledValue) : controlledValue)}
            {...props}
        />
    )
}
