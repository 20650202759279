const NewsCardStyles = {
    card: {
        width: 250
    },
    image: {
        borderRadius: 3,
        objectFit: "cover",
        width: "100%"
    },
    title: {
        fontSize: 20,
        fontWeight: "bold"
    },
    timeAgo: {
        fontSize: 14
    }
}

export default NewsCardStyles
