export default function compareStrings(s1?: string, s2?: string, descending: boolean = false) {
    if (!s2) {
        return -1
    } else if (!s1) {
        return 1
    } else if (descending) {
        return s1 < s2 ? 1 : -1
    } else {
        return s1 > s2 ? 1 : -1
    }
}
