import { ConfigurationElement } from "../../types/configuration.types"
import { useMemo, useState } from "react"

/**
 * Searches for the an element with the given id in the given root element recursively.
 * @param element the root element.
 * @param id the id to find
 */
const find = (element: ConfigurationElement, id: string): ConfigurationElement | undefined => {
    if (element.id === id) {
        return element
    } else {
        const found = element.children.find(e => e.id === id)
        if (found) {
            return found
        } else {
            for (let child of element.children) {
                const foundChild = find(child, id)
                if (foundChild) {
                    return foundChild
                }
            }
            return undefined
        }
    }
}

export const useSelectedNode = (rootElement: ConfigurationElement) => {
    const [selectedNode, setSelectedNode] = useState<string>("")
    const selectedElement = useMemo(() => {
        return find(rootElement, selectedNode)
    }, [rootElement, selectedNode])
    const onNodeSelect = (_event: any, id: string | null) => {
        setSelectedNode(id ?? "")
    }
    return { selectedElement, onNodeSelect }
}
