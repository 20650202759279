import { getProductGroupData } from "./ProductGroupCard.utils"
import { Skeleton, Typography } from "@mui/material"
import { ProductGroupCardStyles } from "./ProductGroupCard.styles"

interface ProductGroupCardTitleProps {
    data?: ReturnType<typeof getProductGroupData>
    disabled: boolean
}

export function ProductGroupCardTitle({ data, disabled }: Readonly<ProductGroupCardTitleProps>) {
    return (
        <Typography variant="h6" sx={ProductGroupCardStyles.name(disabled)}>
            {data ? data.name : <Skeleton />}
        </Typography>
    )
}
