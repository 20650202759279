import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/react"
import prepareHeaders from "./prepareHeaders"
import BusySlice from "../features/busy/busy.slice"
import { Settings } from "../settings"
import readError from "../features/error/utils/readError"

export const baseQuery: BaseQueryFn<string> = async (url, api) => {
    try {
        api.dispatch(BusySlice.actions.setBusy())
        const headers = prepareHeaders(new Headers(), api)
        const response = await fetch(Settings.configuration.baseUrl + url, { headers, credentials: "include" })
        return { data: await response.json() }
    } catch (e) {
        return { error: await readError(e) }
    } finally {
        api.dispatch(BusySlice.actions.setIdle())
    }
}
