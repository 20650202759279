import { Launch } from "@mui/icons-material"
import { Link, LinkProps, Stack } from "@mui/material"
import { NewTabLinkStyles } from "./NewTabLink.styles"

interface NewTabLinkProps extends LinkProps {
    text: string
    disabled?: boolean
}

export function NewTabLink({ text, disabled = false, ...props }: NewTabLinkProps) {
    return (
        <Stack direction="row">
            <Stack sx={NewTabLinkStyles(Boolean(props.href), disabled)} {...props} component={Link} target="_blank" direction="row">
                <Launch fontSize="small" />
                {text}
            </Stack>
        </Stack>
    )
}
