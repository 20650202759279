import { Compare, Description, Folder, Settings } from "@mui/icons-material"
import { ConfigurationElement } from "../../types/configuration.types"
import React from "react"
import { Stack } from "@mui/material"
import { Value } from "./Value"

type ConfigurationTreeItemLabelProps = {
    configurationElement: ConfigurationElement
}

const IconStyle = {
    width: "20px"
}

const Icons = {
    CONFIGURATION: <Settings sx={IconStyle} />,
    PARAMETER: <Description sx={IconStyle} />,
    CONTAINER: <Folder sx={IconStyle} />,
    PARAMETER_COMPARISON: <Compare sx={{ ...IconStyle, color: "red" }} />
}

export const ConfigurationTreeItemLabel = ({ configurationElement }: ConfigurationTreeItemLabelProps) => {
    return (
        <Stack direction="row">
            {Icons[configurationElement.type]}
            {configurationElement.name}
            <Value value={configurationElement.value} />
        </Stack>
    )
}
