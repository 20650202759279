import Dialog, { DefaultDialogActionsProps, DialogProps } from "../../../../components/dialog/Dialog"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../translations/TranslationKeys"
import Input from "../../../../components/input/Input"
import SalesApi from "../../sales.api"
import { ChangeEvent, FormEvent, useCallback, useMemo, useReducer } from "react"
import { ErrorAlert } from "../../../error/components/errorAlert/ErrorAlert"
import { Stack } from "@mui/material"

const folderNameReducer = (_state: string, event: ChangeEvent<HTMLInputElement>) => event.target.value

export function CreateLineupButtonDialog(props: Readonly<Pick<DialogProps, "onClose">>) {
    const { onClose } = props

    const [folderName, setFolderName] = useReducer(folderNameReducer, L10n.format(TranslationKeys.lineItem.lineup.create.dialog.nameInput.defaultValue))
    const [createLineup, { error }] = SalesApi.useCreateLineupMutation()

    const createLineupAndCloseDialog = useCallback(() => {
        createLineup(folderName).unwrap().then(onClose).catch()
    }, [createLineup, folderName, onClose])

    const submit = (event: FormEvent) => {
        event.preventDefault()
        createLineupAndCloseDialog()
    }

    const defaultDialogActions: DefaultDialogActionsProps = useMemo(
        () => ({
            onCancel: onClose,
            onConfirm: createLineupAndCloseDialog,
            disableConfirm: !folderName
        }),
        [createLineupAndCloseDialog, folderName, onClose]
    )

    return (
        <Dialog
            open={true}
            title={L10n.format(TranslationKeys.lineItem.lineup.create.dialog.title)}
            defaultDialogActions={defaultDialogActions}
            withDividers
            {...props}
        >
            <Stack spacing={1}>
                <form onSubmit={submit}>
                    <Input
                        label={L10n.format(TranslationKeys.lineItem.lineup.create.dialog.nameInput.label)}
                        value={folderName}
                        onChange={setFolderName}
                        autoFocus
                    />
                </form>
                {error && <ErrorAlert error={error.message} />}
            </Stack>
        </Dialog>
    )
}
