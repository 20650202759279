import ReactDOM from "react-dom"
import { Stylesheet } from "@fluentui/react"
import { BrowserNotificationService } from "./features/browserNotification/BrowserNotificationService"
import App from "./App"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en.json"

TimeAgo.addDefaultLocale(en)

Stylesheet.getInstance().setConfig({ namespace: "encoway" })

BrowserNotificationService.addEventListeners()

ReactDOM.render(<App />, document.getElementById("cui"))
