import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Product } from "@encoway/c-services-js-client"
import { Stack } from "@mui/material"
import React from "react"

export const TextCell = (params: Readonly<GridRenderCellParams<Product>>) => {
    const width = params.value ? `${String(params.value).length * 9}px` : "100%"
    return (
        <Stack direction="row" width={width}>
            {params.value}
        </Stack>
    )
}
