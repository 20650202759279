import { useAppDispatch, useAppSelector } from "../../../store/store"
import SalesApi from "../sales.api"
import { SalesSlice } from "../sales.slice"
import { useCallback } from "react"
import ErrorSlice from "../../error/error.slice"
import useNavigate from "../../../router/hooks/useNavigate"

export function useDeleteLineItemsHandler() {
    const lineItemsToDelete = useAppSelector(state => state.sales.lineItemsToDelete)
    const [triggerDeleteLineItems] = SalesApi.useDeleteLineItemsMutation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const salesDocumentId = SalesApi.endpoints.salesDocument.useQueryState().data!.salesDocumentId

    const askToDeleteLineItems = useCallback(
        (lineItemIds: string[]) => {
            dispatch(SalesSlice.actions.setLineItemsToDelete(lineItemIds))
        },
        [dispatch]
    )

    const cancelDeletingLineItems = useCallback(() => {
        dispatch(SalesSlice.actions.setLineItemsToDelete(undefined))
    }, [dispatch])

    const deleteLineItems = useCallback(async () => {
        if (lineItemsToDelete) {
            try {
                await triggerDeleteLineItems(lineItemsToDelete).unwrap()
            } catch (error: any) {
                if (error.status === 410) {
                    navigate.toProjectConfiguration(salesDocumentId)
                } else {
                    dispatch(ErrorSlice.actions.set(error))
                }
            }
        } else {
            console.error("Before deleting line items 'askToDeleteLineItems' must be called.")
        }
    }, [lineItemsToDelete, triggerDeleteLineItems, dispatch, navigate, salesDocumentId])

    return {
        isAskingToDeleteLineItems: !!lineItemsToDelete,
        askToDeleteLineItems,
        cancelDeletingLineItems,
        deleteLineItems
    }
}
