import theme from "../../../../../App.theme"

const UserStyles = {
    avatar: {
        bgcolor: theme.palette.divider,
        color: theme.palette.text.primary,
        fontSize: 15,
        fontWeight: "bold"
    }
}

export default UserStyles
