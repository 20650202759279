import { InputFieldProps } from "@encoway/cui-configurator-components"
import AbbInputFieldStyles from "./AbbInputField.styles"
import { CuiInputFieldWithCustomValueFormatting } from "./CuiInputFieldWithCustomValueFormatting"
import CuiUtils from "../../utils/CuiUtils"

const AbbInputField = (props: InputFieldProps) => {
    return <CuiInputFieldWithCustomValueFormatting {...props} data={CuiUtils.formatParameter(props.data)} styles={AbbInputFieldStyles(props.data)} />
}

export default AbbInputField
