import DashboardWidget from "../dashboardWidget/DashboardWidget"
import { Card, Stack } from "@mui/material"
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined"
import Typography from "@mui/material/Typography"
import { ArrowForward } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import HelpWidgetStyles from "./HelpWidget.styles"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import Link from "@mui/material/Link"

export default function HelpWidget() {
    return (
        <DashboardWidget>
            <Card sx={HelpWidgetStyles.card} component={Link} target="_blank" href={L10n.format(TranslationKeys.pages.dashboard.helpWidget.link)}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={HelpWidgetStyles.stack}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <HeadsetMicOutlinedIcon sx={HelpWidgetStyles.icons} />
                        <Stack spacing={1}>
                            <Typography sx={HelpWidgetStyles.headline}>{L10n.format(TranslationKeys.pages.dashboard.helpWidget.headline)}</Typography>
                            <Typography sx={HelpWidgetStyles.text}>{L10n.format(TranslationKeys.pages.dashboard.helpWidget.text)}</Typography>
                        </Stack>
                    </Stack>
                    <ArrowForward sx={HelpWidgetStyles.icons} />
                </Stack>
            </Card>
        </DashboardWidget>
    )
}
