import TextField from "@mui/material/TextField"
import React from "react"

type SearchFieldProps = {
    searchString?: string
    setSearchString: (searchString: string) => void
}

export const SearchField = ({ searchString, setSearchString }: SearchFieldProps) => {
    return <TextField autoFocus={true} value={searchString} onChange={e => setSearchString(e.target.value)} placeholder="search" />
}
