import { OidcRoles } from "./oidc.constants"
import { UserInformation } from "./hooks/useUserInformation"

export class OidcUtils {
    public static hasEditProjectsRole(userInformation: UserInformation) {
        return userInformation.roles?.includes(OidcRoles.EDIT_PROJECTS_ROLE)
    }

    public static hasUpdateProjectLsuRole(userInformation: UserInformation) {
        return userInformation.roles?.includes(OidcRoles.UPDATE_PROJECT_LSU_ROLE)
    }

    public static getLsus(userInformation?: UserInformation) {
        return (
            userInformation?.roles
                ?.filter(role => role.startsWith(OidcRoles.USER_INFO_LSU_ROLE_PREFIX))
                .map(role => role.replace(OidcRoles.USER_INFO_LSU_ROLE_PREFIX, "")) ?? []
        )
    }

    public static hasReadTransferPricesRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_TRANSFER_PRICES_ROLE)
    }

    public static hasReadMultiplierRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_MULTIPLIER_ROLE)
    }

    public static hasReadListPricesRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_LIST_PRICES_ROLE)
    }

    public static hasReadMfkRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_MFK_ROLE)
    }

    static hasReadTransferPriceDiscountRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_TRANSFERPRICES_DISCOUNT_ROLE)
    }

    static hasUpdateTransferPricesDiscountRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.UPDATE_TRANSFERPRICES_DISCOUNT_ROLE)
    }
    static hasReadTotalCostRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_COSTPRICES_ROLE)
    }
}
