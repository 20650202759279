// @ts-ignore
import Numeral from "numeral"
import React, { useCallback } from "react"
import { L10n } from "@encoway/l10n"

/**
 * Validates if a given string is a correctly formatted number.
 * If the range argument is provided it also checks whether the number is in the range.
 *
 * @param {string} input - The string that will be validated.
 * @param {string} fullLocale - Full locale to use for converting the argument input to a number
 * @param {number|string} minValue - Optional allowed minimal value to check the input against.
 * @param {number|string} maxValue - Optional allowed maximal value to check the input against.
 * @returns {number|NaN} The input as number if valid, NaN otherwise.
 *
 */
export const validateNumericInput: (input: string, fullLocale: string, minValue?: number | string, maxValue?: number | string) => number = (
    input,
    fullLocale,
    minValue,
    maxValue
) => {
    const number = Numeral(input.replace(",", "."))?.value()
    return number === null ||
        isNaN(number) ||
        !isValidInput(input) ||
        (minValue !== undefined && number < minValue) ||
        (maxValue !== undefined && number > maxValue)
        ? NaN
        : number
}
const isValidInput = (input: string) => {
    const cd = { thousands: " ", decimal: "," }
    const pd = { thousands: " ", decimal: "." }
    const comma = new RegExp(`^[+-]?(\\d{1,2}[${cd.thousands}])?((\\d{3}[${cd.thousands}]|\\d{4,}}))*?\\d+([${cd.decimal}]\\d+)?$`)
    const point = new RegExp(`^[+-]?(\\d{1,2}[${pd.thousands}])?((\\d{3}[${pd.thousands}]|\\d{4,}}))*?\\d+([${pd.decimal}]\\d+)?$`)
    return comma.test(input) || point.test(input)
}

export type ValidateOptions = {
    updateError?: boolean
    removeError?: boolean
}

export const useValidation = (
    component: string,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>,
    formattedMinValue?: string,
    formattedMaxValue?: string,
    minValue?: number | string,
    maxValue?: number | string
) => {
    return useCallback(
        (currentValue: string, options?: ValidateOptions) => {
            if (currentValue !== "") {
                const validatedNumber = validateNumericInput(currentValue, L10n.currentFullLocale(), minValue, maxValue)
                if (options && options.updateError && isNaN(validatedNumber) && currentValue !== formattedMaxValue && currentValue !== formattedMinValue) {
                    if ((!maxValue || maxValue === "Infinity") && (!minValue || minValue === "-Infinity")) {
                        setError(
                            L10n.format(`Configuration.${component}.input.invalid`, {
                                input: currentValue
                            })
                        )
                    } else {
                        setError(
                            L10n.format(`Configuration.${component}.input.outofbounds`, {
                                originalMinValue: formattedMinValue,
                                originalMaxValue: formattedMaxValue
                            })
                        )
                    }
                } else {
                    if (options && options.removeError) {
                        setError(undefined)
                    }
                }
                return validatedNumber
            }
            return NaN
        },
        [component, minValue, maxValue, formattedMinValue, formattedMaxValue, setError]
    )
}
