import { useEffect, useState } from "react"

export function useBroadcastChannel(name: string) {
    const [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel>()

    useEffect(() => {
        const bc = new BroadcastChannel(name)
        setBroadcastChannel(bc)
        return () => bc.close()
    }, [name])

    return broadcastChannel
}
