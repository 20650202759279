import Plot from "react-plotly.js"
import { Box } from "@mui/material"
import { L10n } from "@encoway/l10n"
import GraphStyles from "./Graph.styles"
import { useGraphQuery } from "../../../../../../../../features/graph/graph.api"
import TranslationKeys from "../../../../../../../../features/translations/TranslationKeys"
import AbbSpinner from "../AbbSpinner/AbbSpinner"
import { CSSProperties, useMemo } from "react"
import { EnvironmentApi } from "../../../../../../../../features/environment/environment.api"

const Graph = (props: any) => {
    const graphQuery = useGraphQuery({
        parameterName: props.data.name,
        configurationId: props.configurationId
    })
    const systemEnvironment = EnvironmentApi.usePublicEnvironmentQuery().data?.systemEnvironment
    const graphDefinition = structuredClone(graphQuery.data)

    const { width, ...layout } = graphDefinition?.layout ?? { width: 5 }

    const errorMessage = useMemo(() => {
        if (!graphQuery.error) return ""

        try {
            const parsedError = JSON.parse(graphQuery.error as string)
            return parsedError?.message || ""
        } catch {
            return typeof graphQuery.error === "string" ? graphQuery.error : ""
        }
    }, [graphQuery.error])

    return (
        <Box sx={GraphStyles.root}>
            {graphDefinition ? (
                <Plot
                    style={GraphStyles.plot as CSSProperties}
                    data={graphDefinition.data}
                    layout={{ title: props.data.translatedName, autosize: true, ...layout }}
                    config={{ displaylogo: false }}
                    useResizeHandler={true}
                />
            ) : null}
            {graphQuery.isError && (
                <>
                    {systemEnvironment === "develop" ? (
                        <Box style={GraphStyles.errorBox as CSSProperties}> {errorMessage}</Box>
                    ) : (
                        L10n.format(TranslationKeys.pages.project.configuration.graph.renderError, { graphName: props.data.translatedName })
                    )}
                </>
            )}
            {graphQuery.isFetching && <AbbSpinner />}
        </Box>
    )
}

export default Graph
