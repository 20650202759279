import { Stack, StackProps, Typography, TypographyProps } from "@mui/material"
import { PropsWithChildren } from "react"
import DashboardWidgetStyles from "./DashboardWidget.styles"

export interface DashboardWidgetProps extends StackProps {
    title?: string
    titleProps?: TypographyProps
}

export default function DashboardWidget({ title, titleProps, children, ...props }: PropsWithChildren<DashboardWidgetProps>) {
    return (
        <Stack spacing={3} {...props}>
            <Typography variant="h2" {...titleProps} sx={{ ...DashboardWidgetStyles.title, ...titleProps?.sx }}>
                {title}
            </Typography>
            {children}
        </Stack>
    )
}
