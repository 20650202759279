import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client"

export const POSITIV_INFINITY_SYMBOL = `+\u221E`
export const NEGATIV_INFINITY_SYMBOL = `-\u221E`
const format = (unformatted?: string | number, formatted?: string | number): string | undefined => {
    if (unformatted === "Infinity") {
        return POSITIV_INFINITY_SYMBOL
    } else if (unformatted === "-Infinity") {
        return NEGATIV_INFINITY_SYMBOL
    }
    return formatted as string
}

export default class CuiUtils {
    static formatParameter(parameter: ParameterTO) {
        return {
            ...parameter,
            originalMinValue: format(parameter?.unformattedOriginalMinValue, parameter?.originalMinValue),
            originalMaxValue: format(parameter?.unformattedOriginalMaxValue, parameter?.originalMaxValue)
        }
    }

    /**
     * Check if container has parameters (is not empty).
     * @param container the container to be checked
     */
    static hasParameters(container: ContainerTO) {
        return container.parameters && container.parameters.length > 0
    }

    /**
     * Recursively walk all child containers of a container.
     * @param container the root container
     * @param fnFilter the container filter
     * @param fnMap the container mapping function
     * @return list of filtered and mapped containers
     */
    static walkContainers<T>(container: ContainerTO, fnFilter: (container: ContainerTO) => boolean, fnMap: (container: ContainerTO) => T): T[] {
        const containers = container.children.filter(fnFilter).map(fnMap)
        return container.children.reduce((acc, val) => acc.concat(this.walkContainers(val, fnFilter, fnMap)), containers)
    }

    static findParameter(container: ContainerTO, filter: (parameter: ParameterTO) => boolean): ParameterTO | undefined {
        const parameter = container.parameters.find(filter)
        if (parameter) {
            return parameter
        } else {
            for (let child of container.children) {
                const result = this.findParameter(child, filter)
                if (result) {
                    return result
                }
            }
        }
    }

    /**
     * Reads value of viewport property.
     * @param value value of viewport property
     */
    static readViewportPropertyValue(value?: string) {
        const result = value?.replaceAll('{"', "").replaceAll('"}', "")
        return result === "{}" ? undefined : result
    }
}
