import { getProductGroupData } from "./ProductGroupCard.utils"
import { ProductGroup } from "@encoway/c-services-js-client"
import { Button } from "@mui/material"
import { ProductGroupCardStyles } from "./ProductGroupCard.styles"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { ProductGroupCardProps, ProductGroupCardSize } from "./ProductGroupCard"

interface ProductGroupCardButtonProps {
    data?: ReturnType<typeof getProductGroupData>
    size: ProductGroupCardSize
    productGroup?: ProductGroup
    onSelect: ProductGroupCardProps["onSelect"]
    disabled: boolean
}

export function ProductGroupCardButton({ data, size, productGroup, onSelect, disabled }: Readonly<ProductGroupCardButtonProps>) {
    return (
        <Button
            data-cy={`ProductGroupCard.${data?.id}.Button`}
            onClick={disabled || !onSelect || !productGroup ? undefined : () => onSelect(productGroup)}
            sx={ProductGroupCardStyles.button(size)}
        >
            {L10n.format(TranslationKeys.pages.project.catalog.selectProductGroupButtonLabel)}
        </Button>
    )
}
