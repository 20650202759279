import AbbFilterDetailDropdownStyles from "./AbbFilterDetailDropdown.styles"
import { AbbFilterDetailDropdownProps, IDetailDropdownOption, IFilterDetailDropdownStyles } from "./AbbFilterDetailDropdown.types"
import { createBasicDropdownOptionType, FilterDropdown, VIEWPORT_PROPERTY_VALUE } from "@encoway/cui-configurator-components"
import { onRenderItem } from "@encoway/cui-configurator-components/src/components/Dropdown/Dropdown.types"
import { DetailDropdownOption } from "./DetailDropdownOption"
import { classNamesFunction } from "@fluentui/react/lib/Utilities"
import { IProcessedStyleSet } from "@fluentui/react/lib/Styling"
import { Error } from "@encoway/react-configurator"
import { DetailDropdownTitle } from "./DetailDropdownTitle"
import { IRenderFunction } from "@fluentui/react"

const AbbFilterDetailDropdown = (props: AbbFilterDetailDropdownProps) => {
    const showImage = props.data.viewPortProperties?.DETAIL_DROPDOWN_IMAGE === VIEWPORT_PROPERTY_VALUE.True
    const showShortText = props.data.viewPortProperties?.DETAIL_DROPDOWN_SHORT_TEXT === VIEWPORT_PROPERTY_VALUE.True
    const classNames = classNamesFunction()(AbbFilterDetailDropdownStyles, props.theme) as IProcessedStyleSet<IFilterDetailDropdownStyles>

    const renderOption: onRenderItem = (dropdownOption: IDetailDropdownOption, changeValue, defaultRender) => {
        return (
            <DetailDropdownOption
                showImage={showImage}
                showShortText={showShortText}
                imageResolution={props.imageResolution}
                mediaLink={props.mediaLink}
                dropdownOption={dropdownOption}
                changeValue={changeValue}
                defaultRender={defaultRender}
                classNames={classNames}
            />
        )
    }

    const onCreateDropdownOption = (
        value: any,
        unit: string,
        error?: Error,
        createBasicDropdownOption?: createBasicDropdownOptionType
    ): IDetailDropdownOption => {
        return {
            shortText: value.shortText,
            image: value.imageUrl,
            ...(createBasicDropdownOption as createBasicDropdownOptionType)(value, unit, error)
        }
    }

    const renderTitle: IRenderFunction<IDetailDropdownOption> = (selectedDropdownOption, defaultRender) => {
        // Note: This code does not(!) support multiselection
        return (
            <DetailDropdownTitle
                showImage={showImage}
                imageResolution={props.imageResolution}
                mediaLink={props.mediaLink}
                selectedDropdownOption={selectedDropdownOption}
                defaultRender={defaultRender}
                classNames={classNames}
            />
        )
    }

    return (
        <FilterDropdown
            {...props}
            onRenderItem={renderOption}
            onRenderTitle={renderTitle}
            onCreateDropdownOption={onCreateDropdownOption}
            styles={AbbFilterDetailDropdownStyles}
        />
    )
}

export default AbbFilterDetailDropdown
