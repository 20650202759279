import { DcsDimensioningResultEntry, DctDimensioningResultEntry } from "./dimensioning.types"
import { Characteristics } from "../catalog/catalog.constants"

export function transformDcsDimensioningResponse(response: any): DcsDimensioningResultEntry[] {
    return response?.entries
        .map((entry: any) => ({
            id: entry.module.productId,
            [Characteristics.DcProductName.id]: entry.module.name,
            [Characteristics.DcCurrent.id]: customRound(entry.dimensioning.current),
            [Characteristics.DcMargin.id]: customRound(entry.dimensioning.margin),
            [Characteristics.DcModuleFrameSize.id]: entry.module.moduleFrameSize,
            [Characteristics.DcModuleDimension.id]: entry.module.moduleDimension,
            [Characteristics.DcThyristorTemp.id]: customRound(entry.dimensioning.thyristorTemp),
            [Characteristics.DcThyristorMaxTemp.id]: entry.module.thyristorMaxTemp
        }))
        .filter((entry: DcsDimensioningResultEntry) => !isNaN(entry.DC_Margin))
        .sort((e1: DcsDimensioningResultEntry, e2: DcsDimensioningResultEntry) => e1.DC_Margin - e2.DC_Margin)
}

export function transformDctDimensioningResponse(response: any): DctDimensioningResultEntry[] {
    return response?.entries
        .map((entry: any) => ({
            id: entry.module.productId,
            [Characteristics.DcProductName.id]: entry.module.name,
            [Characteristics.DcCurrent.id]: customRound(entry.module.dcRatedCurrent),
            [Characteristics.DcMargin.id]: customRound(entry.dimensioning.margin),
            [Characteristics.DcModuleFrameSize.id]: entry.module.moduleFrameSize,
            [Characteristics.DcModuleDimension.id]: entry.module.moduleDimension
        }))
        .filter((entry: DctDimensioningResultEntry) => !isNaN(entry.DC_Margin))
        .sort((e1: DctDimensioningResultEntry, e2: DctDimensioningResultEntry) => e1.DC_Margin - e2.DC_Margin)
}

function customRound(num: number): number {
    const roundedValue = Math.round(num)
    if (roundedValue === -0) {
        return 0
    } else {
        return roundedValue
    }
}
