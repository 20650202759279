import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Stack, Tooltip } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { ReactNode } from "react"

export const renderCell = (renderer?: (params: GridRenderCellParams) => ReactNode) => (params: GridRenderCellParams) => {
    return (
        <Tooltip title={renderer ? "" : params.formattedValue}>
            <Stack direction="row" alignItems="center" height="100%" width="100%">
                {renderer ? renderer(params) : params.formattedValue}
                {params.isEditable && (
                    <EditIcon
                        sx={{
                            position: "relative",
                            top: "-10px",
                            left: "-4px",
                            transform: "scale(50%)"
                        }}
                    />
                )}
            </Stack>
        </Tooltip>
    )
}
