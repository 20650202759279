import { DetailDropdownValueImage } from "./DetailDropdownValueImage"
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling"
import { Stack } from "@fluentui/react/lib/Stack"
import { IDetailDropdownOption, IFilterDetailDropdownStyles } from "./AbbFilterDetailDropdown.types"
import { mediaLink } from "@encoway/react-configurator"
import { IRenderFunction } from "@fluentui/react/lib/Utilities"

interface DetailDropdownTitleProps {
    showImage: boolean
    imageResolution?: string
    mediaLink?: mediaLink
    selectedDropdownOption?: IDetailDropdownOption
    defaultRender?: IRenderFunction<IDetailDropdownOption>
    classNames: IProcessedStyleSet<IFilterDetailDropdownStyles>
}

export const DetailDropdownTitle = (props: DetailDropdownTitleProps) => {
    const { showImage, imageResolution, mediaLink, selectedDropdownOption, defaultRender, classNames } = props
    return (
        <Stack horizontal className={classNames.detailOptionSelected} tokens={{ childrenGap: "0.5em" }}>
            <DetailDropdownValueImage
                image={selectedDropdownOption?.image}
                imageResolution={imageResolution}
                mediaLink={mediaLink}
                className={showImage ? mergeStyles(classNames.imageContainer, { marginTop: "0.33rem" }) : undefined}
                size="1.2rem"
                showImage={showImage}
            />
            <Stack.Item style={{ width: "calc(100% - 0.6rem)" }}>{defaultRender?.(selectedDropdownOption)}</Stack.Item>
        </Stack>
    )
}
