import { useMemo } from "react"
import { customerInformationInputs } from "../constants/customerInformationInputs"
import { useSalesDocumentPropertyInput } from "../../../../../features/sales/hooks/useSalesDocumentPropertyInput"
import { SalesDocumentProperties } from "../../../../../features/sales/sales.constants"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"

export function useCustomerInformationFormInputs() {
    const industrySegmentInput = useSalesDocumentPropertyInput(SalesDocumentProperties.INDUSTRY_SEGMENT, Characteristics.IndustrySegment.id)
    const destinationCountryInput = useSalesDocumentPropertyInput(SalesDocumentProperties.DESTINATION_COUNTRY, Characteristics.DestinationCountry.id)
    return useMemo(() => [industrySegmentInput, ...customerInformationInputs, destinationCountryInput], [industrySegmentInput, destinationCountryInput])
}
