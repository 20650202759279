import { useReducer, useState } from "react"
import { L10n } from "@encoway/l10n"
import { Button, Paper, Stack } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import TranslationKeys from "../../features/translations/TranslationKeys"
import NewSalesDocumentDialog from "../../features/sales/components/newSalesDocumentDialog/NewSalesDocumentDialog"
import ProjectsDataGrid from "./components/projectsDataGrid/ProjectsDataGrid"
import BackToLink from "../../components/links/backToLink/BackToLink"
import SearchInput from "../../components/input/searchInput/SearchInput"
import Dialog from "../../components/dialog/Dialog"
import useDialog from "../../components/dialog/useDialog"
import { GridFilterModel, GridPaginationModel, GridSortModel } from "@mui/x-data-grid"
import LockedSalesDocumentDialog from "../../features/sales/components/./lockedSalesDocumentDialog/LockedSalesDocumentDialog"
import RouterPaths from "../../router/constants/RouterPaths"
import SalesApi from "../../features/sales/sales.api"
import SalesUtils from "../../features/sales/sales.utils"
import useOpenSalesDocumentHandler from "../../features/sales/hooks/useOpenSalesDocumentHandler"
import useDeleteSalesDocumentHandler from "../../features/sales/hooks/useDeleteSalesDocumentHandler"
import { MainContainer } from "../../components/mainContainer/MainContainer"
import { useUserInformation } from "../../features/oidc/hooks/useUserInformation"
import { OidcUtils } from "../../features/oidc/oidc.utils"
import Dropdown from "../../components/dropdown/Dropdown"
import { getTranslatedProjectSearchColumns, searchColumnsReducer } from "./ProjectsPage.utils"
import { initialGridPaginationModel, initialGridSortModel, searchAllProjectColumnsDropdownMenuItem } from "./ProjectsPage.constants"

export default function ProjectsPage() {
    const dialog = useDialog()
    const userInformation = useUserInformation()
    const [searchValue, setSearchValue] = useState<string>("")
    const [searchColumns, updateSearchColumns] = useReducer(searchColumnsReducer, [searchAllProjectColumnsDropdownMenuItem.value])
    const [sortModel, setSortModel] = useState<GridSortModel>(initialGridSortModel)
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(initialGridPaginationModel)
    const [filterModel, setFilterModel] = useState<GridFilterModel>()

    const salesDocumentsQuery = SalesApi.useSalesDocumentsQuery({
        searchValue: searchValue,
        searchProperties: searchColumns.filter(column => column !== searchAllProjectColumnsDropdownMenuItem.value),
        pagingLoadConfig: SalesUtils.salesDocuments.getPaging(paginationModel, sortModel),
        filterConfig: SalesUtils.salesDocuments.getFilter(filterModel)
    })

    const openSalesDocumentHandler = useOpenSalesDocumentHandler()
    const deleteSalesDocumentHandler = useDeleteSalesDocumentHandler()
    const [copySalesDocument] = SalesApi.useCopySalesDocumentMutation()

    return (
        <MainContainer>
            <Stack spacing={3} marginTop={3}>
                <BackToLink text={L10n.format(TranslationKeys.pages.dashboard.pageName)} route={RouterPaths.DASHBOARD} />

                <Stack direction="row" spacing={3}>
                    <Button
                        variant="contained"
                        endIcon={<AddIcon />}
                        onClick={dialog.open}
                        disabled={!userInformation || !OidcUtils.hasEditProjectsRole(userInformation)}
                    >
                        {L10n.format(TranslationKeys.project.newProject.button.label)}
                    </Button>
                    <SearchInput onSearch={setSearchValue} placeholder={L10n.format(TranslationKeys.pages.projects.searchInputPlaceholder)} />
                    <Dropdown
                        label="label"
                        hideLabel
                        value={searchColumns}
                        menuItems={getTranslatedProjectSearchColumns()}
                        multiple
                        onChange={updateSearchColumns}
                        cypressId=""
                    />
                </Stack>

                <Paper>
                    <ProjectsDataGrid
                        rows={salesDocumentsQuery.currentData?.data ?? []}
                        rowCount={salesDocumentsQuery.currentData?.totalCount ?? 0}
                        onSelectProject={openSalesDocumentHandler.open}
                        onDeleteProject={deleteSalesDocumentHandler.setSalesDocumentToDelete}
                        onCopyProject={copySalesDocument}
                        pagination={true}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        filterModel={filterModel}
                        onFilterModelChange={setFilterModel}
                    />
                </Paper>

                <LockedSalesDocumentDialog
                    open={!!openSalesDocumentHandler.lockedSalesDocument}
                    projectCanBeOpened={openSalesDocumentHandler.lockedSalesDocumentCanBeOpened}
                    lockedBy={openSalesDocumentHandler.lockedSalesDocument?.properties.quote_locked_by}
                    onOpenLockedProject={openSalesDocumentHandler.openLockedSalesDocument}
                    onCancelOpen={openSalesDocumentHandler.cancel}
                />

                <NewSalesDocumentDialog {...dialog} />

                <Dialog
                    open={!!deleteSalesDocumentHandler.salesDocumentToDelete}
                    onClose={deleteSalesDocumentHandler.cancel}
                    title={L10n.format(TranslationKeys.project.deleteDialog.title)}
                    message={L10n.format(TranslationKeys.project.deleteDialog.message)}
                    defaultDialogActions={{
                        onConfirm: deleteSalesDocumentHandler.confirm,
                        onCancel: deleteSalesDocumentHandler.cancel,
                        confirmButtonLabel: TranslationKeys.project.deleteDialog.confirmButtonLabel,
                        cancelButtonLabel: TranslationKeys.project.deleteDialog.cancelButtonLabel
                    }}
                />
            </Stack>
        </MainContainer>
    )
}
