import { ProductGroup } from "@encoway/c-services-js-client"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"

export function getProductGroupData(productGroup: ProductGroup) {
    return {
        id: productGroup.id,
        name: productGroup.name,
        catalogLink: CatalogUtils.getCharacteristicValue<string>(productGroup, Characteristics.CatalogLink.id),
        imageSrc: CatalogUtils.getMediaUri(productGroup, Characteristics.ProductImage.id),
        shortText: CatalogUtils.getCharacteristicValue<string>(productGroup, Characteristics.ShortText.id)
    }
}
