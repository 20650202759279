import Conan from "../../../../assets/conan.svg"
import React from "react"
import { Stack } from "@mui/material"
import { LogoStyles } from "./Logo.styles"

import "./Logo.css"

type LogoProps = {
    rotate: boolean
}

export const Logo = ({ rotate }: LogoProps) => {
    return (
        <Stack style={LogoStyles(rotate)}>
            <img src={Conan} alt="ConAnApp" />
        </Stack>
    )
}
