import { PropsWithChildren, useCallback, useEffect } from "react"
import { useIdToken, useToken } from "@encoway/sales-showroom-auth"
import { useAppDispatch } from "../../../../store/store"
import OidcSlice from "../../../../features/oidc/oidc.slice"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { SalesRoles } from "../../../../features/sales/sales.constants"
import { ContentWithSalesService } from "./ContentWithSalesService"
import { ContentWithCatalogService } from "./ContentWithCatalogService"
import { UnexpectedError } from "../unexpectedError/UnexpectedError"
import { useUserInformation } from "../../../../features/oidc/hooks/useUserInformation"

const TOKEN_REFRESH_INTERVAL = 25000

const removeSearchParameters = () => {
    if (window.location.search) {
        const baseUri = window.location.protocol + "//" + window.location.host + window.location.pathname
        window.history.replaceState(null, "", baseUri)
    }
}

export const ContentWithToken = (props: PropsWithChildren<unknown>) => {
    const tokenFunction = useToken()
    const idTokenFunction = useIdToken()
    const userInformation = useUserInformation()
    const dispatch = useAppDispatch()

    const updateToken = useCallback(() => {
        const tokenValue = tokenFunction?.()
        dispatch(OidcSlice.actions.setToken(tokenValue))
        const idTokenValue = idTokenFunction?.()
        dispatch(OidcSlice.actions.setIdToken(idTokenValue))
    }, [tokenFunction, idTokenFunction, dispatch])

    useEffect(() => {
        updateToken()
        const timer = setInterval(updateToken, TOKEN_REFRESH_INTERVAL)
        return () => clearInterval(timer)
    }, [updateToken])

    useEffect(removeSearchParameters, [])

    if (userInformation?.roles?.includes(SalesRoles.QA_BASE)) {
        return (
            <ContentWithSalesService>
                <ContentWithCatalogService>{props.children}</ContentWithCatalogService>
            </ContentWithSalesService>
        )
    } else if (userInformation?.roles) {
        return (
            <UnexpectedError
                title={L10n.format(TranslationKeys.error.login.accessDenied.title)}
                message={L10n.format(TranslationKeys.error.login.accessDenied.body)}
            />
        )
    } else {
        return null
    }
}
