import theme from "../../App.theme"

const DialogStyles = {
    title: {
        padding: 2,
        alignItems: "center",
        justifyContent: "space-between"
    },
    content: {
        padding: 2
    },
    grayButton: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.text.primary,
        ":hover": {
            backgroundColor: theme.palette.background.default
        },
        flex: "none"
    },
    blackButton: {
        backgroundColor: theme.palette.common.black,
        ":hover": {
            backgroundColor: theme.palette.background.default
        },
        flex: "none"
    },
    divider: {
        borderBottomWidth: 2
    }
}

export default DialogStyles
