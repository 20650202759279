import { useCallback, useMemo } from "react"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import SalesApi from "../../../../features/sales/sales.api"
import { AbbSalesDocumentProperty } from "../../../../features/sales/sales.types"
import SalesUtils from "../../../../features/sales/sales.utils"
import { useAppSelector } from "../../../../store/store"
import { GenericFormUtils } from "../../../../components/forms/genericForm/GenericForm.utils"
import { GenericFormProps, GenericInput } from "../../../../components/forms/genericForm/GenericForm"
import { useProjectInformationFormInputs } from "./hooks/useProjectInformationFormInputs"
import { useCustomerInformationFormInputs } from "./hooks/useCustomerInformationFormInputs"
import { generalInformationInputs } from "./constants/generalInformationInputs"
import { contactInformationInputs } from "./constants/contactInformationInputs"

export interface ProjectFormDefinition {
    title: string
    isValid: boolean
    formProps: GenericFormProps
}

export default function useProjectForms(): ProjectFormDefinition[] {
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data
    const [updateSalesDocument] = SalesApi.useUpdateSalesDocumentMutation()

    const projectInformationInputs = useProjectInformationFormInputs()
    const customerInformationInputs = useCustomerInformationFormInputs()

    const forms = useMemo(
        () => [
            { translationKey: TranslationKeys.pages.project.details.generalInformation, inputs: generalInformationInputs },
            { translationKey: TranslationKeys.pages.project.details.projectInformation, inputs: projectInformationInputs },
            { translationKey: TranslationKeys.pages.project.details.customerInformation, inputs: customerInformationInputs },
            { translationKey: TranslationKeys.pages.project.details.contactInformation, inputs: contactInformationInputs }
        ],
        [projectInformationInputs, customerInformationInputs]
    )

    const getForms = useCallback(
        () =>
            forms.map(form => ({
                title: L10n.format(form.translationKey),
                isValid: GenericFormUtils.isFormValid(form.inputs, salesDocument!.properties),
                formProps: {
                    inputs: form.inputs,
                    values: salesDocument!.properties,
                    validateInput: (input: GenericInput) => GenericFormUtils.validateInput(input, salesDocument!.properties[input.id]),
                    disableInput: (property: AbbSalesDocumentProperty) => readOnly || !SalesUtils.salesDocument.isEditableProperty(property, salesDocument!),
                    update: (property: AbbSalesDocumentProperty, value: string | number) => {
                        updateSalesDocument([{ [property]: value }, {}])
                    }
                }
            })),
        [forms, readOnly, salesDocument, updateSalesDocument]
    )

    return useMemo(() => (salesDocument ? getForms() : []), [getForms, salesDocument])
}
