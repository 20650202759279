import { ConfigurationService } from "@encoway/c-services-js-client"
import { Stack, Tooltip } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { Compare, Download, Upload } from "@mui/icons-material"
import { useDownload } from "./useDownload"
import { useUpload } from "./useUpload"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { ConanService } from "../../service/ConanService"
import { useToken } from "@encoway/sales-showroom-auth"
import { ConfigurationElement } from "../../types/configuration.types"
import React from "react"
import { Logo } from "../Logo/Logo"

export type View =
    | "internal"
    | "abb.dimensioningView"
    | "abb.commonOptionsView"
    | "abb.MV_MfkView"
    | "abb.typeCodeView"
    | "abb.documentView"
    | "abb.MV_EplanView"
    | "abb.MV_completeTypeCodeView"

type ActionBarProps = {
    configurationService: ConfigurationService
    view: View
    changeView: (newValue: View) => void
    changeComparisonFile: (newValue: string) => void
    configurationState?: ConfigurationElement
    busy: boolean
}
export const ActionBar = ({ configurationService, view, changeView, changeComparisonFile, configurationState, busy }: ActionBarProps) => {
    const { download } = useDownload(configurationService)
    const { upload } = useUpload(configurationService, view)
    const token = useToken()
    const onFileChange = async (e: any) => {
        const file = e.target.files[0] as File
        await upload(file)
        changeComparisonFile(`${file.name}_${file.size}_${file.lastModified}`)
    }
    const onStopComparison = async () => {
        await ConanService.stopComparison(configurationService.configurationId, token)
        changeComparisonFile(Date.now().toString())
    }
    const onUpload = () => {
        document.getElementById("choose-file-dialog")?.click()
    }
    return (
        <Stack direction="row" style={{ padding: "4px" }}>
            <input id="choose-file-dialog" type="file" onChange={onFileChange} style={{ display: "none" }} accept=".config" />
            <Stack style={{ paddingRight: "8px" }}>
                <Logo rotate={busy} />
            </Stack>
            <Tooltip title="download the current configuration">
                <IconButton onClick={download}>
                    <Download />
                </IconButton>
            </Tooltip>
            <Tooltip title="upload a configuration to start a comparison">
                <IconButton onClick={onUpload}>
                    <Upload />
                </IconButton>
            </Tooltip>
            <Tooltip title="stop the comparison of configurations">
                <IconButton disabled={!configurationState?.isComparison} onClick={onStopComparison}>
                    <Compare />
                </IconButton>
            </Tooltip>
            <Select value={view} onChange={event => changeView(event.target.value as View)}>
                <MenuItem value="internal">Internal Tree</MenuItem>
                <MenuItem value="abb.dimensioningView">Dimensioning View</MenuItem>
                <MenuItem value="abb.commonOptionsView">Common Options View</MenuItem>
                <MenuItem value="abb.MV_MfkView">MV MfkView</MenuItem>
                <MenuItem value="abb.typeCodeView">Type Code View</MenuItem>
                <MenuItem value="abb.lineupView">Lineup View</MenuItem>
                <MenuItem value="abb.documentView">Document View</MenuItem>
                <MenuItem value="abb.MV_EplanView">MV Eplan View</MenuItem>
                <MenuItem value="abb.MV_completeTypeCodeView">MV Complete TypeCode View</MenuItem>
            </Select>
        </Stack>
    )
}
