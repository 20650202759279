import { AbbLineItem } from "../../../../../../../features/sales/sales.types"
import { BomNode } from "../../LineItemsDataGrid"

/**
 * Checks if the given line item is a footer.
 * @param lineItem the line item to check.
 */
export const isFooter = (lineItem: AbbLineItem) => !!lineItem.isSalesDocument

export const getPreviousBomNode = (bomNode: BomNode, bomNodes: BomNode[]): BomNode | undefined => {
    const parentChildren = bomNode.parent?.children ?? bomNodes.filter(node => !node.parent)
    const index = parentChildren.findIndex(node => node.lineItem.lineItemId === bomNode.lineItem.lineItemId)
    return index > 0 ? parentChildren.at(index - 1) : undefined
}
