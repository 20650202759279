import { ConfigurationService } from "@encoway/c-services-js-client"
import React, { useState } from "react"
import { useConfigurationState } from "./useConfigurationState"
import { Stack } from "@mui/material"
import { ConfigurationBoard } from "../ConfigurationBoard/ConfigurationBoard"
import { ActionBar, View } from "../ActionBar/ActionBar"

type ConAnAppProps = {
    configurationService: ConfigurationService
}

export const ConAnApp = ({ configurationService }: ConAnAppProps) => {
    const [view, setView] = useState<View>("internal")
    const [comparisonFile, setComparisonFile] = useState("")
    const [fetchingConfiguration, setFetchingConfiguration] = useState(false)
    const configurationState = useConfigurationState(configurationService, view, comparisonFile, setFetchingConfiguration)
    return (
        <Stack>
            <ActionBar
                configurationService={configurationService}
                view={view}
                changeView={setView}
                configurationState={configurationState}
                changeComparisonFile={setComparisonFile}
                busy={fetchingConfiguration}
            />
            {configurationState && <ConfigurationBoard configurationState={configurationState} />}
        </Stack>
    )
}
