import { Icon as MuiIcon, IconProps as MuiIconProps, Tooltip, TooltipProps } from "@mui/material"
import { ElementType } from "react"

export interface IconProps extends MuiIconProps {
    component: ElementType
    tooltipProps?: Omit<TooltipProps, "children">
}

export default function Icon({ tooltipProps, ...iconProps }: Readonly<IconProps>) {
    const actualTooltipProps = tooltipProps ?? { title: "" }
    return (
        <Tooltip enterTouchDelay={0} {...actualTooltipProps}>
            <MuiIcon {...iconProps} />
        </Tooltip>
    )
}
