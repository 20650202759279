export default class TreeUtils {
    static findRecursively<Item>(items: Item[], getChildren: (item: Item) => Item[], predicate: (item: Item) => boolean): Item | undefined {
        const item = items.find(predicate)
        if (item) {
            return item
        } else {
            for (const item of items) {
                const children = getChildren(item)
                const lineItem = this.findRecursively(children, getChildren, predicate)
                if (lineItem) {
                    return lineItem
                }
            }
        }
    }

    static findAllRecursively<Item>(items: Item[], getChildren: (item: Item) => Item[], predicate: (item: Item) => boolean): Item[] {
        const recursiveChildren = items.flatMap(item => this.findAllRecursively(getChildren(item), getChildren, predicate))
        return items.concat(recursiveChildren).filter(predicate)
    }
}
