import { INotReadyCountStyles } from "@encoway/cui-configurator-components"
import theme from "../../../../../../../../App.theme"

const AbbNotReadyCountStyles: INotReadyCountStyles = {
    circle: {
        backgroundColor: theme.palette.primary.main
    }
}

export default AbbNotReadyCountStyles
