import { getTheme } from "@fluentui/react"

const theme = getTheme()

const MatlabWarningStyles = {
    root: {
        backgroundColor: theme.palette.themeLighter,
        borderLeft: `4px solid ${theme.palette.themeLight}`
    },
    text: {
        paddingTop: "4px"
    }
}

export default MatlabWarningStyles
