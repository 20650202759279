import { VISUALIZATION_IMAGE_PREFIX } from "./visualization.constants"

export function mapVisualizationImagesResult(imagesResult?: Record<string, string>): Record<string, string> {
    if (imagesResult) {
        return Object.entries(imagesResult).reduce((result, [key, value]) => ({ ...result, [getVisualizationImageKey(key)]: value }), {})
    } else {
        return {}
    }
}

function getVisualizationImageKey(imageName: string) {
    return VISUALIZATION_IMAGE_PREFIX + imageName.replaceAll("-", "_").toUpperCase()
}
