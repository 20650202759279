import { ICheckboxStyles } from "@encoway/cui-configurator-components"

export const AbbValueCheckboxStyles: ICheckboxStyles = {
    checkboxContainer: {
        margin: "0px !important",
        "& > svg": {
            display: "none"
        }
    }
}
