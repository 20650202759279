import { TreeItem } from "@mui/x-tree-view"
import React from "react"
import { ConfigurationElement } from "../../types/configuration.types"
import { ConfigurationTreeItemLabel } from "./ConfigurationTreeItemLabel"

type ConfigurationTreeItemProps = {
    configurationElement: ConfigurationElement
}
export const ConfigurationTreeItem = ({ configurationElement }: ConfigurationTreeItemProps) => {
    return (
        <TreeItem itemId={configurationElement.id} label={<ConfigurationTreeItemLabel configurationElement={configurationElement} />}>
            {configurationElement.children.map(child => (
                <ConfigurationTreeItem key={child.id} configurationElement={child} />
            ))}
        </TreeItem>
    )
}
