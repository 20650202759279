import { DataGridPro } from "@mui/x-data-grid-pro"
import React from "react"
import { ConfigurationElement } from "../../types/configuration.types"
import { COLUMNS } from "./columns"

type ConfigurationDetailsProps = {
    selectedElement: ConfigurationElement
}

export const ConfigurationDetails = ({ selectedElement }: ConfigurationDetailsProps) => {
    const isParameter = (element: ConfigurationElement) => {
        return ["PARAMETER", "PARAMETER_COMPARISON"].includes(element.type)
    }
    const rows = isParameter(selectedElement) ? [selectedElement] : selectedElement.children.filter(e => isParameter(e))
    return <DataGridPro columns={COLUMNS} rows={rows} getRowHeight={() => "auto"} />
}
