import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined"
import LanguageStyles from "./Language.styles"
import { L10n } from "@encoway/l10n"
import { Button } from "@mui/material"

export default function Language() {
    return (
        <Button disableRipple sx={LanguageStyles.button}>
            <PublicOutlinedIcon sx={LanguageStyles.icon} />
            {L10n.currentLanguage().toUpperCase()}
        </Button>
    )
}
