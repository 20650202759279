import { Box, Stack, Typography } from "@mui/material"

interface UnexpectedErrorProps {
    title: string
    message: string
}

export function UnexpectedError({ title, message }: UnexpectedErrorProps) {
    return (
        <Stack spacing={3} mt={10}>
            <Box width="100px" height="10px" bgcolor="red"></Box>
            <Typography variant="h1">Oops! An unexpected error occurred</Typography>
            <Typography variant="h2">{title}</Typography>
            <Box dangerouslySetInnerHTML={{ __html: message }} />
            <Typography>
                <b>Timestamp:</b> {getTimestamp()}
            </Typography>
        </Stack>
    )
}

function getTimestamp() {
    const date = new Date()
    const values = [date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getSeconds()]
    return values.map(toString).join("")
}

function toString(value: number) {
    const s = value.toString()
    return s.length > 1 ? s : "0" + s
}
