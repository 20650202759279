import { getTheme, IMessageBarStyles } from "@fluentui/react"

const theme = getTheme()

const NotificationStyles: IMessageBarStyles = {
    root: {
        backgroundColor: theme.palette.themeLighter,
        borderLeft: `4px solid ${theme.palette.neutralTertiary}`
    },
    text: {
        paddingTop: "4px"
    }
}

export default NotificationStyles
